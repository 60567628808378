import React from "react";
import classes from "./LoadingSpin.module.scss";
import loading from "../assets/imgs/loading.png";

class LoadingSpin extends React.Component {
    render() {
        return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
            <img alt="loading" src={loading} />
        </div>
    }
}

export default LoadingSpin;