import classes from "./Component.module.scss";
import loading from "../../../assets/imgs/loading.gif";
import { renderCollage } from "./Collage";
import filter from "../../../assets/imgs/filter.png";
import Loading from "../../../components/Loading";
import LoadingSpin from "../../../components/LoadingSpin";
import CheckBox from "../../../components/CheckBox";
import { renderChat } from "./Chat";
import { renderNotes } from "./Notes";
import { renderScreenShot } from "./ScreenShot";
import { getParticipantScreenshotNotes, saveNotes } from "./Action";
import ScreenshotCard from "../../../components/ScreenshotCard";
import screenshot from "../../../assets/imgs/screenshot.png";
import imgfolder from "../../../assets/imgs/img-folder.png";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";

export const renderLoading = () => {
    return <div className={classes.container}>
        <LoadingSpin />
    </div>
}

export const renderWindow = (_this) => {
    if (_this.state.isloading) {
        return renderLoading();
    }
    switch (_this.state.chosenWindow) {
        case 'loading':
            return renderLoading();
        case 'collage':
            return renderCollage(_this);
        case 'chat':
            return renderChat(_this);
        case 'notes':
            return renderNotes(_this);
        case 'screenshot':
            return renderScreenShot(_this);
        default:
            return renderLoading();
    }
}

export const renderSearch = (_this) => {
    return <div className={classes.search}>
        <div className={classes.search_c}>
            <input
                ref={_this.searchRef}
                type="text"
                placeholder='Search'
                onChange={(event) => {
                }}
            />
            {/* <div className={classes.search_c_filter}> */}
            {/* <img alt="filter" src={filter} /> */}
            {/* <span>by id</span> */}
            {/* <span>by message</span> */}
            {/* <span>by chat</span> */}
            {/* <span>by warning</span> */}
            {/* </div> */}
        </div>
        {/* <div className={classes.search_c}>
            <div className={classes.search_c_check}>
                <div className={classes.search_c_check_c}>
                    <CheckBox
                        id="online"
                        name="online"
                        onChange={() => { }}
                        checked={true}
                    />
                    <span>Online</span>
                </div>
            </div>
        </div> */}
    </div>
}

export const renderNavigation = (_this) => {
    return <div className={classes.navigation} >

        {/* <div className={classes.direction} */}
        <button className={`${classes.direction} ${_this.state.mode === "screenshot" ? classes.active : ""}`}
            onClick={() => {
                getParticipantScreenshotNotes(_this, _this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand)
                _this.setState({ mode: "screenshot" })
            }}
        >
            <img alt="screenshot" src={screenshot} height="25px" />
            <span>{Lang(`Screenshot`, LANG(), { en: `Screenshot` })}</span>
        </button>

        <button className={`${classes.direction} ${_this.state.mode === "view-screenshot" ? classes.active : ""}`}
            onClick={() => {
                getParticipantScreenshotNotes(_this, _this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand)
                _this.setState({ mode: "view-screenshot" })
            }}
        >
            <img alt="showscreenshot" src={imgfolder} height="25px" />
            <span>{Lang(`Lihat Screenshot`, LANG(), { en: `View Screenshots` })}</span>
        </button>
    </div>
}

export const viewScreenshot = (_this) => {
    let k = 0;
    return <div className={classes.screenshot_card_wrapper}>
        {
            _this.state.screenshotNotes.map((data, index) => {
                return (
                    <div key={k++}
                        className={classes.screenshot_card}>
                        <ScreenshotCard
                            data={data}
                            participantId={_this.state.chosen.profile?.id}
                            folderRand={_this.state.chosen.profile?.folder_rand}
                            index={index}
                            updateData={(participantId, folderRand) => getParticipantScreenshotNotes(_this, participantId, folderRand)}
                            imageModal={(event) => {
                                if (_this.props.openImage) {
                                    _this.props.onImageClose()
                                } else {
                                    _this.props.onImageOpen(event)
                                }
                                // Debug("data : ", event)
                            }}
                            style={{ cursor: "zoom-in" }}
                        />
                    </div>
                );
            })}
    </div>
}

export const renderAttentionButton = (_this) => {
    return <>
        <div className={classes.flagAttention}>
            <div>
                <CheckBox
                    checked={_this.state.chosen.profile.flagAttention}
                    onChange={(e) => {
                        let fa = !(_this.state.chosen.profile.flagAttention);
                        saveNotes(_this, fa, () => {
                            _this.setState(prevState => ({
                                chosen: {
                                    ...prevState.chosen,
                                    profile: {
                                        ...prevState.chosen.profile,
                                        flagAttention: fa,
                                    },
                                },
                            }));
                        })
                    }}
                />
                <div className={classes.flagAttention_text}>
                    {Lang(`Berikan perhatian khusus bagi peserta ini`, LANG(), { en: `Give special attention to this participant.` })}
                </div>
            </div>
            <div className={classes.i}>
                {Lang(`Tanda perhatian khusus dapat anda berikan apabila
                            peserta dinilai melakukan hal - hal yang dianggap curang.
                            Tanda ini akan membantu penyelenggara ujian untuk menganalisa
                            lebih lanjut dan menghasilkan keputusan yang adil.`, LANG(), {
                    en: `You can provide special attention if the participant is 
                                suspected of engaging in cheating behaviors. 
                                This marker will assist exam proctors in further analysis and making fair decisions.`
                })}
            </div>
        </div>
    </>
}