import React from "react";
import classes from "./LoginEMSModal.module.scss";
import showpass from "../assets/imgs/showpassword.png";
import hidepass from "../assets/imgs/hidepassword.png";

var console = {};
console.log = function () { };
console.error = function () { };

class LoginEMSModal extends React.Component {
    constructor(props) {
        super(props);

        this.usernameRef = React.createRef();
        this.passwordRef = React.createRef();

        this.state = {
            showPassword: false,
        }
    }


    render() {
        return <div className={classes.container}>
            <div className={classes.container_title}>
                Login
            </div>
            <div className={classes.container_login}>
                <div className={classes.container_login_c}>
                    <div className={classes.title}>
                        <span>Username</span>
                        {/* <a href="#">Sign Up</a> */}
                    </div>
                    <input ref={this.usernameRef} type="text"
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                                this.props.onLogin(this.usernameRef.current.value,
                                    this.passwordRef.current.value)
                            }
                        }}
                    />
                </div>
                <div className={classes.container_login_c}>
                    <div className={classes.title}>
                        <span>Password</span>
                        <img
                            alt="pass"
                            src={this.state.showPassword === false ? showpass : hidepass}
                            title={this.state.showPassword === false ? "tunjukkan password" : "sembunyikan password"}
                            height="25px"
                            onClick={() => {
                                this.setState({ showPassword: !this.state.showPassword })
                            }}
                        />
                    </div>
                    <input ref={this.passwordRef}
                        type={this.state.showPassword === true ? "text" : "password"}
                        onKeyDown={(k) => {
                            if (k.key === "Enter") {
                                this.props.onLogin(this.usernameRef.current.value,
                                    this.passwordRef.current.value)
                            }
                        }}
                    />
                </div>
                <div className={classes.container_login_c}>
                    <div className={[classes.btnOk, classes.btnLogin].join(" ")}
                        onClick={() => {
                            this.props.onLogin(this.usernameRef.current.value,
                                this.passwordRef.current.value)
                        }}
                    >
                        Login
                    </div>
                </div>
            </div>
            <div className={classes.container_sep}>
                <div>
                </div>
                <span>atau</span>
                <div></div>
            </div>
            {/* <div className={classes.container_oauth}>
                <div className={classes.btnHelp}
                    onClick={() => this.props.onLoginSSO()}
                >
                    Login dengan SSO Telkom University
                </div>
            </div> */}

            <div>
                {this.props.onFormGoogleAuth()}
                {/* {this.props.onFormFacebookAuth()} */}
            </div>

            <div className={classes.container_oauth}>
                {/* <div className={classes.btnHelp}
                    onClick={() => this.props.onLoginOAUTH()}
                >
                    Login dengan Google
                </div> */}
            </div>

            <div className={classes.container_sep}>
                <div>
                </div>
                {/* <span>atau</span> */}
                <div></div>
            </div>
            <div className={classes.container_close}>
                <div className={classes.btnReload}
                    onClick={() => {
                        this.props.onClose()
                    }}
                >
                    Tutup
                </div>
            </div>
        </div>
    }
}

export default LoginEMSModal;