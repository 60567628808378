import React, { Component } from 'react';
import classes from './LoginPageMobilePairing.module.scss';
import { QrReader } from 'react-qr-reader';
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import AuthContext from "../../store/auth-context";
import { fetchMobilePairingLogin } from '../../libs/proctor_utils';
import { Debug, Err, Info } from '../../libs/logger';
import Loading from '../../components/Loading';
import { ErrorAlert } from '../../components/Alert';
import { getExamId } from '../../libs/utils';

class LoginPageMobilePairing extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);

        this.inputTestRef = React.createRef();

        this.state = {
            showScanner: false,
            result: '',
            loading: false
        };
    }

    handleLoginClick = () => {
        this.setState({ showScanner: true });
    };

    handleScan = (data) => {
        if (data) {
            this.setState({ result: data }, () => {
                if (navigator.vibrate) {
                    setTimeout(() => {
                        navigator.vibrate(200);
                        // this.props.history.push("/mobilePairing");
                        this.handleLogin(data)
                    }, 0);
                }
            });
        }
        this.setState({ loading: true })
    };

    handleError = (err) => {
        console.error(err);
        this.setState({ result: '', loading: false })
        // console.log(err);
    };

    handleLogin = async (token) => {
        await fetchMobilePairingLogin({
            token: token
        }, (error) => {
            Err("[error] fetch mobile pairing user login", error);
        }).then((data) => {
            if (data.status === "success") {
                const expiresIn = 3600 * 12;
                const expirationTime = new Date(
                    new Date().getTime() + expiresIn * 1000
                );
                const result = data.result;
                this.context.login(
                    "participant-mobile-pairing",
                    result.access_token,
                    result.id,
                    { ...result, role: "participant-mobile-pairing" },
                    getExamId(),
                    expirationTime
                );

                if (token) {
                    Debug("mobile pairing token : ", token)
                    // localStorage.setItem(`mobile_pairing_token_${result.id}`, token);
                }

                setTimeout(() => {
                    this.props.history.push("/mobilePairing");
                }, 1000);
                // this.redirectOnLogin(result);
            } else {
                ErrorAlert(data.errorMessage);
                // window.parent.postMessage('accessFailed', '*');
                // window.parent.postMessage('accessFailed', telkomPageURL);
            }
        })
        this.setState({ result: '', loading: false })
    }

    render() {
        return (
            <div className={this.state.showScanner ? classes.scanner_container : classes.login_container}>
                {!this.state.showScanner ? (
                    <>
                        <button className={classes.loggin_button} onClick={this.handleLoginClick}>Login</button>
                        <input
                            ref={this.inputTestRef}
                            type="text"
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    Info("Handle Login Enter", this.inputTestRef.current.value);
                                    this.handleLogin(this.inputTestRef.current.value)
                                }
                            }}

                        />
                    </>

                ) : (
                    <>
                        {
                            this.state.loading ?
                                <div style={{ transform: "scale(0.5)" }}>
                                    <Loading />
                                </div>
                                :
                                <div className={classes.qr_code_scanner}>
                                    <h2>QR Code Scanner</h2>
                                    {/* <h2>Result : {this.state.result}</h2> */}
                                    <div className={classes.qr_reader_container}>
                                        <div className={classes.qr_video_wrapper}>
                                            <QrReader
                                                delay={300}
                                                constraints={{ facingMode: 'environment' }}
                                                onError={this.handleError}
                                                onResult={(result, error) => {
                                                    if (result) {
                                                        this.handleScan(result?.text);
                                                    }
                                                    // if (error) {
                                                    //     this.handleError(error);
                                                    // }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                        }
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(LoginPageMobilePairing);
