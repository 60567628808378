import React, { Component } from 'react';
import PropTypes from "prop-types";
import ListeningAudio from '../assets/audio/LISTENING.mp3';
import classes from './AudioPlayer.module.scss';
import { Rnd } from 'react-rnd';

class AudioPlayer extends Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.state = {
            isPlayedOnce: false,
            currentTime: 0,
            duration: 0,
        };
        this.id = props.id;
        this.examProfile = props.examProfile;
        this.showAudioPlayer = false;
    }

    componentDidMount() {
        const savedTime = localStorage.getItem(`${this.examProfile.title}_${this.id}_${this.examProfile.start_time_ts}`);
        if (savedTime) {
            let adjustedTime = parseFloat(savedTime);
            // Check if the saved time is more than 5 seconds
            if (adjustedTime > 5) {
                adjustedTime -= 2; // Subtract 2 seconds
            }
            this.setState({ currentTime: adjustedTime }, () => {
                if (this.audioRef.current) {
                    this.audioRef.current.currentTime = this.state.currentTime;
                }
            });
        }
        if (this.audioRef.current) {
            this.audioRef.current.addEventListener("loadedmetadata", this.setAudioDuration);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isPlayedOnce && !prevState.isPlayedOnce) {
            this.startSavingCurrentTime();
        }
    }

    componentWillUnmount() {
        this.stopSavingCurrentTime();
        if (this.audioRef.current) {
            this.audioRef.current.removeEventListener("loadedmetadata", this.setAudioDuration);
        }
    }

    setAudioDuration = () => {
        if (this.audioRef.current) {
            this.setState({ duration: this.audioRef.current.duration });
        }
    };

    startSavingCurrentTime = () => {
        this.timeInterval = setInterval(() => {
            const currentTime = this.audioRef.current.currentTime;
            localStorage.setItem(`${this.examProfile.title}_${this.id}_${this.examProfile.start_time_ts}`, currentTime);
            this.setState({ currentTime });
        }, 1000);
    };

    stopSavingCurrentTime = () => {
        clearInterval(this.timeInterval);
    };

    handlePlayAudio = () => {
        const { isPlayedOnce } = this.state;
        const audio = this.audioRef.current;

        if (!isPlayedOnce) {
            audio.play();
            this.setState({ isPlayedOnce: true });
        }
    };

    handleShowAudioPlayer = () => {
        this.showAudioPlayer = !this.showAudioPlayer;
    }

    formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    renderAudioPlayer = () => {
        const { isPlayedOnce, currentTime, duration } = this.state;
        const progress = (currentTime / duration) * 100 || 0;

        // return (this.audioPlayer && <Rnd
        return (<Rnd
            default={{
                x: 10,
                y: 10,
                width: 320,
                // height: 200,
            }}
            minWidth={200}
            minHeight={100}
            bounds="window"
            style={{ zIndex: "4000" }}
        >
            <div className={classes.container}>
                <div className={classes.audioPlayer}>
                    <button
                        className={classes.playButton}
                        onClick={this.handlePlayAudio}
                        disabled={isPlayedOnce}
                    >
                        {isPlayedOnce ? "Playing..." : "Play"}
                    </button>
                    <div className={classes.timeBarContainer}>
                        <div className={classes.timeBar} style={{ width: `${progress}%` }}></div>
                    </div>
                    <div className={classes.timeInfo}>
                        <span>{this.formatTime(currentTime)}</span>
                        <span>{this.formatTime(duration)}</span>
                    </div>
                    <audio
                        ref={this.audioRef}
                        src={ListeningAudio}
                        onEnded={this.stopSavingCurrentTime}
                    />
                </div>
            </div>
        </Rnd>

        )
    }

    render() {
        // console.log("initExam : ", this.props.initExam)
        return (<>
            {!this.props.initExam && <button className={`${classes.customButton} ${classes.audioPlayer}`} onClick={this.handleShowAudioPlayer}>
                Audio Player
            </button>
            }
            {this.showAudioPlayer && this.renderAudioPlayer()}
        </>
        )
    }
}

AudioPlayer.propTypes = {
    id: PropTypes.string.isRequired,
    examProfile: PropTypes.object.isRequired,
    initExam: PropTypes.bool.isRequired
}

export default AudioPlayer;
