import { Err } from "../../libs/logger";
import { fetchVideoServers } from "../../libs/proctor_utils"

export const __VideoRouter__ = function (_this) {
    this.videoServers = [
    ]
    this.updateVideoServerRoutineLastTs = -1;
    this.DELAY = 10000
    this.updateVideoServerRoutine = () => {
        if (Date.now() - this.updateVideoServerRoutineLastTs < this.DELAY) {
            return;
        }
        this.updateVideoServerRoutineLastTs = Date.now();
        fetchVideoServers((error) => {
            Err(error)
        }).then((data) => {
            if (data?.status === "success") {
                this.videoServers = data.result
                _this.pm.updateVideoRouter(this.videoServers)
            } else {
                Err(data.errorMessage)
            }
        })
    }
}