import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { getTokenName } from "../libs/proctor_utils";
import { setExamId } from "../libs/utils";

let logoutTimer;

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  profile: {},
  examId: "",
  login: (token) => { },
  logout: () => { },
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem(getTokenName());
  const storedExpirationDate = localStorage.getItem("expirationTime");
  const storedUserProfile = localStorage.getItem("privileges");
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem(getTokenName());
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("privileges");
    return null;
  }

  return { token: storedToken, duration: remainingTime, userProfile: storedUserProfile };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  let initialUserProfile;
  if (tokenData) {
    initialToken = tokenData.token;
    initialUserProfile = tokenData.userProfile;
  }

  const [token, setToken] = useState(initialToken);
  const [userProfile, setUserProfile] = useState(initialUserProfile);
  const [updateRequired, setUpdateRequired] = useState(false);

  const userIsLoggedIn = !!token;
  const userProfileLoggedIn = userProfile;

  const logoutHandler = useCallback(() => {
    setToken(null);
    setUserProfile({});
    localStorage.removeItem(getTokenName());
    localStorage.removeItem("expirationTime");
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    window.parent.postMessage('Logout', '*');
    // window.parent.postMessage('Logout', telkomPageURL);
    sessionStorage.removeItem('directUser');
  }, []);

  const loginHandler = (
    role,
    token,
    username,
    privileges,
    examid,
    expirationTime
  ) => {
    setToken(token);
    setUserProfile(privileges);
    localStorage.setItem("role", role);
    localStorage.setItem(`user_${role}`, username);
    localStorage.setItem(getTokenName(username), token);
    localStorage.setItem("expirationTime", expirationTime);
    setExamId(examid);
    localStorage.setItem("privileges", privileges);
    const remainingTime = calculateRemainingTime(expirationTime);
    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = useMemo(() => (
    {
      token: token,
      isLoggedIn: userIsLoggedIn,
      profile: userProfileLoggedIn,
      login: loginHandler,
      logout: logoutHandler,
      participantVideoUpdateRequired: updateRequired,
      setParticipantVideoUpdateRequired: setUpdateRequired
    }
  ))

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.object.isRequired
}
export default AuthContext;
