import chatbubble from "../../assets/imgs/chat.png";
import minimize from "../../assets/imgs/minimize.png";

import ChatWs from "../../libs/ChatWs";
import { Lang } from "../../libs/Language";
import MessageEvent from "../../libs/MessageEvent";
import PrivateMessage from "../../libs/PrivateMessage";
import newmessage from "../../assets/imgs/newmessage.png";
import { Debug, Err } from "../../libs/logger";
import Chat from "../../components/Chat/Chat";

const CHATWS_RECALL = 60000;
const NEW_MESSAGE_KEY = "std::msg::par-recv";
const CHATWS_RECONNECT_PERIODE = 60000 * 10; // in ms, 60000 = 1m

/*
Implementasi PING, 
chatwsage akan di update apabila mendapatkan ping atau message dari 
*/

export const __ChatWs__ = function (_this, classes) {
  _this.messageEvent = new MessageEvent(_this, NEW_MESSAGE_KEY);
  _this.privateMessage = new PrivateMessage();
  _this.chatWs = null;
  _this.chatWsRegistered = false;
  _this.chatWsLastRegisteredTs = 0;
  _this.mode = _this.props.mode;
  _this.documentTitle = document.title;
  _this.chatWsAge = Date.now();

  //local variable to store last state of show/hide chat box
  // this.showChatBox = false
  this.sendChatBoxIsShownTs = 0;

  /*****************/
  /*
  /* ChatWs Routine
  /*****************/
  this.chatMessageHandler = (msg) => {
    let foundCloseChatBox = false;

    // message is received from server
    let listMessage = msg.message;
    if (listMessage.length <= 0) {
      Debug("message zero, ignore")
      return;
    } else {
      Debug(`got message=${listMessage}`);
    }
    if (listMessage.length > 0) {
      // set also maximizeChatLastTs so the minimize process 
      // can be postponed
      // Debug("set maximize last ts", msg)
      this.maximizeChatLastTs = Date.now();
    }

    for (let m in listMessage) {
      let message = listMessage[m]

      let msg = message;
      let proctorerId = msg.from_id;

      if (msg.text.includes("[cmd]")) {
        if (msg.text === "[cmd]close_your_window") {
          if (_this.chatWs) {
            _this.chatWs.sendChatMessageReceived(
              _this.context.profile.id,
              message.from_id,
              message.ts);
          }
          foundCloseChatBox = true;
        }
      } else {
        let dx = _this.privateMessage.chatInsert(
          _this.context.profile.id,
          msg.from_id,
          msg.ts,
          msg.text)

        let ppid = _this.state.privateProctorerId;
        if (!ppid.includes(proctorerId)) {
          ppid.push(proctorerId)
        }
        _this.setState({
          privateMessage: dx,
          privateProctorerId: ppid,
          helpAlert: false,
        });

        if (_this.chatWs) {
          _this.chatWs.sendChatMessageReceived(
            _this.context.profile.id,
            message.from_id,
            message.ts);
        }

        // send to the other proctorers
        if (ppid.length > 0) {
          for (const element of ppid) {
            if (msg.from_id !== element) {
              _this.chatWs.sendChat(
                msg.from_id,
                element,
                JSON.stringify(msg),
                _this.context.profile.id
              )
            }
          }
        }
      }
    }

    if (foundCloseChatBox) {
      document.title = _this.documentTitle;

      _this.setState({
        newMessageReceived: false,
        showChatBox: false
      })
    } else if (listMessage.length > 0) {
      _this.messageEvent.setNewMessage(msg.from_id, true);

      if (_this.state.minimize === true || document.visibilityState === "hidden") {
        _this._action.playNewMessageSound();

        document.title = `* new message received, please check`;

        if (_this.state.minimize === true) {
          _this.setState({
            newMessageReceived: true,
          })
        }
        _this.setState({ showChatBox: true })
      } else if (_this.state.examProfile.hide_chat_help_button === true) {
        _this.setState({
          newMessageReceived: true,
          showChatBox: true
        })
      }
    }
  }

  this.chatServerReceivedHandler = (msg) => {
    // receive the confirmation, centang 1
    let ts = msg.ts;
    let id = msg.id;
    // let localStorageId = `${_this.context.profile.id}-${id}`
    let localStorageId = `private-chat-${_this.context.profile.id}`
    let pm = _this.privateMessage.updateState(localStorageId, ts, id, true, false);
    _this.setState({
      privateMessage: pm
    })
  }

  this.chatReceiverReceivedHandler = (msg) => {
    // indicate that the message is already received by receiver
    let ts = msg.ts;
    let id = msg.id;
    // let localStorageId = `${_this.context.profile.id}-${id}`
    let localStorageId = `private-chat-${_this.context.profile.id}`

    if (_this.chatWs) {
      _this.chatWs.sendChatConfirmed(
        _this.context.profile.id,
        ts);
    }

    let pm = _this.privateMessage.updateState(
      localStorageId, ts, id, true, true);
    _this.setState({
      privateMessage: pm
    })
  }

  this.chatGetPrivateChatHandler = (msg) => {
    let ppid = _this.state.privateProctorerId;
    if (!ppid.includes(msg.from)) {
      ppid.push(msg.from)
    }
    _this.setState({
      privateProctorerId: ppid
    })

    const msgres = _this.privateMessage.read(`private-chat-${_this.context.profile.id}`)
    if (msgres?.messages) {
      let last_ts = msg.last_ts
      if (last_ts === undefined) {
        last_ts = 0;
      }
      const messages = msgres.messages
      let pm = [];
      for (let m of messages) {
        if (m.ts > last_ts) {
          pm.push(m);
        }
      }
      _this.chatWs.sendAllPrivateChat(
        _this.context.profile.id,
        msg.from,
        pm
      )
    }

  }

  this.maximizeChatLastTs = Date.now();
  this.chatWsRoutine = () => {
    if (_this.state.examProfile.hide_chat_help_button) {
      if (_this.state.showChatBox) {
        if (Date.now() - this.sendChatBoxIsShownTs > 10000) {
          // send to proctor that chat box is shown
          let ts = Date.now();
          this.sendChatBoxIsShownTs = Date.now();
          if (_this.chatWs &&
            _this.state.privateProctorerId.length > 0) {
            for (let p of _this.state.privateProctorerId) {
              _this.chatWs.sendChat(
                _this.context.profile.id,
                p,
                JSON.stringify(_this.privateMessage.pack(
                  ts,
                  _this.context.profile.id,
                  "[cmd]chatbox_is_shown"
                )),
                _this.context.profile.id);
            }
          }
        }
      }
    }

    // handle minimize chat routine
    if (document.visibilityState === "hidden") {
      this.maximizeChatLastTs = Date.now() - 70000;
    }

    if (Date.now() - this.maximizeChatLastTs > 60000) {
      // call minimize now
      if (_this.state.minimize === false) {
        this.minimizeMaximizeChatHandler()
        // Debug("set maximize last ts from interval")
        this.maximizeChatLastTs -= 10000;
      }
    }
    // end of handle minimuze chat routine

    if (_this.chatWs === null) {
      _this.chatWs = new ChatWs((msg) => {
        // on message received
        msg = JSON.parse(msg);
        if (msg.type === "chat-message") {
          this.chatMessageHandler(msg)
        } else if (msg.type === "chat-server-recvd") {
          this.chatServerReceivedHandler(msg)
        } else if (msg.type === "chat-receiver-recvd") {
          this.chatReceiverReceivedHandler(msg)
        } else if (msg.type === "get-private-chat") {
          this.chatGetPrivateChatHandler(msg);
        }
      }, (error) => {
        if (error) {
          Err("[chatws] chatws on error, close chatws, error=", error);
        }
        // on close
        if (_this.chatWs) {
          _this.chatWs.close();
          _this.chatWsRegistered = false;
          delete _this.chatWs
          _this.chatWs = null;
        }
      })
      _this.chatWsAge = Date.now();
    } else if (_this.chatWsRegistered !== true || Date.now() - _this.chatWsLastRegisteredTs > CHATWS_RECALL) {
      let reg = _this.chatWs.registerParticipant(_this.context.profile.id);

      _this.chatWsRegistered = reg;
      if (reg) {
        _this.chatWsLastRegisteredTs = Date.now();
      }

      if (_this.state.privateProctorerId.length > 0) {
        for (const p of _this.state.privateProctorerId) {
          _this.privateMessage.sendUnsentMessage(
            _this.context.profile.id,
            // _this.state.privateProctorerId,
            p,
            _this.chatWs
          )
        }
      }
    }

    // disconnect chatws on purpose to take the benefit of autoscale
    if (_this.chatWs !== null && Date.now() - _this.chatWsAge >
      CHATWS_RECONNECT_PERIODE
    ) {
      Debug("close chatws, because reconnect");
      _this.chatWs.sendRefreshConnection(_this.context.profile.id);
      _this.chatWsAge -= 10000;
    }
  }
  /*****************/

  this.minimizeMaximizeChatHandler = () => {
    if (_this.state.minimize === true) {
      _this.setState({
        newMessageReceived: false
      })
      document.title = `${_this.documentTitle}`;
      // Debug("set maximize last ts, minimize maximize chat handler");
      this.maximizeChatLastTs = Date.now();
    }
    _this.setState({ minimizeState: "close" });
    setTimeout(() => {
      _this.setState(prevState => ({
        minimize: !prevState.minimize,
        minimizeState: "normal"
      }));
    }, 600)
  }

  this.privateChat = (mode) => {
    let hide = false;
    if (_this.state.examProfile.hide_chat_help_button === true) {
      hide = !_this.state.showChatBox;
    }

    let style = {}
    if (_this.state.examProfile.url_in_iframe === false) {
      style = {
        right: `${300 + 30}px`,
      }
    }
    return <div
      className={mode === "mobile" ?
        classes.chat_mobile :
        classes.chat_status
      }
      style={{
        ...style, visibility: hide ? "hidden" : "visible"
      }}
    >
      {_this.state.privateProctorerId.length > 0 &&
        <div className={classes.chat_status_private}
          style={{
            transform: _this.state.minimizeState === "close" ? "scale(0,0)" : "scale(1,1)",
            transformOrigin: mode === "mobile" ? "right top" : "right bottom"
          }}
        >
          <div className={classes.chat_status_private_header}>
            <button onClick={this.minimizeMaximizeChatHandler}>
              <i>
                {
                  _this.state.minimize ?
                    <>
                      <img alt="chatbubble" src={chatbubble} width="20px" />
                      {_this.state.newMessageReceived &&
                        <div
                          className={classes.chat_status_private_header_new}
                        >
                          <img
                            className={classes.icon}
                            alt="newmessage"
                            src={newmessage}
                          />
                          <span>new message</span>
                        </div>}
                    </>
                    :
                    <img alt="minimize" src={minimize} width="20px" />
                }
              </i>
            </button>
          </div>
          <div className={classes.chat_status_private_main}
            style={{
              display: _this.state.minimize ? "none" : "block"
            }}>
            <Chat
              getCommandChat={() => _this._action.fetchCommandData()}
              listCommand={_this.state.listCommand}
              title={`Private Chat`}
              privateId={_this.context.profile.id}
              onChange={() => {
                this.maximizeChatLastTs = Date.now();
              }}
              onChatSend={
                (msg) => {
                  this.maximizeChatLastTs = Date.now();

                  let id = `private-chat-${_this.context.profile.id}`
                  let ts = Date.now();
                  let message = _this.privateMessage.merge(
                    id,
                    ts,
                    _this.context.profile.id,
                    msg
                  )

                  _this.setState({
                    privateMessage: message
                  })

                  if (_this.chatWs &&
                    _this.privateMessage &&
                    _this.state.privateProctorerId.length > 0) {
                    for (let p of _this.state.privateProctorerId) {
                      _this.chatWs.sendChat(
                        _this.context.profile.id,
                        p,
                        JSON.stringify(_this.privateMessage.pack(
                          ts,
                          _this.context.profile.id,
                          msg
                        )),
                        _this.context.profile.id);
                    }
                  }
                }
              }
              broadcastMessage={_this.state.privateMessage}
              type="participant"
            />
          </div>
        </div>
      }
    </div>
  }

  this.renderProctorBroadcastChat = (message) => {
    return <div className={classes.container_broadcastProc}
      style={{
        minWidth: _this.mode === "mobile" ? "150px" : "250px",
        maxWidth: _this.mode === "mobile" ? null : "400px"
      }}>
      {/* style={{ minWidth: "150px" }}> */}
      <div className={classes.container_broadcastProc_tittle}>
        <span>{Lang(`PERHATIAN`, _this.state.lang, { en: `NOTIFICATION` })}</span>
      </div>
      <div className={[classes.container_broadcastProc_message, classes.ascroll].join(" ")}>
        {message}
      </div>
    </div>
  }

  this.processBroadcast = (msg) => {
    if (msg === undefined) {
      return "";
    }
  }
}