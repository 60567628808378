import classes from "./Collage.module.scss";
import face from "../../../assets/imgs/proctorer.png";
import speaker from "../../../assets/imgs/audioon.png";
import mute from "../../../assets/imgs/audiooff.png";
import restart from "../../../assets/imgs/restart.png";

export const renderItem = (_this, txt, cb) => {
    return <div className={[classes.btnOk, classes.screen_title_reconnect].join(" ")}
        onClick={() => {
            cb()
        }}>
        <img alt="restart" src={restart} />
        <span>
            {txt}
        </span>
    </div>
}

export const renderVideoAction = (_this, type = "liveVideo" /*liveVideo, screenCapture, mobilePairing*/) => {
    let txt = "connect screen video"
    if (type === "liveVideo") {
        txt = "connect face video";
    } else if (type === "mobilePairing") {
        txt = "connect mobile pairing video";
    }
    return <>{_this.props.pm.getDoNotRun(type, _this.state.chosen.profile.id) === true ?
        renderItem(_this, txt, () => {
            _this.props.pm.switchDoNotRun(type, _this.state.chosen.profile.id);
        })
        :
        renderItem(_this, `dis${txt}`, () => {
            _this.props.pm.switchDoNotRun(type, _this.state.chosen.profile.id);
        })
    }
    </>
}

export const renderCollage = (_this) => {
    return <div className={[classes.container, classes.FadeInPage1s].join(" ")}>
        <div className={classes.left}>
            {/* <div className={classes.left_title}> */}
            <span>Face</span>
            {/* {renderVideoAction(_this, "liveVideo")} */}
            {/* </div> */}
            <div className={classes.img}>
                <img
                    // width="100%"
                    style={{ maxHeight: "240px", cursor: "zoom-in" }}
                    alt={"face"}
                    src={_this.state.faceImgUrl}
                    onClick={(event) => {
                        if (_this.props.openImage) {
                            _this.props.onImageClose()
                        } else {
                            _this.props.onImageOpen([event.target.src])
                        }
                    }}
                />
            </div>
            <video
                controls
                id={`liveVideoDesk${_this.state.chosen.profile.id}`}
                autoPlay
                // height="200px"
                muted={_this.state.isAudioMuted}
            >
                <track kind='captions' />
            </video>
            <div
                className={classes.audio}
                onClick={() => {
                    _this.setState(prevState => ({
                        isAudioMuted: !prevState.isAudioMuted
                    }));
                }}
            >
                {
                    _this.state.isAudioMuted ?
                        <img alt="mute"
                            // width={"30px"} 
                            src={mute} /> :
                        <img alt="unmute"
                            // width={"30px"} 
                            src={speaker} />
                }
            </div>
        </div>
        <div className={classes.right}>
            <div className={classes.screen}>
                <div className={classes.screen_title}>
                    <span>Screen</span>
                    {renderVideoAction(_this, "screenCapture")}
                </div>
                <div className={classes.horiz}>
                    <div className={classes.img}>
                        <img
                            style={{
                                cursor: "zoom-in"
                            }}
                            alt="screen"
                            src={_this.state.screenImgUrl}
                            onClick={(event) => {
                                if (_this.props.openImage) {
                                    _this.props.onImageClose()
                                } else {
                                    _this.props.onImageOpen([event.target.src])
                                }
                            }}
                        />
                    </div>
                    <div>
                        <video
                            id={`screenCaptureDesk${_this.state.chosen.profile.id}`}
                            autoPlay
                            controls
                            muted={_this.state.isAudioMuted}>
                        </video>
                    </div>
                </div>
            </div>
            <div className={classes.screen}>
                <span>Mobile Pairing</span>
                <div className={classes.horiz}>
                    <div className={classes.img}>
                        <img
                            alt="mobile pairing"
                            style={{
                                cursor: "zoom-in"
                            }}
                            src={_this.state.mobilePairingImgUrl}
                            onClick={(event) => {
                                if (_this.props.openImage) {
                                    _this.props.onImageClose()
                                } else {
                                    _this.props.onImageOpen([event.target.src])
                                }
                            }}
                        />
                    </div>
                    <div>
                        <video
                            id={`mobilePairingDesk${_this.state.chosen.profile.id}`}
                            autoPlay
                            controls
                            muted={_this.state.isAudioMuted}>
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
}