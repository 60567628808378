import { Err } from "../libs/logger";
import { fetchGetAgreement } from "../libs/proctor_utils";
import classes from "./TermsAndConditions.module.scss";

export const __TermsAndConditions__ = function (_this, url = "telu.amanin.id") {
    // Debug("URL = ", url)
    // init is here
    // set initial state is here
    _this.state = {
        ..._this.state,
        showTerms: undefined
        // showTerms: true
    }

    _this.agreementInterval = null;

    this.unmount = () => {
        clearInterval(_this.agreementInterval);
    }
    this.mount = () => {
        clearInterval(_this.agreementInterval);
        _this.agreementInterval = setInterval(() => {
            if (_this.context?.profile?.id === undefined) {
                return;
            }
            fetchGetAgreement(_this.context?.profile?.id, (e) => {
                Err(e);
            }).then((data) => {
                if (data.status === 'success') {
                    const dt = data.result;
                    if (dt.terms_and_conditions) {
                        clearInterval(_this.agreementInterval);
                        _this._action.redirectOnLogin();
                    }
                }
            })
        }, 5000)

        if (_this.state.showTerms === undefined && _this.context?.profile?.id !== undefined) {
            const v = _this.context?.profile?.terms_and_conditions
            _this.setState({
                showTerms: v === null ? true : !v
            })
        }

    }
    this.render = () => {
        return _this.state.showTerms && <div className={classes.terms}>
            <div>
                <iframe
                    title="terms"
                    src={`https://amanin.id/termsandconditions?id=${_this.context?.profile?.id}&url=${url}`}
                />
            </div>
        </div>
    }
}
