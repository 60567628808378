import { Err } from "./logger";

class AudioAnalyzer {
    analyzeStream = (stream, onDetected = null, onStreamAvailable = null) => {
        // if (onStreamAvailable) {
        //     onStreamAvailable(stream);
        // }
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        const microphone = audioContext.createMediaStreamSource(stream);

        const analyser = audioContext.createAnalyser();
        microphone.connect(analyser);

        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        function detectSound() {
            analyser.getByteFrequencyData(dataArray);

            const averageAmplitude = dataArray.reduce((acc, value) => acc + value, 0) / bufferLength;
            if (onDetected) {
                onDetected(averageAmplitude, dataArray)
            }
            requestAnimationFrame(detectSound);
        }
        audioContext.resume().then(() => {
            detectSound();
        });
    }

    analyze(onDetected, onStreamAvailable) {
        // return
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                this.analyzeStream(stream, onDetected, onStreamAvailable)
            })
            .catch(error => {
                Err('Error accessing microphone:', error);
            });
    }
};

export default AudioAnalyzer;