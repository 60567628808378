import { ErrorAlert, SuccessAlert } from "../../../components/Alert";
import { Lang } from "../../../libs/Language";
import { Debug, Err, Info } from "../../../libs/logger"
import { fetchNotes, fetchParticipantByParticipantId, fetchSaveScreenShotList, fetchScreenshotList, urlLiveScreen, urlMobilePairing, urlWebCam } from "../../../libs/proctor_utils"
import { LANG } from "../../../libs/utils";

export const fetchDetailParticipant = (_this, uuid, cb) => {
    fetchParticipantByParticipantId(uuid, (err) => {
        Err(err);
    }).then((v) => {
        if (v.status === 'success') {
            setTimeout(() => {
                const res = v.result;
                _this.setState({
                    isloading: false,
                    chosen: {
                        uuid: res.uuid,
                        profile: res
                    }
                })
                setTimeout(() => {
                    updateImage(_this);
                }, 100)

                setTimeout(() => {
                    loadPopupVideo(_this, "liveVideo")
                    loadPopupVideo(_this, "mobilePairing")
                    loadPopupVideo(_this, "screenCapture")
                }, 1000);
                _this.props.onChangeChosenParticipant(res);

                cb && cb(res.id)
            }, 100)
        }
    })
}

export const updateImage = (_this) => {
    _this.setState({
        screenImgUrl: urlLiveScreen(_this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand, Date.now()),
        faceImgUrl: urlWebCam(_this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand, Date.now()),
        mobilePairingImgUrl: urlMobilePairing(_this.state.chosen.profile?.id, _this.state.chosen.profile?.folder_rand, Date.now()),
    })
}

export const loadPopupVideo = (_this, type /* liveVideo, mobilePairing, screenCapture*/) => {
    const elid = `${type}_proctor${_this.state.chosen.profile?.xrcp}${_this.state.chosen.profile?.id}`
    const el = document.getElementById(elid);
    const deskid = `${type}Desk${_this.state.chosen.profile?.id}`

    setTimeout(() => {
        const elx = document.getElementById(deskid)
        if (elx !== null && elx !== undefined) {
            if (elx.srcObject === null) {
                document.getElementById(deskid).srcObject = el?.srcObject;
            }
        } else {
            loadPopupVideo(_this, type);
        }
    }, 1000)
}

export const getParticipantScreenshotNotes = (_this, participantId, folderRand) => {
    fetchScreenshotList(participantId, folderRand, (err) => {
        Debug("[ERROR]", err);
    }).then((res) => {
        if (res && res.status === 'success') {
            _this.setState({ screenshotNotes: res.result })
        }
    }).catch(err => {
        Debug(err)
    })
}

export const onImageModalOpen = (_this, imageUrl) => {
    return _this.setState({ isImageModalOpen: true, selectedImage: imageUrl })
}
export const onImageModalClose = (_this) => {
    return _this.setState({ isImageModalOpen: false, selectedImage: "" })
}

export const saveScreenshot = async (_this) => {
    try {
        let els_face = _this.participantImageRef.current;
        let els_screen = _this.screenImageRef.current;
        let els_mobile = _this.mobileImageRef.current;

        if (els_face === undefined || els_face === null) {
            return;
        }

        if (els_screen === undefined || els_screen === null) {
            return;
        }

        let canvas_face = document.createElement('canvas');
        let canvas_screen = document.createElement('canvas');
        let canvas_mobile = document.createElement('canvas');

        canvas_face.width = 640;
        canvas_face.height = 480;

        canvas_screen.width = 854;
        canvas_screen.height = 480;

        canvas_mobile.width = 854;
        canvas_mobile.height = 480;

        let ctx_face = canvas_face.getContext('2d');
        let ctx_screen = canvas_screen.getContext('2d');
        let ctx_mobile = canvas_mobile.getContext('2d');

        ctx_face.drawImage(els_face, 0, 0, canvas_face.width, canvas_face.height);
        let image_face = canvas_face.toDataURL('image/jpeg');

        ctx_screen.drawImage(els_screen, 0, 0, canvas_screen.width, canvas_screen.height);
        let image_screen = canvas_screen.toDataURL('image/jpeg');

        let image_mobile;
        if (_this.MOBILE_PAIRING) {
            ctx_mobile.drawImage(els_mobile, 0, 0, canvas_mobile.width, canvas_mobile.height);
            image_mobile = canvas_mobile.toDataURL('image/jpeg');
        }
        // SAVE IMAGE
        // if (_this.props.imageWs !== null && _this.props.imageWs.sendFaceScreenScreenShotImage(_this.props.data?.id, image_face, image_screen, _this.screenShotRef.current.value, _this.props.currentTs, _this.props.data?.folder_rand) === false) {
        //     _this.props.imageWs.close();
        //     _this.props.imageWs = null;
        // }

        let data = {
            id: _this.state.chosen?.profile?.id,
            img_face: image_face,
            img_screen: image_screen,
            image_mobile: image_mobile ? image_mobile : "",
            notes: _this.screenShotRef.current.value,
            folder_rand: _this.state.chosen?.profile?.folder_rand,
            ts: Date.now(),
        }

        await fetchSaveScreenShotList(data, (err) => {
            Err(err);
        }).then((res) => {
            if (res.status === 'success') {
                SuccessAlert(Lang("Data berhasil disimpan", LANG(), { en: `Data is saved successfully` }));
                _this.screenShotRef.current.value = "";
            }
        }).catch(error => {
            Err(error)
            ErrorAlert(Lang("Data gagal disimpan, silakan coba kembali.", LANG(), { en: `Data is failed to save, try again.` }))
        })

        canvas_face.remove();
        canvas_screen.remove();
        canvas_mobile.remove();

    } catch (e) {
        Err(e)
    }
}

export const saveNotes = (_this, flagAttention, cb) => {

    fetchNotes(
        _this.state.chosen?.profile?.note_proctorer_id,
        _this.state.chosen?.profile?.id,
        _this.notesRef?.current === null ? _this.state.chosen?.profile?.notes :
            _this.notesRef.current.value,
        flagAttention, (e) => {
            Debug(e);
            ErrorAlert("Data gagal disimpan, silakan coba kembali.");
        }).then((data) => {
            if (data !== null && data.status === "success") {
                SuccessAlert("Data berhasil disimpan");
                cb()
            } else {
                ErrorAlert("Data gagal disimpan, silakan coba kembali.")
            }

        })
}