import { Debug, Err } from "../logger";
import { fetchJSONMessageCommon } from "../proctor_utils";
import { getAccessToken, getExamId } from "../utils";

export function sendStoreParticipantImage(_this, participantId, proctorId = null) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: "store-participant-image",
                participantId: participantId,
                'folder-rand': _this.folderRand,
                proctorId: proctorId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendRefreshConnection(_this, participantId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: "refresh-connection",
                participantId: participantId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendBroadcastToParticipantPost(_this, from, to, msg, participantId, cb) {
    fetchJSONMessageCommon("broadcast-alert", {
        chat: {
            from: from,
            to: to,
            msg: msg,
            participantId: participantId
        },
        examid: getExamId()
    }, (err) => {
        Err(err);
        cb(false)
    }).then((v) => {
        Debug(v)
        cb(true)
    })
}

export function sendBroadcastToParticipant(_this, from, to, msg, participantId = null) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify(
                {
                    type: "broadcast-alert",
                    chat: {
                        from: from,
                        to: to,
                        msg: msg,
                        participantId: participantId
                    },
                    token: getAccessToken(),
                    examid: getExamId()
                }));
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}

export function sendUpdateVideoDistribution(_this, participantId, proctorerId, ipaddress, stats) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(JSON.stringify({
            type: "update-video-distribution",
            participantId: participantId,
            proctorerId: proctorerId,
            ipaddress: ipaddress,
            stats: stats,
            token: getAccessToken(),
            examid: getExamId()
        }));
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}

export function sendUpdateMiscInformation(_this, participantId, info) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: "misc",
                id: participantId,
                token: getAccessToken(),
                info: info,
                examid: getExamId()
            })
        )
    }
}

export function sendLogout(_this, participantId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(JSON.stringify({
            type: "logout",
            participantId: participantId,
            token: getAccessToken(),
            examid: getExamId()
        }));
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}


export function sendAIWarning(_this, from, warning_type, ts, warningdata = null) {
    // AI Warning Type
    /*
    0 - no face detected
    1 - face detected more than 1
    2 - face id unmatch
    3 - open new tab
    */
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify(
                {
                    type: "aiwarning",
                    from: from,
                    warningtype: warning_type,
                    ts: ts,
                    'folder-rand': _this.folderRand,
                    warningdata: warningdata,
                    token: getAccessToken(),
                    examid: getExamId()
                }
            )
        )
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}

export function sendKeyStroke(_this, from, key) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify(
                {
                    type: "keystroke",
                    from: from,
                    key: key,
                    'folder-rand': _this.folderRand,
                    token: getAccessToken(),
                    examid: getExamId()
                }
            )
        )
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}

export function sendPing(_this, from) {
    // Debug("[commonws] send ping");
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify(
                {
                    type: "ping",
                    from: from,
                    'folder-rand': _this.folderRand,
                    token: getAccessToken(),
                    examid: getExamId()
                }
            )
        )
    }
}