import { sendStartWebRTCPrivate } from "../../../libs/CommonWebsocket/WebRTC";
import { Debug, Err, Info } from "../../../libs/logger";
import WebRTCGeneral from "../../../libs/WebRTC/WebRTCGeneral";
import WebRTCLiveVideo from "../../../libs/WebRTC/WebRTCLiveVideo";
import WebRTCPrivateCall from "../../../libs/WebRTC/WebRTCPrivateCall";
import { DISABLE_WEBRTC } from "./CommonWs";

// export const sendStartWebRTCHandler = (_this, msg) => {
//     if (DISABLE_WEBRTC === true) {
//         return;
//     }
//     const participantId = msg.participantId;
//     const proctorerId = msg.proctorerId;
//     const proctorId = msg.proctorId;
//     const videoRouter = msg.videoRouter;

//     if (videoRouter === null) {
//         Err("Video Router is null, video stream will not be available.")
//         return;
//     }

//     if ((_this.state.displayMediaStream === null && _this.mode !== "mobile") ||
//         _this.state.userMediaStream === null) {
//         _this.commonWs.sendWebRTCFailed(false, proctorerId, participantId, proctorId);
//         return;
//     }
//     if (_this.webrtc !== null) {
//         _this.webrtc.close();
//         delete _this.webrtc;
//         _this.webrtc = null;
//     }

//     _this.webrtc = new WebRTCLiveVideo(
//         _this.context.profile.ice_id,
//         _this.context.profile.id,
//         _this.context.profile.name,
//         videoRouter,
//         () => {
//             _this.commonWs.sendWebRTCStarted(proctorerId, participantId, proctorId);
//         },
//         true,
//         _this.state.webRtcMediaStream,
//         null,
//         (error) => {
//             if (error === "webrtc disconnected") {
//                 Debug("**************** WEBRTC DISCONNECTED **************");
//                 _this.webrtc.close();
//                 delete _this.webrtc;
//                 _this.webrtc = null;
//                 return;
//             }
//             Debug("GOT WEBRTC ERROR");
//             _this.commonWs.sendWebRTCFailed(true, proctorerId, participantId, proctorId);
//         }
//     )
// }

// // mobile pairing
// export const startWebRTCMobilePairingHandler = (_this, msg) => {
//     Debug("[debug] start webrtc mobile pairing handler");
//     if (DISABLE_WEBRTC === true) {
//         return;
//     }
//     const participantId = msg.participantId;
//     const proctorerId = msg.proctorerId;
//     const proctorId = msg.proctorId;
//     const videoRouter = msg.videoRouter;

//     if (videoRouter === null) {
//         Err("Video Router is null, video stream will not be available.")
//         return;
//     }

//     if (_this.state.mobilePairingStream === null) {
//         _this.commonWs.sendWebRTCGeneralFailed(false, proctorerId, participantId, proctorId);
//         return;
//     }

//     if (_this.webrtc !== null) {
//         _this.webrtc.close();
//         delete _this.webrtc;
//         _this.webrtc = null;
//     }

//     _this.webrtc = new WebRTCGeneral(
//         "mobilepairing_",
//         _this.context.profile.id,
//         _this.context.profile.name,
//         videoRouter,
//         () => {
//             _this.commonWs.sendWebRTCGeneralStarted(proctorerId, participantId, proctorId);
//         },
//         true,
//         _this.state.mobilePairingStream,
//         (error) => {
//             if (error === "webrtc disconnected") {
//                 Debug("**************** WEBRTC GENERAL DISCONNECTED **************");
//                 _this.webrtc.close();
//                 delete _this.webrtc;
//                 _this.webrtc = null;
//                 return;
//             }
//             Debug("GOT WEBRTC GENERAL ERROR");
//             _this.commonWs.sendWebRTCGeneralFailed(true, proctorerId, participantId, proctorId);
//         }
//     )
// }

// live video
// export const startWebRTCHandler = (_this, msg) => {
//     if (DISABLE_WEBRTC === true) {
//         return;
//     }
//     const participantId = msg.participantId;
//     const proctorerId = msg.proctorerId;
//     const proctorId = msg.proctorId;
//     const videoRouter = msg.videoRouter;

//     if (videoRouter === null) {
//         Err("Video Router is null, video stream will not be available.")
//         return;
//     }

//     if ((_this.state.displayMediaStream === null && _this.mode !== "mobile") ||
//         _this.state.userMediaStream === null) {
//         _this.commonWs.sendWebRTCFailed(false, proctorerId, participantId, proctorId);
//         return;
//     }
//     if (_this.webrtc !== null) {
//         _this.webrtc.close();
//         delete _this.webrtc;
//         _this.webrtc = null;
//     }

//     _this.webrtc = new WebRTCLiveVideo(
//         _this.context.profile.ice_id,
//         _this.context.profile.id,
//         _this.context.profile.name,
//         videoRouter,
//         () => {
//             _this.commonWs.sendWebRTCStarted(proctorerId, participantId, proctorId);
//         },
//         true,
//         _this.state.webRtcMediaStream,
//         null,
//         (error) => {
//             if (error === "webrtc disconnected") {
//                 Debug("**************** WEBRTC DISCONNECTED **************");
//                 _this.webrtc.close();
//                 delete _this.webrtc;
//                 _this.webrtc = null;
//                 return;
//             }
//             Debug("GOT WEBRTC ERROR");
//             _this.commonWs.sendWebRTCFailed(true, proctorerId, participantId, proctorId);
//         }
//     )
// }

// private
export const sendStartWebRTCPrivateHandler = (_this, msg) => {
    Debug(`start webrtc private`);
    if (DISABLE_WEBRTC === true) {
        return;
    }
    const participantId = msg.participantId;
    const proctorerId = msg.proctorerId;
    const proctorId = msg.proctorId;
    const videoRouter = msg.videoRouter;
    Debug(`start webrtc private`, msg);

    if (videoRouter === null) {
        Err("Video Router is null, video stream will not be available.")
        return;
    }

    if (_this.webrtc['privateCall'] !== null) {
        _this.webrtc['privateCall'].close();
        delete _this.webrtc['privateCall'];
        _this.webrtc['privateCall'] = null;
    }

    _this.webrtc['privateCall'] = new WebRTCPrivateCall(
        _this.context.profile.id,
        _this.context.profile.name,
        videoRouter,
        () => {
            Debug("send webrtc private started", proctorerId, participantId, proctorId, ", videoRouter=", videoRouter);
            _this.commonWs.sendWebRTCPrivateStarted(proctorerId, participantId, proctorId, videoRouter);
        },
        true,
        _this.state.webRtcMediaStream,
        (error) => {
            Debug("WEBRTC DISCONNECTED ERROR=", error);
            if (error === "webrtc disconnected") {
                Debug("**************** WEBRTC DISCONNECTED **************");
                _this.webrtc['privateCall'].close();
                delete _this.webrtc['privateCall'];
                _this.webrtc['privateCall'] = null;
                return;
            }
            Error("GOT WEBRTC ERROR", error);
            _this.commonWs.sendWebRTCPrivateFailed(true, proctorerId, participantId, proctorId);
        }
    )
}



export const startWebRTCGeneralHandler = (_this, msg) => {
    const getParticipantStream = (calltype) => {
        if (calltype === "liveVideo") {
            return _this.state.userMediaStream
        } else if (calltype === "mobilePairing") {
            return _this.state.mobilePairingStream
        } else if ( calltype === "screenCapture") {
            return _this.state.displayMediaStream
        }
        return _this.state.userMediaStream;
    }

    // const getWebRTCInstance = (calltype) => {
    //     if ( calltype === "liveVideo") {
    //         return _this.webrtc;
    //     } else if ( calltype === "privateCall") {
    //         return _this.webrtc_private;
    //     } else if ( calltype === "screenCapture") {
    //         return _this.webrtc_screencapture;
    //     }
    //     return _this.webrtc;
    // }

    Debug("[debug] start webrtc general handler");
    if (DISABLE_WEBRTC === true) {
        return;
    }
    const { calltype, participantId, proctorerId, proctorId, videoRouter } = msg;

    if (videoRouter === null) {
        Err("Video Router is null, video stream will not be available.")
        return;
    }

    if (getParticipantStream(calltype) === null) {
        // Err(`[${calltype}] get participant stream == null`);
        _this.commonWs.sendWebRTCGeneralFailed(calltype, false, proctorerId, participantId, proctorId);
        return;
    }

    if (_this.webrtc[calltype]) {
        _this.webrtc[calltype].close();
        delete _this.webrtc[calltype];
        _this.webrtc[calltype] = null;
    }

    _this.webrtc[calltype] = new WebRTCGeneral(
        calltype,
        _this.context.profile.id,
        _this.context.profile.name,
        videoRouter,
        () => {
            _this.commonWs.sendWebRTCGeneralStarted(calltype, proctorerId, participantId, proctorId);
        },
        true,
        getParticipantStream(calltype),
        (error) => {
            if (error === "webrtc disconnected") {
                Debug("**************** WEBRTC GENERAL DISCONNECTED **************");
                _this.webrtc[calltype].close();
                delete _this.webrtc[calltype];
                _this.webrtc[calltype] = null;
                return;
            }
            Debug("GOT WEBRTC GENERAL ERROR, error=", error);
            _this.commonWs.sendWebRTCGeneralFailed(calltype, true, proctorerId, participantId, proctorId);
        }
    )
}