import React from "react";
import anxiety from '../../assets/imgs/anxiety.png'
import refresh from '../../assets/imgs/refresh.png'
import logout from '../../assets/imgs/logout_force.png'


export const __Component__ = function (_this, classes) {


    this.navbar = () => {
        const { isSpinning, reverseSpin } = _this.state;
        return (
            <div className={classes.navbar}>
                <div className={classes.navItem}>
                    <img src={anxiety} alt="Home" />
                    <span>{_this.context.profile.id}</span>
                    {/* <span>Home</span> */}
                </div>
                <div className={classes.navItem}>
                    <img src={anxiety} alt="Search" />
                    {/* <span>Search</span> */}
                    <span></span>
                </div>
                <div className={`${classes.navItem} ${classes.centerItem}`}>
                    <div className={classes.circle}>
                        <img
                            src={refresh}
                            alt="Switch Camera"
                            className={isSpinning ? (reverseSpin ? classes.spinReverse : classes.spin) : ''}
                            onClick={_this._actionmp.switchCamera}
                        />
                    </div>
                </div>
                <div className={classes.navItem}>
                    <img src={anxiety} alt="Cart" />
                    {/* <span>Cart</span> */}
                    <span></span>
                </div>
                <div className={classes.navItem}>
                    <img src={logout} alt="Profile" />
                    <span>Logout</span>
                </div>
            </div>
        );
    }
}