/**** Common Ws ****/

import { AttentionAlert } from "../../../components/Alert";
import CommonWebsocket from "../../../libs/CommonWebsocket/CommonWebsocket";
import { Lang } from "../../../libs/Language";
import { Debug, Err, Info } from "../../../libs/logger";
import { sendStartWebRTCHandler, sendStartWebRTCPrivateHandler, startWebRTCGeneralHandler, startWebRTCHandler, startWebRTCMobilePairingHandler } from "./WebRTC";

export const DISABLE_WEBRTC = false;
export const COMMONWS_RECONNECT_PERIODE = 60000 * 10; // in ms, 60000 = 1m

export const __CommonWs__ = function (_this) {
    _this.updateTsRoutineLastTs = 0;
    _this.commonWsAge = Date.now();

    _this.webrtc = {
        liveVideo: null,
        screenCapture: null,
        privateCall: null
    };
    // _this.webrtc_private = null;

    _this.commonWsLastReadyTs = Date.now();
    _this.commonWsReinitialized = false;
    _this.currentTs = 0;

    this.validationStatusHandler = (msg) => {
        // Update API fetchExamProfile validation_status
        _this._action?.setValidationStatus(msg.status === "valid" ? 1 : 0)
    }

    this.commonWsOnMessageReceived = (_this, msg) => {
        msg = JSON.parse(msg);
        Debug(msg);
        if (msg.type === 'start-webrtc') {
            this.startWebRTCHandler(msg);
        } else if (msg.type === 'start-webrtc-private') {
            this.startWebRTCPrivateHandler(msg)
        } else if (msg.type === 'start-webrtc-mobile-pairing') {
            this.startWebRTCMobilePairingHandler(msg)
        } else if (msg.type === 'start-webrtc-general') {
            this.startWebRTCGeneralHandler(msg)
        } else if (msg.type === "store-participant-image") {
            const use_ts = Date.now()
            _this._action?.sendWebCamImageToServer("face", use_ts, true);
            _this._action?.sendWebCamImageToServer("screen", use_ts, true);
        } else if (msg.type === "broadcast") {
            _this.setState({ broadcastMessage: msg });
        } else if (msg.type === "broadcast-to-participant") {
            if (_this.state.broadcastFromProctorerMessage !== msg["text"] && _this.context.profile?.role !== "participant-mobile-pairing") {
                _this.setState({ broadcastFromProctorerMessage: msg["text"] });
                AttentionAlert(msg["text"])
            }
        } else if (msg.type === "validation-status") {
            this.validationStatusHandler(msg)
        } else if (msg.type === "currentTS") {
            _this.currentTs = _this.currentTs !== 0 && msg.ts;
        } else if (msg.type === "update-ts-confirmed") {
            /*
            _this.updateTsRoutineLastTs is the important state which schedule
            proctorerImageRef, do not comment it !!!
            */
            _this.updateTsRoutineLastTs = Date.now();
            Debug('[update-ts] confirmed');
        }

        // Detect Keluar tab dan print screen
        if (_this.state.initExam === false) {
            document.addEventListener('visibilitychange', () => { _this._warning._openNewTabOrBrowserWarning() });
            // document.addEventListener('keyup', (e) => { _this._warning._printScreenWarning(e) });
        }
    }

    this.commonWsOnError = (_this, err) => {
        if (err) {
            Err("[commonws] commonws on error, close commonws, error=", err);
        }
        delete _this.commonWs;
        _this.commonWs = null;
        _this.pai?.setCommonWs(null);

    }

    this.commonWsRoutine = () => {
        if (_this.state.initProktorAIStatus === false ||
            _this.state.fetchExamStatus === false ||
            _this.state.fetchParticipantStatus === false) {
            // Debug("fetch All is not yet done");
            _this.commonWsLastReadyTs = Date.now()
            return;
        }

        if (_this.commonWs === null) {
            if (Date.now() - _this.commonWsLastReadyTs > 20000) {
                _this.setState({
                    commonWsRegistered: false,
                    connectingMessage: Lang("Koneksi anda terputus, mencoba terhubung kembali.", _this.state.lang, { en: `Your connection is lost, try reconnecting.` })
                });
            }
        } else if (_this.commonWs.ready() === true) {
            _this.commonWsLastReadyTs = Date.now();
        } else if (Date.now() - _this.commonWsLastReadyTs > 10000) {
            _this.setState({
                commonWsRegistered: false,
                connectingMessage: Lang("Koneksi anda terputus, mencoba terhubung kembali.", _this.state.lang, { en: `Your connection is lost, try reconnecting.` })
            });
        }


        // disconnect commonws on purpose to take the benefit of autoscale
        if (_this.commonWs !== null && Date.now() - _this.commonWsAge >
            COMMONWS_RECONNECT_PERIODE
        ) {
            Debug("close commonws, because reconnect");
            _this.commonWs.sendRefreshConnection(_this.context.profile.id);

            // on reconnect do not call below, because it will trigger
            // on failed connection and set ts of participants or proctorer to 0, 
            // make online indicator become unstable
            // // _this.commonWs.close();
            // // _this.commonWsAge = Date.now();
        }

        if (_this.commonWs === null) {
            _this.commonWs = new CommonWebsocket(_this, _this.context.profile.folder_rand, this.commonWsOnMessageReceived, this.commonWsOnError);
            _this.commonWsReinitialized = true;
            _this.updateTsRoutineLastTs = Date.now();
            _this.commonWsAge = Date.now();
        }
        if (_this.state.commonWsRegistered === false || _this.commonWsReinitialized === true) {
            let reg = {}
            if (_this.context.profile.role === "participant-mobile-pairing") {
                reg = _this.commonWs.registerParticipantMobilePairing(_this.context.profile.id);
            } else {
                reg = _this.commonWs.registerParticipant(_this.context.profile.id);
            }
            _this.commonWsReinitialized = !reg;
            if (reg === true) {
                _this.setState({
                    commonWsRegistered: !_this.commonWsReinitialized
                });
                _this.pai?.setCommonWs(_this.commonWs);

                if (_this._action?.getCurrentTsRoutine) {
                    _this._action?.getCurrentTsRoutine();
                }
            }
        }
    }

    // this.startWebRTCHandler = (msg) => startWebRTCHandler(_this, msg);
    // webrtc private handler 
    this.startWebRTCPrivateHandler = (msg) => sendStartWebRTCPrivateHandler(_this, msg);
    // webrtc mobile pairing handler
    // this.startWebRTCMobilePairingHandler = (msg) => startWebRTCMobilePairingHandler(_this, msg);
    // webrtc handler
    // this.startWebRTCHandler = (msg)  => sendStartWebRTCHandler(_this, msg);
    // webrtc general
    this.startWebRTCGeneralHandler = (msg) => startWebRTCGeneralHandler(_this, msg);
}