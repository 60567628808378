import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { fetchLogin, fetchLoginOAUTH } from "../../libs/proktor_first_utils";
import { fetchCreateExamProfileEms, fetchJoinExam } from "../../libs/proctor_utils_ext";

// const GOOGLE_CLIENT_ID = "833620407736-ilgarviq67sq2v8chi6nc7r98gild1uo.apps.googleusercontent.com";
const GOOGLE_CLIENT_ID = "554916165593-u2sse677o7h43i7h2q3kdc1o88glov3i.apps.googleusercontent.com";

const FACEBOOK_CLIENT_ID = "1027396358495821";
const FACEBOOK_CLIENT_SECRET = "5b3b8b93c4f85600552961d55a518305";

const GITHUB_CLIENT_ID = "93bf84838ff4356fe7c5";
const GITHUB_CLIENT_SECRET = "c1c22e12cfb481415e84eb0a36e5f102d0a19240";

export const __Action__ = function (_this, classes) {
    this.onLoginOAUTH = (data) => {
        _this.setState({ showLoading: "Logging In ...", showLoginModal: false })
        setTimeout(() => {
            fetchLoginOAUTH(data, (error) => {
                console.error("error=", error);
            }).then((data) => {
                let profile = data.result;
                // profile = {
                //     id: 'deddihp@gmail.com',
                //     photo: 'https://amanin.id/photos/deddihp.jpg',
                //     name: 'Deddi Hariprawira',
                //     email: 'deddihp@gmail.com',
                //     package_name: 'unknown',
                //     running_exam: [],
                //     max_duration: 100,
                //     total_duration_today: 0,
                // }
                localStorage.setItem("profile", btoa(JSON.stringify(profile)))
                _this.setState({
                    userProfile: profile,
                    showLoginModal: false,
                })
            }).finally(() => { _this.setState({ showLoading: "" }) });
        }, 1000)
    }

    this.onFormGoogleAuth = () => {
        return <>
            <LoginSocialGoogle
                client_id={GOOGLE_CLIENT_ID}
                onLoginStart={() => {
                    _this.setState({ showLoading: "Authenticating ...", showLoginModal: false })
                }}
                onResolve={({ provider, data }) => {
                    this.onLoginOAUTH({
                        id: data.email,
                        name: data.name,
                        photo: data.picture
                    })
                }}
                onReject={(err) => {
                    // pop up window close
                    // console.log(err)
                    _this.setState({ showLoading: "" });
                }}
                scope="email profile"
            >
                <GoogleLoginButton
                    style={{
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px"
                    }}
                />
            </LoginSocialGoogle>
        </>
    }

    this.onFormFacebookAuth = () => {
        return <>
            <LoginSocialFacebook
                appId={FACEBOOK_CLIENT_ID}
                onResolve={({ provider, data }) => {
                    console.log(provider, data)
                    this.onLoginOAUTH({
                        id: data.email,
                        name: data.name,
                        photo: ""
                    })
                }}
                onReject={(err) => {
                    console.log(err)
                }}
                scope={"email public_profile"}
            >
                <FacebookLoginButton
                    style={{
                        borderRadius: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: "20px"
                    }}
                />
            </LoginSocialFacebook>
        </>
    }

    this.handleCreateExam = (data) => {
        _this.setState({ showLoading: "Loading ...", showCreateExamModal: false })
        fetchCreateExamProfileEms(data, (error => {
            alert(error.message)
        })).then((response) => {
            if (response.status === "success") {
                this.directExam(response?.result?.id)
            } else if (response.status === "failed") {
                alert(response.errorMessage)
            }
        }).catch(error => {
            alert(error.message)
        }).finally(() => {
            setTimeout(() => {
                _this.setState({ showLoading: "" })
            }, 2000);
        })
    }


    this.loginHandler = (data) => {
        if (data === undefined || data === null || data.status !== "success") {
            alert("Username dan Password tidak cocok.");
            return;
        }

        if (data.status === "success") {
            let profile = data.result;
            profile.exam_id = _this.examId
            localStorage.setItem("profile", btoa(JSON.stringify(profile)))
            _this.setState({ userProfile: profile, showLoginModal: false })
            setTimeout(() => {
                _this._termsandconditions.getData();
            }, 1000)
        } else {
            alert(data.errorMessage);
        }
    }

    this.logingIn = (id, password) => {
        _this.setState({ showLoading: "Logging In ...", showLoginModal: false })
        setTimeout(() => {
            fetchLogin(
                _this.examId,
                id,
                password,
                _this.state.loginAs,
                (error) => {
                    console.error("error=", error);
                }).then((data) => {
                    this.loginHandler(data);
                }).finally(() => { _this.setState({ showLoading: "" }) });
        }, 1000)
    }

    this.joinExam = (inputId) => {
        if (inputId !== "" && _this.state.userProfile) {
            _this.setState({ showLoading: "Loading ..." })
            setTimeout(() => {
                fetchJoinExam(
                    inputId,
                    _this.state.userProfile.id,
                    _this.state.userProfile.password,
                    (error) => {
                        console.error("[error]", error);
                    }
                ).then((data) => {
                    if (!data || (data && data.status !== "success")) {
                        alert(data ? data.errorMessage : "Tidak menemukan Ujian");
                        return;
                    }
                    const expiresIn = 3600 * 12;
                    const expirationTime = new Date(
                        new Date().getTime() + expiresIn * 1000
                    );
                    let profile = data.result;
                    _this.context.login(
                        "idToken",
                        _this.state.userProfile.id,
                        profile,
                        "lang",
                        expirationTime
                    )
                    const { history } = _this.props;

                    let loginAs = profile.as;
                    if (loginAs === "participant") {
                        history.replace("/exam");
                    } else if (loginAs === "proctorer") {
                        history.replace("/proctor");
                    } else {

                    }
                }).finally(() => {
                    _this.setState({ showLoading: "" })
                })
            }, 1000)
        } else if (!_this.state.userProfile && _this.state.enableJoin === true) {
            _this.setState({ showLoginModal: true })
        }
    }

    this.directExam = (examId) => {
        fetchJoinExam(
            examId,
            _this.state.userProfile.id,
            _this.state.userProfile.password,
            (error) => {
                console.error("[error]", error);
            }
        ).then((data) => {
            if (!data || (data && data.status !== "success")) {
                alert(data ? data.errorMessage : "Tidak menemukan Ujian");
                return;
            }
            const expiresIn = 3600 * 12;
            const expirationTime = new Date(
                new Date().getTime() + expiresIn * 1000
            );
            let profile = data.result;
            _this.context.login(
                "idToken",
                _this.state.userProfile.id,
                profile,
                "lang",
                expirationTime
            )
            const { history } = _this.props;

            let loginAs = profile.as;
            if (loginAs === "participant") {
                history.replace("/exam");
            } else if (loginAs === "proctorer") {
                history.replace("/proctor");
            } else {

            }
        })
    }

    this.nextPrev = (type) => {
        let p = _this.state.chosenImage;
        if (type === "prev") {
            p--;
            if (p < 0) {
                p = _this.descList.length - 1;
            }
        } else {
            p++;
            if (p >= _this.descList.length) {
                p = 0;
            }
        }
        _this.setState({
            chosenImage: p
        })
    }
}