import { Err, Trace } from "../logger";

class ProktorWebsocket {
  constructor(socket, userid, username, isParticipant, after_connected_event) {
    this.socketIO = socket;
    this.isParticipant = isParticipant;
    this.after_connected = after_connected_event;
    this.socketIO.on("connect", () => {
      if (this.isParticipant) {
        this.sendUserId({
          userid: userid,
          name: username,
          socketid: socket.id,
        });
      } else {
        this.sendProctorId({
          proctorid: userid,
          name: username,
          socketid: socket.id,
        });
      }
      if (this.after_connected !== null) {
        this.after_connected();
      }
    });
    this.socketIO.on("connect_error", (err) => {
      Err("ERROR IS RECEIVED", err);
    });
    this.socketIO.on("error", (err) => {
      Err("Socket IO is error=", err);
    })
  }

  close() {
    this.socketIO.close();
  }
  sendUserId(data) {
    // Trace("SEND USER ID", data);
    this.socketIO.emit("user-id", data);
  }

  sendProctorId(data) {
    // Trace("SEND PROCTOR ID = ", data);
    this.socketIO.emit("proctor-id", data);
  }

  sendPing(data) {
    this.socketIO.timeout(5000).emit("ping", data);
  }

  sendPreOffer(data) {
    // Trace("SEND PRE OFFER, data = ", data);
    this.socketIO.emit("pre-offer", data);
  }

  sendPreOfferAnswer(data) {
    // Trace("SEND PRE OFFER ANSWER", data);
    this.socketIO.emit("pre-offer-answer", data);
  }

  sendDataUsingWebRTCSignaling(data) {
    // Trace("[send] sendDataUsingWebRTCSignaling", data);
    this.socketIO.emit("webRTC-signaling", data);
  }

}

export default ProktorWebsocket;