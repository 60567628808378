import React from "react";
import { fetchSaveValidationResult, urlPhotoID } from "../../../libs/proctor_utils";
import classes from "./__index__.module.scss";
import info from "../../../assets/imgs/info.png";
import ProgressStatus from "../../../components/ProgressStatus";
import ProgressBar from "../../../components/ProgressBar";
import position from "../../../assets/imgs/good_position.jpg";
import { inputSizes } from "../../../libs/ProktorAI";
import { Lang } from "../../../libs/Language";
import Tips from "../../../components/Tips";
import { __IdentityValidation__ } from "./IdentityValidation";
import { __VideoRotateValidation__ } from "./VideoRotateValidation";
import { ErrorAlert, SuccessAlert } from "../../../components/Alert";
import { Debug, Err } from "../../../libs/logger";
import { checkImage } from "../../../libs/utils";

let console = {};
console.log = function () { };
console.error = function () { };

export const __DynamicValidation__ = function (_this) {
    _this.inputDisplayVideoRef = React.createRef();
    _this.inputVideoRef = React.createRef();

    this.enableDebug = false
    // this.enableDebug = true

    // this.TIMEOUT = TELKOM_CASE ? 60000 : 75000 * 1;
    this.TIMEOUT = 75000 * 1;
    this.ACCEL = 0;
    this.PREPARATION_TIME = 15000;
    // this.PREPARATION_TIME = 5000;
    this.boxWidth = 150;
    this.videoWidth = 320;
    this.videoHeight = 240;
    this.boxRef = new React.createRef();
    this.isDone = false;

    this.pageValidation = "face"; // face,identityCard, video

    this.inputSizeReferenceId = 5;
    this.inputSizes = [...inputSizes];

    // threshold for the accepted face to be processed
    // this.SCORE_FACE = 0.75;

    // ambil tengah2 saja
    this.SCORE_FACE = 0.5;

    // 0.3 menjadikan wajah miring juga di anggap wajah
    // this.SCORE_FACE = 0.3;

    this.isProcessing = false;

    this.resultCandidate = [];
    this.lastPrintTs = 0;
    this.startTs = -1;
    this.average = 1; // error rate = 1 by default

    this.faceImgInCam = false; // error format/size image validation

    this.faceFromKTP = null;

    // below is the value of error rate
    this.threshold = {
        green: 0.40, // 60% similarity
        yellow: 0.50, // 50% similarity
        orange: 0.60, // 40% similarity
        pink: 0.70,  //30% similarity
        red: 1, // 0% similarity
        always_valid: false
    }

    this.identity_validation = {
        isValid: false,
        face_img_validation: null,
        identity_img_validation: null,
        video_start_validation: 0,
        video_end_validation: 0
    }

    this.startVideoRecording = false;

    this._identityValidation = new __IdentityValidation__(_this, this);
    this._videoRotateValidation = new __VideoRotateValidation__(_this, this);

    this.dialogs = {
        'preparation': [
            Lang(`Halo, Saya Mas Robot, Akan membantu anda untuk melakukan validasi wajah. Mohon bersiap, saya akan mulai mendeteksi wajah anda.`, _this.state.lang, {
                en: `Hello, I'm Mr Robot, I will help you to validate your face. Please get ready, I will start detecting your face.`
            })
        ],
        'notyetcomplete': [
            Lang('Saya masih mengambil beberapa sampel wajah anda, tunggu ya...', _this.state.lang, {
                en: `I'm still taking some samples of your face, wait...`
            }),
            Lang('Mohon tetap di depan kamera, saya masih memproses...', _this.state.lang, {
                en: `Please stay in front of the camera, I'm still processing...`
            }),
            Lang('Masih belum selesai ya, mohon bersabar sedikit lagi...', _this.state.lang, {
                en: `It's still not finished yet, please be patient a little longer...`
            })
        ],
        'almostcomplete': [
            Lang('Sebentar lagi sepertinya selesai nih ...', _this.state.lang, { en: `It seems like it'll be done soon...` }),
            Lang('Bagus, sedikit lagi ya ...', _this.state.lang, { en: `Very Good, Almost done ...` }),
            Lang('Almost Complete, please be patient ...', _this.state.lang, { en: `Almost Complete, please be patient ...` })
        ],
        'slow': [
            Lang('Saya mendeteksi perangkatmu cukup lambat, apabila ada aplikasi lain yang berjalan, tolong ditutup terlebih dahulu.', _this.state.lang, {
                en: `I detect that your device is quite slow, if there are other applications running, please close them first.`
            }),
            Lang('Proses berjalan agak lambat, tolong bersabar, sedang di proses ...', _this.state.lang, {
                en: `The process is running a bit slowly, please be patient, it's currently in progress...`
            }),
            Lang('Agak lambat euy, sabar deui ...', _this.state.lang, {
                en: `It's a bit slow, be patient again...`
            })
        ],
        'match': [
            Lang('Cukup mirip, tolong tunggu sebentar lagi, masih memproses.', _this.state.lang, {
                en: `Quite similar, please wait a moment longer, still processing.`
            }),
            Lang('Siip, anda cukup mirip dengan foto yang anda upload, tolong tunggu sebentar lagi ya...', _this.state.lang, {
                en: `Great, you're quite similar to the photo you uploaded, please wait a moment longer, okay?`
            }),
            Lang('Bagus, tingkat kemiripan cukup tinggi.', _this.state.lang, {
                en: `Good, the similarity level is quite high.`
            }),
            Lang('Mantap, cukup mirip lho ...', _this.state.lang, {
                en: `Awesome, quite similar indeed...`
            })
        ],
        'notmatch': [
            Lang('Anda masih tidak terlalu mirip dengan foto anda, mungkin cahaya di ruangan kurang', _this.state.lang, {
                en: `Still not quite similar to your photo, perhaps the lighting in the room is insufficient.`
            }),
            Lang('Masih tidak mirip, coba sesuaikan wajah anda seperti yang di foto.', _this.state.lang, {
                en: `Not quite there yet, try adjusting your face to match the one in the photo.`
            }),
            Lang('Apakah anda pakai kaca - mata ?, coba di lepas terlebih dahulu', _this.state.lang, {
                en: `Do you wear glasses? Try taking them off first.`
            }),
        ],
        'facenotdetected': [
            Lang('Saya belum bisa mendeteksi wajah anda, apakah anda sudah di depan kamera ?.', _this.state.lang, {
                en: `I haven't been able to detect your face yet, are you already in front of the camera?`
            }),
            Lang('Halo, apakah anda ada di sana ?,', _this.state.lang, { en: `Hello, are you there ?` }),
            Lang('Jangan membelakangi sumber cahaya ya, karena saya bisa silau dan wajah anda jadi tidak kelihatan.', _this.state.lang, {
                en: `Don't face away from the light source, as it might cause glare and make your face less visible.`
            }),
            Lang('Belum ada wajah terdeteksi, mohon posisikan wajah anda.', _this.state.lang, {
                en: `No face detected yet, please position your face.`
            }),
            Lang('Belum ada wajah terdeteksi, mohon kondisikan lingkungan anda.', _this.state.lang, {
                en: `No faces detected yet, please adjust your surroundings.`
            }),
            Lang('Jangan tutupi wajah anda dengan tangan atau masker.', _this.state.lang, {
                en: `Don't cover your face with your hand or mask.`
            }),
            Lang('Apakah anda pakai kaca mata ?, mungkin ada pantulan cahaya di kaca mata anda.', _this.state.lang, {
                en: `Do you wear glasses? There might be a reflection of light on your glasses.`
            })
        ],
        'done': [
            Lang('Baik, sesaat lagi proses akan selesai.', _this.state.lang, {
                en: `Alright, the process will be finished shortly.`
            }),
            Lang('Terima Kasih, sebentar lagi proses akan selesai.', _this.state.lang, { en: `Thank you, the process will be completed shortly.` }),
            Lang('Ok, mau selesai nih...', _this.state.lang, { en: `Okay, it's almost done...` })
        ]
    }

    this.printDialog = (type = 'facenotdetected') => {
        const random = () => {
            return Math.floor(Math.random() * this.dialogs[type].length);
        }
        if (Date.now() - this.lastPrintTs > 5000 || type === 'done') {
            this.lastPrintTs = Date.now();
            let id = random();
            if (this.prevId === id) {
                id = (id + 1) % this.dialogs[type].length;
            }
            if (document.getElementById('robot_comment') !== null) {
                document.getElementById('robot_comment').innerHTML = `${this.dialogs[type][id]}`;
            }
        }
    }

    this.addResultCandidate = (distance, imgsrc, score_face = 0) => {
        let accelerate = false;
        this.resultCandidate.push({
            distance: distance,
            imgsrc: imgsrc,
            score_face: score_face
        })

        this.resultCandidate.sort((a, b) => (a.distance >= b.distance) ? 1 : -1);

        if (this.resultCandidate.length > 10) {
            // Hapus objek terakhir
            this.resultCandidate.splice(10);
        }

        let avg = 0;
        for (let i = 0; i < this.resultCandidate.length; i++) {
            let el = document.getElementById(`sample_img_${i}`)
            if (el) { el.src = this.resultCandidate[i].imgsrc; }
            el = document.getElementById(`sample_span_${i}`)
            if (el) { el.innerHTML = `dist=${this.resultCandidate[i].distance.toFixed(2)},score_face=${this.resultCandidate[i].score_face.toFixed(2)}`; }
            avg += this.resultCandidate[i].distance;
        }
        avg = avg / this.resultCandidate.length;
        let el = document.getElementById(`sample_span_avg`)
        if (el) { el.innerHTML = `average: ${avg}`; }
        this.average = avg;

        if (this.resultCandidate.length < 10) {
            this.printDialog('notyetcomplete');
        } else if (avg > 0.6) {
            this.printDialog('notmatch');
        } else if (avg >= 0.5) {
            this.printDialog('notmatch');
        } else if (avg >= 0.4) {
            this.printDialog('match');
            accelerate = true;
        } else {
            // this is also matched avg < 0.4
            this.printDialog('almostcomplete');
            accelerate = true;
        }
        return accelerate;
    }

    this.processFaceFromKTP = (face = null, score_face = 0) => {
        if (this.faceFromKTP !== null) {
            Debug("face from ktp is already assigned, return");
            this.isProcessing = false
            return;
        }

        // get photo from ID
        // we will use several input Size, in case the used one is failed
        Debug(_this.context.profile);
        _this.pai.extractFaceFromKTP(
            urlPhotoID(_this.context.profile.id, _this.context.profile.folder_rand),
            this.inputSizes[this.inputSizeReferenceId],
            _this.context.profile.photo_actual === ""
        ).then((imgktp) => {
            // Debug("IMGKTP = ", imgktp)
            if (imgktp === null || imgktp === false) {
                Debug("extract face from ktp is failed. id=", this.inputSizes[this.inputSizeReferenceId]);
                // image from ktp is failed, give the 0% similarity, try again
                // by changing input Size
                if (_this.context.profile.photo_actual !== "") {
                    this.inputSizeReferenceId = (this.inputSizeReferenceId + 1) % this.inputSizes.length;
                }
                if (face !== null) {
                    if (_this.context.profile.photo_actual === "") {
                        this.addResultCandidate(1 - score_face, face.src, score_face);
                    } else {
                        this.addResultCandidate(1, face.src, score_face);
                    }
                }
                this.isProcessing = false;
            } else {
                Debug("extract face is ok with", this.inputSizeReferenceId, this.inputSizes[this.inputSizeReferenceId]);
                // this.inputSizeReferenceId = (this.inputSizeReferenceId + 1) % this.inputSizes.length;
                this.faceFromKTP = imgktp;

                // send face in ktp to server
                // very important to set canvas width and height first
                const cv = document.createElement("canvas");
                if (cv === undefined) {
                    Err("failed to create canvas for ktp");
                    return null;
                }
                cv.width = imgktp.width; /////// THIS IS ERROR
                cv.height = imgktp.height;
                cv.getContext("2d").drawImage(imgktp, 0, 0, cv.width, cv.height)

                _this.warningWs.sendFaceInKTPImage(_this.context.profile.id, cv.toDataURL('image/jpeg'));

                cv.remove();

                // if (doFaceValidation_cb !== null) {
                //     doFaceValidation_cb(imgktp)
                // }
                this.isProcessing = false;
            }
        });
    }

    this.processFace = (data) => {
        const doFaceValidation = (imgktp, score_face = 0) => {
            if (document.getElementById("onKTPCanvas") !== null) {
                document.getElementById("onKTPCanvas").src = imgktp.src;
            }
            document.getElementById("onCaptureCanvas").src = face.src;

            // compare the face to get distance
            _this.pai.faceValidation2(
                document.getElementById("onCaptureCanvas"),
                document.getElementById("onKTPCanvas")
            ).then((dist) => {
                // add push 
                const accelerate = this.addResultCandidate(dist, face.src, score_face);
                this.isProcessing = false;

                if (accelerate) {
                    this.ACCEL += 3000;
                }
            })
        }

        if (data === null) {
            this.isProcessing = false;
            return;
        }
        const face = data.img
        const score = data.score
        if (face === null || score < this.SCORE_FACE) {
            this.isProcessing = false;

            if (this.resultCandidate.length < 5 && this.isDone === false) {
                this.printDialog();
            }
            Debug("debug return < score face", score, this.SCORE_FACE);

            return;
        } else {
            Debug("inputSize=", inputSizes[this.inputSizeReferenceId], ", faceisnull=", face === null, ", score higher, process now ", score, " < ", this.SCORE_FACE);
        }

        if (document.getElementById("face") === null) {
            Debug("debug return");
            return;
        }
        document.getElementById("face").src = face.src;

        if (this.faceFromKTP === null) {
            this.processFaceFromKTP(face, score)
        } else {
            // skip load ktp, use the latest loaded image ktp
            doFaceValidation(this.faceFromKTP, score)
        }
    }

    this.extractFace = () => {
        if (this.isProcessing === true) {
            Debug("still processing previous process");
            return;
        }
        const el = _this.onValidationWebCamRef.current
        this.isProcessing = true;
        _this.setState({
            videoResolution: {
                width: el.videoWidth,
                height: el.videoHeight
            }
        })
        // get image from video to canvas img, in rectangle 320x240
        const img = _this.pai.captureVideoElementToImage320x240(
            el
        );

        const sample = document.getElementById("box")
        if (!sample) {
            this.isProcessing = false;
            Debug("there is no sample, SET PROCESSING FALSE");;
            return;
        }

        sample.src = img.src;

        // detect face inside box
        _this.pai.extractFaceFromImg(img).then((data) => {
            this.processFace(data);
        });
    }

    this.getThresholdPolicy = () => {
        if (_this.state.examProfile.valid_by_default) {
            return 1;
        }
        const tp = _this.state.examProfile.validation_policy;
        switch (tp) {
            case "green":
                return this.threshold.green;
            case "yellow":
                return this.threshold.yellow;
            case "orange":
                return this.threshold.orange;
            case "pink":
                return this.threshold.pink;
            case "red":
                return this.threshold.red;
            default:
                return this.threshold.green;
        }
    }

    this.robotStuff = () => {
        if (this.isDone === true) {
            return;
        }
        // detect the video width and height
        if (_this.onValidationWebCamRef?.current) {
            this.videoHeight = _this.onValidationWebCamRef.current.videoHeight;
            this.videoWidth = _this.onValidationWebCamRef.current.videoWidth;
        } else {
            this.startTs = -1;
            this.average = 1;
            this.resultCandidate = [];
            this.isProcessing = false;
            this.ACCEL = 0;
            return;
        }

        const el = document.getElementById(`sample_time`);
        if (el) {
            el.innerHTML = `time: ${(Date.now() - this.startTs) / 1000}`;
        }

        // if the time is > 60 and sample image is 10, then store validation value
        // Debug("start ts = ", this.startTs, ", average=", this.average);
        if (this.startTs === -1) {
            this.startTs = Date.now();
        }

        if ((Date.now() - this.startTs) < this.PREPARATION_TIME) {
            this.printDialog("preparation");
            if (this.isProcessing === false) {
                Debug("on preparation, process face from ktp, isprocessing=", this.isProcessing);
                this.processFaceFromKTP();
            } else {
                Debug("is processing is still true, wait")
            }
            return;
        }

        this.extractFace();
        Debug("ACCEL = ", this.ACCEL);
        if (this.startTs > 0 && Date.now() - this.startTs > (this.TIMEOUT - this.ACCEL)) {

            //Disimpan di akhir
            this.processValidationPolicy()
        }
    }

    this.saveBestImage = (cb) => {
        // Best image is the image with highest score_face, 
        // but the distance between best similarity is < 0.08
        if (this.resultCandidate.length < 1) {
            console.log(`RESULT CANDIDATE IS < 1 = ${_this.state.examProfile.valid_by_default}`);
            if (_this.state.examProfile.valid_by_default) {
                cb();
            } else {
                this.recoverVideoRoutine();
            }
            return null;
        }
        let bestCandidate = { score_face: 0 };
        for (const element of this.resultCandidate) {
            const r = element;
            if (bestCandidate.score_face === 0 || (r.score_face > bestCandidate.score_face &&
                r.distance - this.resultCandidate[0].distance < 0.08
            )) {
                bestCandidate = r;
            }
        }
        Debug("Found BestCandidate = ", bestCandidate, "of", this.resultCandidate[0]);
        // const bestimg = this.resultCandidate[0].imgsrc
        const bestimg = bestCandidate.imgsrc;

        const cv = document.createElement("canvas");
        if (cv === undefined) {
            Err("failed to create canvas");
            return null;
        }
        let img = new Image();
        img.src = bestimg;

        // very important to set canvas width and height first
        cv.width = img.width;
        cv.height = img.height;

        cv.getContext("2d").drawImage(img, 0, 0, cv.width, cv.height)

        let faceImage = cv.toDataURL('image/jpeg');

        checkImage(faceImage).then((response) => {
            console.log("Result : ", response)
            console.log("Result response.status : ", response.status)

            if (response.status) {
                this.identity_validation.face_img_validation = faceImage;
                _this._fileapi_.faceIncamValidation(faceImage, () => {
                    this.faceImgInCam = true
                    cb()
                })
                cv.remove();
                _this.pai.setFaceReference(img);
                img = null;
            } else {
                cv.remove();
                _this.pai.setFaceReference(img);
                img = null;
                console.error(`Error : ${response.message}`)

                return ErrorAlert(Lang(`Mohon Maaf, Proses Validasi Gagal, anda dapat mencoba kembali atau klik tombol Bantuan Pengawas.Error : ${response.message}`, _this.state.lang, {
                    en: `Apologies, the validation process failed. You can try again or click the Supervisor Assistance button.Error : ${response.message}`
                })).then(() => {
                    this.recoverVideoRoutine();
                });
            }

            // cv.remove();
            // _this.pai.setFaceReference(img);
            // img = null;
        })



    }

    this.processValidationPolicy = () => {
        // let { isValid } = this.identity_validation;

        // if (_this.state.examProfile.valid_by_default) {
        //     // it's valid by default, keep going even though there is no face detected
        //     // isValid = true;
        //     alert(Lang("Proses Validasi Wajah Berhasil, Selanjutnya, akan dilakukan validasi dengan kartu identitas (KTM/KTP).", _this.state.lang, { en: `Face Validation is Success, Next step, will be validated with ID card such as KTM/KTP` }))
        //     this.identity_validation.isValid = true;
        //     setTimeout(() => {
        //         this.pageValidation = "identityCard";
        //         _this._validation.attachMediaToOnValidationWebCamRef();
        //     }, 1000);
        // }

        // Dengan persentase validasi wajah
        if (
            _this.state.examProfile.valid_by_default ||
            (this.resultCandidate.length > 5 && this.average <= this.getThresholdPolicy())) {
            // isValid = true;
            this.identity_validation.isValid = true;

            _this.setState({
                onValidationDone: "done",
                validationStateMessage: ""
            });
            this.isDone = true;

            setTimeout(() => this.printDialog("done"), 500);

            // send the best image to server
            // this.saveBestImage()

            if (_this.state.examProfile.useValidationId360) {
                console.log("RESULT 1");
                this.saveBestImage(
                    SuccessAlert(Lang("Proses Validasi Wajah Berhasil", _this.state.lang, { en: `Face Validation is Success` })).then(() => {
                        setTimeout(() => {
                            this.faceImgInCam = false
                            this.pageValidation = "identityCard";
                            _this._validation.attachMediaToOnValidationWebCamRef();
                        }, 1500);
                    })
                );
            } else {
                console.log("RESULT 2");
                // go on only with face validation
                this.onDoneValidation();

            }

        } else {

            Debug("send validation result");
            this.sendSaveValidationResult(async (data) => {
                this.saveBestImage()
                await ErrorAlert(Lang("Mohon Maaf, Proses Validasi Gagal, anda dapat mencoba kembali atau klik tombol Bantuan Pengawas.", _this.state.lang, {
                    en: `Apologies, the validation process failed. You can try again or click the Supervisor Assistance button.`
                }));

                this.recoverVideoRoutine();
            })

        }
        // console.log("faceImgInCam : ", this.faceImgInCam)
        // this.faceImgInCam = false;
    }

    this.calcProgress = () => {
        const time = Date.now() - this.startTs - this.PREPARATION_TIME
        const x = Math.round(time / (this.TIMEOUT - this.PREPARATION_TIME - this.ACCEL) * 100)
        if (x < 0) {
            return 0;
        }
        if (x > 100) {
            return 100;
        }
        return x;
    }

    this.renderBox = () => {
        let topheight = 40;
        let leftheight = 160;
        if (this.boxRef.current !== null) {
            topheight = Math.floor(this.boxRef.current.clientWidth / 8);
            leftheight = Math.floor(this.boxRef.current.clientWidth / 2);
        }
        return <div
            ref={this.boxRef}
            className={classes.box}>{
                this.boxRef.current === null ? <></> : <>
                    <div
                        className={classes.box_top}
                        style={{ height: `${topheight}px` }}
                    ></div>
                    <div className={classes.box_left}
                        style={{
                            top: `${topheight}px`,
                            height: `${leftheight}px`
                        }}
                    ></div>
                    <div className={classes.box_right}
                        style={{
                            top: `${topheight}px`,
                            height: `${leftheight}px`
                        }}
                    ></div>
                    <div
                        className={classes.box_bottom}
                        style={{ height: `${topheight}px` }}
                    ></div>
                </>
            }
        </div>
    }

    this.renderDisplayEl = () => {
        return <div style={{
            visibility: "hidden"
        }}>
            <video
                ref={_this.inputVideoRef}
                autoPlay
                muted
                height="50px"
                id="inputVideo"
            />
            <video
                ref={_this.inputDisplayVideoRef}
                autoPlay
                muted
                height="50px"
                id="inputDisplayVideo"
            />
        </div>
    }

    this.faceValidationProgress = () => {
        const getVisibility = () => {
            if (this.startTs !== -1) {
                if (Date.now() - this.startTs < 1000) {
                    return "hidden"
                } else {
                    return "inherit"
                }
            } else {
                return "hidden"
            }
        }
        const imgN = 10;
        let imgs = [];
        for (let i = 0; i < imgN; i++) {
            imgs.push(
                <div key={i} style={{ border: "1px solid black", display: "flex", flexDirection: "column" }}>
                    <img alt="sample" key={i} id={`sample_img_${i}`} />
                    <span id={`sample_span_${i}`}></span>
                </div>
            )
        }

        return <div className={classes.container}>
            {this.renderDisplayEl()}
            <Tips />
            <h2>{Lang(`Selamat Datang`, _this.state.lang, { en: `Hi` })}, {_this.context.profile.name}</h2>
            <div className={classes.container_profile}>
                {
                    _this.context.profile.photo
                    &&
                    <img className={classes.identity} alt="photoid" height={"200px"} src={urlPhotoID(_this.context.profile.id, _this.context.profile.folder_rand)} />
                }
                <div className={classes.container_profile_info}>
                    <img alt="info" src={info} />
                    <ol type="*">
                        <li>
                            {Lang(`Tegakkan badan anda, pastikan cahaya di ruangan anda cukup terang`, _this.state.lang, { en: `Stand straight, make sure the lighting in your room is sufficiently bright.` })}
                        </li>
                        <li>
                            {Lang(`Buka masker anda, apabila anda memakai masker.`, _this.state.lang, { en: `Remove your mask if you're wearing one.` })}
                        </li>
                        <li>
                            {Lang(`Apabila memakai kacamata, usahakan untuk tidak ada pantulan cahaya di kaca mata, atau buka kaca mata anda.`, _this.state.lang, { en: `If you're wearing glasses, try to avoid any glare on them, or remove your glasses.` })}
                        </li>
                        <li>
                            {Lang(`Posisikan wajah anda di kotak yang tersedia di layar.`, _this.state.lang, { en: `Position your face within the box provided on the screen.` })}
                        </li>
                        <li>
                            {Lang(`Mas Robot akan mencoba untuk mengambil sampel wajah anda.`, _this.state.lang, { en: `Mr. Robot will now attempt to capture a sample of your face.` })}
                        </li>
                        <li>
                            {Lang(`Ikuti instruksi yang diberikan oleh Mas Robot.`, _this.state.lang, { en: `Follow the instructions provided by Mr. Robot.` })}
                        </li>
                    </ol>

                    <div className={classes.example}>
                        <img alt="pos" src={position} />
                        <span>{Lang(`Contoh posisi wajah yang diharapkan Mas Robot`, _this.state.lang, { en: `Example of the expected face position as desired by Mr. Robot.` })}</span>
                    </div>

                </div>
            </div>
            <div className={classes.video_message}>
                <div className={classes.container_bordervideo}>
                    <div className={[classes.container_video, classes.FadeInPage1s].join(" ")}
                        style={{
                            visibility: getVisibility()
                        }}
                    >
                        <video
                            ref={_this.onValidationWebCamRef}
                            width="320px"
                            height="240px"
                            autoPlay
                            muted
                            playsInline
                            style={{
                                transform: "scaleX(-1)"
                            }}
                        ></video>
                        {this.renderBox()}
                    </div>
                </div>
                <div className={classes.messages}>
                    <div className={classes.container_robot}>
                        <div>
                            <ProgressStatus type="ai" state={this.calcProgress() === 0 ? "" : "processing"} />
                        </div>
                        <span id={`robot_comment`}></span>
                    </div>
                    <div className={classes.container_progress}>
                        <ProgressBar
                            completed={this.calcProgress() === 0 ? -1 : this.calcProgress()} />
                    </div>
                </div>
            </div>
            <div
                className={classes.container_debug}
                style={{
                    position: this.enableDebug ? "inherit" : "absolute",
                    visibility: this.enableDebug ? "inherit" : "hidden"
                }}
            >
                <div>
                    <img alt="box" id="box" />
                    <img alt="face" id="face" />
                    <img
                        alt="capture"
                        id="onKTPCanvas"
                        height="100px"
                    />
                    <img
                        alt="validation"
                        id="onValidationCanvas"
                        height="100px"
                        style={{ display: "none" }}
                    ></img>
                    <img
                        alt="capture"
                        id="onCaptureCanvas"
                        height="100px"
                    />
                </div>
                <div className={classes.container_debug_cap}>
                    {/* <img id="testimg" /> */}
                    {imgs}
                </div>
                <div className={classes.container_debug_info}>
                    <span id={`sample_time`}></span>
                    <span id={`sample_span_avg`}></span>
                </div>
            </div>
        </div>
    }

    this.recoverVideoRoutine = () => {
        _this.setState({
            validationInProgress: false,
            onValidationDone: "",
            validationStateMessage: ""
        })
        _this._preparation.recoverVideoForPreparation(_this)
        this.isDone = false;
    }

    this.sendSaveValidationResult = (cb) => {
        fetchSaveValidationResult(
            _this.context.profile.id,
            {
                green: this.threshold.green,
                yellow: this.threshold.yellow,
                orange: this.threshold.orange,
                pink: this.threshold.pink,
                red: this.threshold.red,
                value: _this.context.profile.photo_actual === "" ? 1 : this.average,
                valid: this.identity_validation.isValid,
                always_valid: this.threshold.always_valid
            },
            async (err) => {
                Err(err);
                await ErrorAlert("[err-261] database error, please try again.");
                this.recoverVideoRoutine();
            }
        ).then(cb)
    }

    this.onDoneValidation = () => {

        this.saveBestImage(() => {
            this.sendSaveValidationResult(async (data) => {
                Debug(data);
                if (data.status === 'success') {
                    console.log("Result onDoneValidation 1");
                    setTimeout(async () => {
                        this.startTs = -1;
                        if (this.identity_validation.isValid) {

                            // Send best face image
                            _this._fileapi_.faceIncamValidation(this.identity_validation.face_img_validation)

                            // Send identity image
                            _this.warningWs.sendIdentityInCamImage(_this.context.profile.id, this.identity_validation.identity_img_validation)

                            // Send time video recording
                            _this._validation.onValidationVideo();

                            // Send validation
                            _this._validation.onValidationValid();

                            // Set default page validation
                            this.pageValidation = "face";

                            // Set default identity image validation
                            this.identity_validation.identity_img_validation = null;

                            // Set default video validation
                            this.identity_validation.video_start_validation = 0;
                            this.identity_validation.video_end_validation = 0;


                        } else {
                            await ErrorAlert(Lang("Mohon Maaf, Proses Validasi Gagal, anda dapat mencoba kembali atau klik tombol Bantuan Pengawas.", _this.state.lang, {
                                en: `Apologies, the validation process failed. You can try again or click the Supervisor Assistance button.`
                            }));
                        }
                        this.recoverVideoRoutine();
                    },
                        1000
                    )
                } else {
                    console.log("Result onDoneValidation 2");

                    await ErrorAlert(`[err-292] ${data.errorMessage}`);
                    this.recoverVideoRoutine();
                }
            })
        })
    }

    this.onCaptureIdentity = () => {
        const canvas = document.createElement('canvas');
        canvas.width = _this.onValidationWebCamRef.current.videoWidth;
        canvas.height = _this.onValidationWebCamRef.current.videoHeight;
        canvas.getContext('2d').drawImage(_this.onValidationWebCamRef.current, 0, 0);
        const imageDataURL = canvas.toDataURL('image/png');
        this.identity_validation.identity_img_validation = imageDataURL;
        canvas.remove();
    };

    this.onNextValidation = () => {
        this.pageValidation = "video"
    }

    this.render = () => {
        if (this.pageValidation === "face") {
            return this.faceValidationProgress();
        } else if (this.pageValidation === "identityCard") {
            return this._identityValidation.identityCard()
        } else {
            return this._videoRotateValidation.videoRotate()
        }
    }

}