import { Lang } from "../../libs/Language";

export const __Content__ = function (_this, classes) {
    _this.getDescriptionList = (lang) => {
        return [
            {
                url: "https://amanin.id/logos/desc1.jpg",
                title: Lang("Fleksibel, Tanpa Infrastruktur, Ujian Dimana Saja", lang, {
                    en: `Flexible, Infrastructure-Free, Tests Anywhere`,
                    ch: `多才多艺，免基础设施，随地测试`,
                    sp: `Versátil, Sin Necesidad de Infraestructura, Exámenes en Cualquier Lugar`,
                    jp: `多機能、インフラ不要、どこでもテスト`,
                    ko: `다재다능하며, 인프라 무용, 어디서나 시험`
                }),
                content: Lang("Peserta dan pengawas dapat mengikuti ujian dari mana saja, kapan saja.", lang, {
                    en: `Participants and Proctorers can take the exam from anywhere, at any time.`,
                    ch: `参与者和监考员可以随时随地参加考试。`,
                    sp: `Los participantes y supervisores pueden tomar el examen desde cualquier lugar, en cualquier momento.`,
                    jp: `参加者と監督者はどこからでもいつでも試験を受けることができます。`,
                    ko: `참가자와 감독은 언제 어디서든 시험을 볼 수 있습니다.`
                })
            },
            {
                url: "https://amanin.id/logos/desc2.jpg",
                title: "Aman, Ringan dan Mudah Digunakan",
                content: "Didesain untuk memberikan pengalaman penggunaan yang mudah, namun tetap mengutamakan keamanan."
            },
            {
                url: "https://amanin.id/logos/desc3.jpg",
                title: "Kemudahan Pengawasan dan Analisa Peserta Ujian",
                content: "Pengawas dapat dengan dengan mudah mengawasi peserta, melakukan analisa, dan memberi peringatan dengan mudah."
            },
            {
                url: "https://amanin.id/logos/desc7.jpg",
                title: "Mendukung Perangkat Apa Saja",
                content: "Didesain untuk dapat digunakan oleh siapa saja, dari mana saja dan dengan perangkat apa saja."
            },
            {
                url: "https://amanin.id/logos/desc5.jpg",
                title: "Pengawasan Ujian Terasa Privat, Peserta Ujian Merasa Nyaman",
                content: "Bagi pengawas, pengawasan terasa begitu dekat, disisi lain bagi peserta, ujian terasa nyaman."
            },
            {
                url: "https://amanin.id/logos/desc8.jpg",
                title: "Fitur - fitur dukungan yang membantu peserta",
                content: "Disertai fitur - fitur dukungan untuk membantu para peserta apabila menemukan kesulitan dalam proses ujian."
            },
            {
                url: "https://amanin.id/logos/desc9.jpg",
                title: "AI untuk kemudahan pengawasan",
                content: "Fitur AI menjadi salah satu fitur yang membantu pengawas dalam menentukan keputusan - keputusan di setiap ujian."
            }
        ];
    }
}