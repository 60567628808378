import { Debug } from "../../libs/logger";
import { handleMessage } from "../../libs/utils";

// ai is implemented here
export const __Warning__ = function (_this) {
    _this.warningData = {
        start: 0,
        last: 0,
        data: [0, 0, 0, 0, 0, 0]
    }
    _this.onExamFaceDetectionStatus = "done";
    // Menghitung total warning dalam range waktu yang ditentukan
    _this.totalWarning = 0;

    this.sendAIWarningSaveImage = (ev, id_error, info) => {
        //Kirim image ketika ada warning
        const used_ts = Date.now();
        Debug(used_ts, "sending img element to server because warning = ", info);
        Debug("EV = ", ev);
        Debug("WARNING - sending img element to server because warning = ", info);
        // const used_ts = _this.currentTs

        // disable this, because it makes bottlenect,
        // now it's using filews not commonws, try again

        // the delay between one image to another
        // const SAVE_IMAGE_DELAY = 15000
        const SAVE_IMAGE_DELAY = 30000
        if (Date.now() - _this.lastSendImageWarningTs < SAVE_IMAGE_DELAY) {
            Debug("ignore send image element to server, diff=", Date.now() - _this.lastSendImageWarningTs);
        } else {
            _this.lastSendImageWarningTs = Date.now();
            _this._action.sendImgElementToServer(ev.img_face, "face", used_ts, () => {
                // _this.lastSendImageWarningTs = Date.now();
            }, true)
            _this._action.sendImgElementToServer(ev.img_screen, "screen", used_ts, null, true);
        }

        _this.commonWs?.sendAIWarning(
            _this.context.profile.id,
            id_error,
            used_ts,
            _this.warningData);
        // if there are warning tab is open send here

        // send again aiwarning2file, to save warning to file
        Debug("[aiwarning2file] send aiwarning2file");
        _this.warningWs?.sendAIWarning2File(
            _this.context.profile.id,
            id_error,
            used_ts,
            _this.warningData
        )
    }

    this.examFaceDetectionHandler = (ev) => {
        _this.onExamFaceDetectionStatus = "done";
        const faceNumberWarning = this._faceNumberWarning(ev);
        const faceIdWarning = this._faceIdWarning(ev, _this.state.examProfile.face_detection_on_exam_thresh);
        const openNewTabOrBrowserWarning = this._openNewTabOrBrowserWarning();
        // const printScreenWarning = this._printScreenWarning();

        // Handle Iframe warning with a callback
        this._warningFromIframe((_warningFromIframe) => {
            if (_warningFromIframe.event === 'warning') {
                info.push(_warningFromIframe.info);
                isWarning = true;
                id_error = _warningFromIframe.id_error;
                _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay;

                // Handle warning for copy/paste case
                this.sendAIWarningSaveImage(ev, id_error, info);
                // console.log(Date.now(), `SEND WARNING FOR ${_warningFromIframe.action}`, info);
                Debug(Date.now(), `SEND WARNING FOR ${_warningFromIframe.action}`, info);
            }
        });

        // document.addEventListener('keyup', (e) => {
        //     const printScreenWarning = this._printScreenWarning(e);

        //     if (printScreenWarning.event === 'warning') {
        //         info.push(printScreenWarning.info);
        //         isWarning = true
        //         id_error = printScreenWarning.id_error;
        //         _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay

        //         // handle warning for open new tab case
        //         this.sendAIWarningSaveImage(ev, id_error, info);
        //         console.log(Date.now(), "SEND WARNING FOR PRINTSCREEN/SCREENSHOT", info)
        //         Debug(Date.now(), "SEND WARNING FOR PRINTSCREEN/SCREENSHOT", info)
        //     }

        // });

        if (faceNumberWarning === null || faceIdWarning === null) {
            return;
        }
        let isWarning = false;
        let info = [];
        let id_error = -1;

        // untuk count jumlah warning
        if (_this.state.eventState.event !== "normal") {
            _this.totalWarning += 1;
        }

        if (faceNumberWarning.event === 'warning') {
            info.push(faceNumberWarning.info);
            isWarning = true;
            id_error = faceNumberWarning.id_error;
            _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay
        }
        if (faceIdWarning.event === 'warning') {
            info.push(faceIdWarning.info);
            isWarning = true
            id_error = faceIdWarning.id_error; // Tidak sama dengan identitas
            _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay
        }

        // handle warning for face cases
        if (isWarning) {
            Debug(Date.now(), "SEND WARNING FOR FACE", info)
            this.sendAIWarningSaveImage(ev, id_error, info);
        }


        if (openNewTabOrBrowserWarning.event === 'warning') {
            info.push(openNewTabOrBrowserWarning.info);
            isWarning = true
            id_error = openNewTabOrBrowserWarning.id_error;
            _this.warningData.data[id_error] += _this.state.examProfile.interprocess_ai_delay

            // handle warning for open new tab case
            this.sendAIWarningSaveImage(ev, id_error, info);
            Debug(Date.now(), "SEND WARNING FOR TAB", info)
        }

        if (_this.warningData.start === 0) {
            _this.warningData.start = Date.now();
        }
        _this.warningData.last = Date.now();

        // if (isWarning === false && isWarningFromIframe === false) {
        if (isWarning === false) {
            _this.setState({
                eventState: {
                    event: 'normal',
                    info: ['Usahakan wajah anda selalu tertangkap kamera.']
                }
            })
        } else {
            _this.setState({
                eventState: {
                    event: 'warning',
                    info: info
                }
            })
        }

        // setInterval(() => {
        //   Debug("DISPLAY FROM WARNING INTERVAL")
        //   const { display_warning_on_exam_thresh, interprocess_ai_delay } = _this.state.examProfile;
        //   // let displayWarning = "Warning";
        //   // let limit = (display_warning_on_exam_thresh / (interprocess_ai_delay * 1000)) / 2;
        //   let limit = ((1 * 30 * 1000) / (interprocess_ai_delay * 1000)) / 2;

        //   if (_this.totalWarning >= limit) {
        //     _this.totalWarning = 0
        //       Debug("DISPLAY WARNING");
        //     setTimeout(() => {
        //     Debug("DISPLAY NORMAL");
        //     }, 30000);
        //   }

        //   // }, _this.state.examProfile.display_warning_on_exam_thresh);
        // }, 1 * 30 * 1000);
    }

    // this.DISABLE_WARNING_ROUTINE = true
    this.DISABLE_WARNING_ROUTINE = false
    this.warningIdentificationRoutine = () => {
        if (this.DISABLE_WARNING_ROUTINE === true) {
            Debug("disable warning");
            return;
        }
        // Debug("AI ROUTINE 1/3");
        if (_this.pai !== null &&
            _this.state.userMediaStream !== null
            &&
            (
                (_this.state.displayMediaStream !== null && _this.mode !== "mobile") ||
                (_this.mode === "mobile")
            )
        ) {
            if (_this.onExamFaceDetectionStatus === "processing") {
                Debug("still processing, skip");
                return;
            } else {
                _this.onExamFaceDetectionStatus = "processing";
            }

            // get face image and screenshot if any, pass it to handler
            const img_face = _this.pai.captureVideoElementToImage(document.getElementById("inputVideo"));
            const img_screen = _this.pai.captureVideoElementToImage(document.getElementById("inputDisplayVideo"));
            // Debug("AI ROUTINE 2/3");

            if (img_face) {
                // Debug("AI ROUTINE 3/3, on exam face detection");

                _this.pai.onExamFaceDetection(img_face, img_screen).then((ev) => {
                    this.examFaceDetectionHandler(ev)
                })
            } else {
                // very important to set status done here.
                _this.onExamFaceDetectionStatus = "done";
            }
        } else {
            // Debug("PAI IS NULL");
        }
    }

    this._faceIdWarning = (ev, threshold) => {
        if (ev === undefined || ev.face === undefined) {
            return null;
        }

        // Debug(_this.state.examProfile);
        // Debug(ev.faceid.dist, threshold);
        if (ev.faceid.dist > threshold) {
            return {
                event: 'warning',
                info: 'Wajah tidak sesuai dengan kartu identitas.',
                id_error: 2
            }
        }
        return {
            event: 'normal'
        };
    }

    this._faceNumberWarning = (ev) => {
        if (ev === undefined || ev.face === undefined) {
            return null;
        }
        Debug(ev);
        if (ev.face.number === 0) {
            return {
                event: 'warning',
                info: "Pelanggaran terdeteksi, wajah anda tidak tertangkap kamera, " +
                    "usahakan wajah anda selalu tertangkap kamera.",
                id_error: 0
            }
        } else if (ev.face.number > 1) {
            return {
                event: 'warning',
                info: "Pelanggaran terdeteksi, ada lebih dari satu wajah tertangkap kamera. "
                    + "Diharapkan tiap peserta mengerjakan ujian dengan jujur.",
                id_error: 1
            }
        }
        return {
            event: 'normal'
        };
    }

    this._openNewTabOrBrowserWarning = () => {
        let warn = false;
        if (_this.state.examProfile.useExtension) {
            if (_this.quizActivity.tab === false || _this.quizActivity.windowFocused === false) {
                warn = true;
            }
        } else if (document.visibilityState === 'hidden') {
            warn = true;
        }

        if (warn) {
            return {
                event: 'warning',
                info: "Pelanggaran terdeteksi, ada tab atau browser baru dibuka.",
                id_error: 3
            }
        }

        return {
            event: 'normal'
        };
    }

    this._printScreenWarning = (event) => {
        let result = {
            event: 'normal'
        };

        // Detect Print Screen for Windows (PrintScreen or keyCode 44)
        const isWindowsPrintScreen = event?.key === "PrintScreen" || event?.keyCode === 44;

        // Detect macOS screenshot shortcuts (Command+Shift+3 or Command+Shift+4)
        const isMacScreenshotShortcut =
            (event?.metaKey && event?.shiftKey &&
                (event?.key === '3' || event?.key === '4' || event?.key === '5')) ||
            (event?.metaKey && event?.shiftKey && event?.ctrlKey &&
                (event?.key === '3' || event?.key === '4'));

        if (isWindowsPrintScreen || isMacScreenshotShortcut) {
            Debug("PRINTSCREEN WARNING");
            result = {
                event: 'warning',
                info: "Pelanggaran terdeteksi, peserta melakukan Print Screen atau Screenshot.",
                id_error: 4
            };
        }


        return result;
    };

    this._warningFromIframe = (callback) => {
        let result = {
            event: 'normal'
        };

        handleMessage(() => {
            result = {
                event: 'warning',
                info: "Pelanggaran terdeteksi, peserta melakukan Copy/Paste.",
                id_error: 5,
                action: "Copy/Paste"
            };
            // Trigger the provided callback with the updated result
            callback(result);
        },
            'CopyPaste-Warning',
            'Copy/Paste action detected.'
        );

        handleMessage(() => {
            result = {
                event: 'warning',
                info: "Pelanggaran terdeteksi, peserta melakukan Print Screen atau Screenshot.",
                id_error: 4,
                action: "PrintScreen/ScreenShot"
            };
            callback(result);
        },
            'PrintScreen-Warning',
            'PrintScreen/ScreenShot action detected.'
        );

        // Return the initial result immediately
        return result;
    };

}


