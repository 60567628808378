class MessageEvent {
  constructor(_this, nmk) {
    this.new_message_key = nmk;
    this.parent = _this;
  }

  setNewMessage(participantId, value) {
    let vm = JSON.parse(localStorage.getItem(this.new_message_key));
    if (vm === null) {
      vm = [];
    }
    if (value === true) {
      if (vm.indexOf(participantId) === -1) {
        vm.push(participantId);
      }
    } else if (value === false) {
      if (vm.indexOf(participantId) !== -1) {
        vm.splice(vm.indexOf(participantId), 1);
      }
    }
    localStorage.setItem(this.new_message_key, JSON.stringify(vm));

    this.parent.setState({ newMessageState: this.getNewMessage() })
  }

  getNewMessage() {
    let vm = JSON.parse(localStorage.getItem(this.new_message_key));
    return vm === null ? [] : vm;
  }

  setChatBoxIsOpen(participantId, value) {
    let vm = JSON.parse(localStorage.getItem(`chatbox_open_${this.new_message_key}`));
    if (vm === null) {
      vm = [];
    }
    if (value === true) {
      if (vm.indexOf(participantId) === -1) {
        vm.push(participantId);
      }
    } else if (value === false) {
      if (vm.indexOf(participantId) !== -1) {
        vm.splice(vm.indexOf(participantId), 1);
      }
    }
    localStorage.setItem(`chatbox_open_${this.new_message_key}`, JSON.stringify(vm));

    this.parent.setState({ chatBoxIsOpenState: this.getChatBoxIsOpen() })
  }

  getChatBoxIsOpen() {
    let vm = JSON.parse(localStorage.getItem(`chatbox_open_${this.new_message_key}`));
    return vm === null ? [] : vm;
  }

}

export default MessageEvent;