import { Debug, Err } from "../logger";
import { fetchJSONMessageCommon } from "../proctor_utils";
import { getAccessToken, urlLocal } from "../utils";
import { sendAIWarning, sendBroadcastToParticipant, sendBroadcastToParticipantPost, sendKeyStroke, sendLogout, sendPing, sendRefreshConnection, sendStoreParticipantImage, sendUpdateMiscInformation, sendUpdateVideoDistribution } from "./Misc";
import { registerClient, registerParticipant, registerParticipantMobilePairing, registerProctorer } from "./Registration";
import { getCurrentTS, sendUpdateTs, sendUpdateTsPost } from "./Ts";
import { sendUpdateValidationStatus, sendVideoValidationTime } from "./Validation";
import { sendStartWebRTC, sendStartWebRTCGeneral, sendStartWebRTCMobilePairing, sendStartWebRTCPrivate, sendWebRTCFailed, sendWebRTCGeneralFailed, sendWebRTCGeneralStarted, sendWebRTCMobilePairingFailed, sendWebRTCMobilePairingStarted, sendWebRTCPrivateFailed, sendWebRTCPrivateStarted, sendWebRTCStarted } from "./WebRTC";

class CommonWebsocket {
    constructor(_parent, folderRand, onMessageReceived = null, onClose = null) {
        this._parent = _parent;
        this.folderRand = folderRand
        this.socket = new WebSocket("wss://" + urlLocal + "/commonws");


        this.socket.addEventListener("open", (event) => {
            // Debug("[commonws] on open");
        })

        this.socket.addEventListener("message", (event) => {
            // Debug("[commonws] on message");
        })

        this.socket.onclose = () => {
            // Debug("[commonws] on close");
            if (onClose !== null) {
                onClose(this._parent, null);
            }
        }

        this.socket.onerror = (err) => {
            // Debug("[commonws] on error");
            if (onClose !== null) {
                onClose(this._parent, err);
            }
        }

        this.socket.onopen = () => {
            // Debug("[commonws] on open");
        }

        this.socket.onmessage = (data) => {
            if (typeof (data.data) === "string") {
                if (onMessageReceived !== null) {
                    onMessageReceived(this._parent, data.data);
                }
            } else {
                data.data.text().then((d) => {
                    if (onMessageReceived !== null) {
                        onMessageReceived(this._parent, d);
                    }
                })
            }
        }
    }

    ready() {
        return this.socket.readyState === 1;
    }
    close() {
        this.socket.close();
    }

    errorSocketIsNotReady() {
        // Debug("[commonws] socket is not ready");
    }

    // registration
    registerClient(type, id, listOfParticipants = null) {
        return registerClient(this, type, id, listOfParticipants);
    }
    registerParticipant(id) {
        return registerParticipant(this, id);
    }
    registerParticipantMobilePairing(id) {
        return registerParticipantMobilePairing(this, id);
    }
    registerProctorer(id, listOfParticipants) {
        return registerProctorer(this, id, listOfParticipants)
    }

    // ts
    getCurrentTS(id) {
        getCurrentTS(this, id)
    }

    sendUpdateTsPost(id, ipAddress, type = "participant", cb = null) {
        sendUpdateTsPost(this, id, ipAddress, type, cb)
    }

    sendUpdateTs(id, ipAddress, type = "participant") {
        sendUpdateTs(this, id, ipAddress, type);
    }

    // validation
    sendUpdateValidationStatus(proctorerId, participantId, status, info = { camera: "on", screen: "on" }) {
        sendUpdateValidationStatus(this, proctorerId, participantId, status, info)
    }
    sendVideoValidationTime(proctorerId, participantId, startTs, endTs) {
        sendVideoValidationTime(this, proctorerId, participantId, startTs, endTs)
    }

    // misc
    sendStoreParticipantImage(participantId, proctorId = null) {
        sendStoreParticipantImage(this, participantId, proctorId);
    }

    sendRefreshConnection(participantId) {
        sendRefreshConnection(this, participantId);
    }

    sendBroadcastToParticipantPost(from, to, msg, participantId, cb) {
        sendBroadcastToParticipantPost(this, from, to, msg, participantId, cb)
    }

    sendBroadcastToParticipant(from, to, msg, participantId = null) {
        sendBroadcastToParticipant(this, from, to, msg, participantId)
    }

    sendUpdateVideoDistribution(participantId, proctorerId, ipaddress, stats) {
        sendUpdateVideoDistribution(this, participantId, proctorerId, ipaddress, stats)
    }

    sendUpdateMiscInformation(participantId, info) {
        sendUpdateMiscInformation(this, participantId, info);
    }

    sendLogout(participantId) {
        sendLogout(this, participantId)
    }


    sendAIWarning(from, warning_type, ts, warningdata = null) {
        sendAIWarning(this, from, warning_type, ts, warningdata)
    }

    sendKeyStroke(from, key) {
        sendKeyStroke(this, from, key)
    }

    sendPing(from) {
        sendPing(this, from);
    }

    // webrtc
    sendStartWebRTC(proctorerId, participantId, proctorId, videoRouter = null) {
        sendStartWebRTC(this, proctorerId, participantId, proctorId, videoRouter)
    }
    sendWebRTCStarted(proctorerId, participantId, proctorId) {
        sendWebRTCStarted(this, proctorerId, participantId, proctorId);
    }
    sendWebRTCFailed(mediaStatus, proctorerId, participantId, proctorId) {
        sendWebRTCFailed(this, mediaStatus, proctorerId, participantId, proctorId);
    }

    // general
    sendStartWebRTCGeneral(calltype, proctorerId, participantId, proctorId, videoRouter = null) {
        sendStartWebRTCGeneral(this, calltype, proctorerId, participantId, proctorId, videoRouter);
    }
    sendWebRTCGeneralStarted(calltype, proctorerId, participantId, proctorId, videoRouter) {
        sendWebRTCGeneralStarted(this, calltype, proctorerId, participantId, proctorId, videoRouter)
    }
    sendWebRTCGeneralFailed(calltype, mediaStatus, proctorerId, participantId, proctorId) {
        sendWebRTCGeneralFailed(this, calltype, mediaStatus, proctorerId, participantId, proctorId)
    }

    // private
    sendStartWebRTCPrivate(proctorerId, participantId, proctorId, videoRouter = null) {
        sendStartWebRTCPrivate(this, proctorerId, participantId, proctorId, videoRouter)
    }
    sendWebRTCPrivateStarted(proctorerId, participantId, proctorId, videoRouter) {
        sendWebRTCPrivateStarted(this, proctorerId, participantId, proctorId, videoRouter)
    }
    sendWebRTCPrivateFailed(mediaStatus, proctorerId, participantId, proctorId) {
        sendWebRTCPrivateFailed(this, mediaStatus, proctorerId, participantId, proctorId)
    }
}


export default CommonWebsocket;