import { getAccessToken, getExamId } from "../utils"

// webrtc live video
export function sendStartWebRTC(_this, proctorerId, participantId, proctorId, videoRouter = null) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(JSON.stringify({
            type: 'start-webrtc',
            proctorId: proctorId,
            participantId: participantId,
            proctorerId: proctorerId,
            videoRouter: videoRouter,
            token: getAccessToken(),
            examid: getExamId()
        })
        )
    }
}

export function sendWebRTCStarted(_this, proctorerId, participantId, proctorId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-started',
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendWebRTCFailed(_this, mediaStatus, proctorerId, participantId, proctorId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-failed',
                mediaStatus: mediaStatus,
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

// webrtc general
export function sendWebRTCGeneralFailed(_this, calltype, mediaStatus, proctorerId, participantId, proctorId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-general-failed',
                calltype: calltype,
                mediaStatus: mediaStatus,
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendWebRTCGeneralStarted(_this, calltype, proctorerId, participantId, proctorId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-general-started',
                calltype: calltype,
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendStartWebRTCGeneral(_this, calltype, proctorerId, participantId, proctorId, videoRouter = null) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(JSON.stringify({
            type: 'start-webrtc-general',
            calltype: calltype,
            proctorId: proctorId,
            participantId: participantId,
            proctorerId: proctorerId,
            videoRouter: videoRouter,
            token: getAccessToken(),
            examid: getExamId()
        })
        )
    }
}

// mobile pairing
export function sendWebRTCMobilePairingStarted(_this, proctorerId, participantId, proctorId, videoRouter) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-mobile-pairing-started',
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                videoRouter: videoRouter,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendWebRTCMobilePairingFailed(_this, mediaStatus, proctorerId, participantId, proctorId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-mobile-pairing-failed',
                mediaStatus: mediaStatus,
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

// webrtc private call
export function sendStartWebRTCPrivate(_this, proctorerId, participantId, proctorId, videoRouter = null) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(JSON.stringify({
            type: 'start-webrtc-private',
            proctorId: proctorId,
            participantId: participantId,
            proctorerId: proctorerId,
            videoRouter: videoRouter,
            token: getAccessToken(),
            examid: getExamId()
        })
        )
    }
}

export function sendWebRTCPrivateStarted(_this, proctorerId, participantId, proctorId, videoRouter) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-private-started',
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                videoRouter: videoRouter,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}

export function sendWebRTCPrivateFailed(_this, mediaStatus, proctorerId, participantId, proctorId) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: 'webrtc-private-failed',
                mediaStatus: mediaStatus,
                proctorId: proctorId,
                participantId: participantId,
                proctorerId: proctorerId,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    }
}