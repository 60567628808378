import React from "react";
import Loading from "../../components/Loading";
import { fetchCurrentTs, fetchUpdateVideoValidationTime, fetchUpdateValidationStatus, urlPhotoID } from "../../libs/proctor_utils";
import { Lang } from "../../libs/Language";
import { AttentionAlert, ErrorAlert, SuccessAlert } from "../../components/Alert";
import { Debug, Err } from "../../libs/logger";

// const _COMPARE_WITH_ID = false
export const __Validation__ = function (_this, classes) {
    _this.onValidationWebCamRef = React.createRef();

    this.attachMediaToOnValidationWebCamRef = () => {
        setTimeout(() => {
            try {
                _this.onValidationWebCamRef.current.srcObject = _this.state.userMediaStream;

                _this.inputVideoRef.current.srcObject = _this.state.userMediaStream
                _this.inputDisplayVideoRef.current.srcObject = _this.state.displayMediaStream;
            } catch (e) {
                Err(e);
            }
        }, 1000);
    }

    this.onValidation = () => {
        if (_this.firstTimeValidation) {
            _this.setState({
                loadingFS: {
                    mainText: "Loading ...",
                    subText: Lang(`Selamat Datang ${_this.context.profile.name}, Kami membutuhkan tangkapan wajah anda sesaat lagi.`, _this.state.lang, { en: `Hi ${_this.context.profile.name}, We need your face validation in a moment.` }),
                    displayTextDuration: 4000
                }
            })
        }

        setTimeout(() => {
            _this.setState({ validationInProgress: true, validationImage: false });

            this.attachMediaToOnValidationWebCamRef()

        }, _this.firstTimeValidation ? 1000 : 0)
    }

    this.onValidationValid = async () => {
        // also update directly using HTTP POST
        fetchUpdateValidationStatus({
            id: _this.context.profile.id,
            validation_status: 1
        }, (error) => {
            if (error) {
                Err("error update validation", error);
            }
        }).then((response) => {
            if (response.status !== "success") {
                Err(response)
            } else {
                // handle profile change here, so we don't need to wait longer to get valid value
                _this.setState(prevState => ({ participantProfile: { ...prevState.participantProfile, validation_status: 1 } }))
            }
        })

        _this.commonWs.sendUpdateValidationStatus(null, _this.context.profile.id, "valid");

        _this.state.examProfile.valid_by_default === false ?
            await SuccessAlert(Lang("Proses Validasi Berhasil", _this.state.lang, { en: `Validation is Success` })) :
            await SuccessAlert(Lang("Terima Kasih, Proses Telah Selesai.", _this.state.lang, { en: `Thank You, the process is already done.` }), true)

    }

    this.onProcessingValidation = () => {
        setTimeout(() => {
            _this.pai.faceValidation2(document.getElementById("onCaptureCanvas"), document.getElementById("onKTPCanvas")).then((dist) => {
                _this.setState({
                    onValidationDone: "done",
                    // dist: dist,
                    validationStateMessage: ""
                });
                setTimeout(async () => {
                    if (dist !== -1 && dist < _this.state.examProfile.face_detection_on_validation_thresh /*FACE_DETECTION_THRESHOLD*/) {
                        this.onValidationValid()
                    } else if (dist === -1) {
                        await ErrorAlert(Lang(
                            "Wajah tidak terdeteksi, pastikan tidak ada backlight (cahaya di belakang anda) dan posisi wajah dapat ditangkap kamera, kemudian ulangi lagi psoses validasi.",
                            _this.state.lang,
                            { en: `The face is not detected, make sure there is no backlight (light behind you) and the position of the face can be captured by the camera, then repeat the validation process again.` }
                        ))
                    } else {
                        await ErrorAlert(Lang(
                            "Proses Validasi Gagal, anda dapat mencoba kembali atau klik tombol Bantuan Pengawas.",
                            _this.state.lang, {
                            en: `Validation Process Failed, you can try again or click the Help button.`
                        }));
                    }
                    _this.setState({
                        validationInProgress: false,
                        onValidationDone: "",
                        validationStateMessage: ""
                    })
                    _this._preparation.recoverVideoForPreparation(_this);
                }, 1000);
            }).catch((e) => {
                _this.setState({
                    onValidationDone: "done",
                    validationStateMessage: ""
                });
                setTimeout(async () => {
                    await ErrorAlert(Lang("Gagal Memproses Validasi, anda dapat mencoba kembali atau klik tombol Bantuan Pengawas.", _this.state.lang,
                        { en: `Failed to Process Validation, you can try again or click the Supervisor Help button.` }));
                    _this.setState({
                        validationInProgress: false,
                        onValidationDone: "",
                        validationStateMessage: ""
                    })
                    _this._preparation.recoverVideoForPreparation();
                    Debug(e)
                }, 1000);
            });
        }, 1000);
    }

    this.onAlwaysValid = () => {
        _this.setState({
            validationImage: true,
            onValidationDone: "",
            validationStateMessage: ""
        });

        _this.setState({
            onValidationDone: "done",
            // dist: 0.5,
            validationStateMessage: ""
        });

        setTimeout(() => {
            this.onValidationValid()
            _this.setState({
                validationInProgress: false,
                onValidationDone: "",
                validationStateMessage: ""
            })
            _this._preparation.recoverVideoForPreparation();

        }, 1000)
    }

    this.extractFaceFromWebCamHandler = async (img) => {
        if (img === null) {
            if (_this.state.examProfile.valid_by_default === false) {
                await ErrorAlert("Sistem gagal mendeteksi wajah anda, mohon baca petunjuk yang tersedia, dan coba kembali, pastikan cahaya pada ruangan memadai. Apabila masih belum bisa melakukan proses validasi, anda dapat klik tombol \"Bantuan Pengawas\"")
                _this.setState({
                    validationInProgress: false,
                    onValidationDone: "",
                    validationStateMessage: ""
                })
                _this._preparation.recoverVideoForPreparation();
            } else {
                this.onAlwaysValid();
            }
        } else if (_this.state.examProfile.valid_by_default === false) {
            _this.pai.extractFaceFromKTP(
                // _this.context.profile.photo
                urlPhotoID(_this.context.profile.id, _this.context.profile.folder_rand)
            ).then(async (imgktp) => {
                if (imgktp === null) {
                    await ErrorAlert("Sistem gagal mendeteksi wajah anda, mohon klik tombol \"Bantuan Pengawas\".")
                    _this.setState({
                        validationInProgress: false,
                        onValidationDone: ""
                    })
                    _this._preparation.recoverVideoForPreparation();

                } else {
                    if (document.getElementById("onKTPCanvas") !== null) {
                        document.getElementById("onKTPCanvas").src = imgktp.src;
                    }
                    document.getElementById("onCaptureCanvas").src = img.src;

                    _this.setState({
                        validationImage: true,
                        onValidationDone: "",
                        validationStateMessage: ""
                    });

                    setTimeout(async () => {
                        await AttentionAlert("Apabila wajah anda cocok dengan photo diri, klik \"Proses Validasi\", atau anda bisa menyesuaikan kembali dengan menekan tombol \"Capture\".")
                    }, 1000);
                }
            })
        } else {
            document.getElementById("onCaptureCanvas").src = img.src;

            this.onAlwaysValid()
        }
    }

    this.renderCapture = () => {
        return <button
            className={[classes.btnOk, classes.button2div].join(" ")}
            onClick={() => {
                _this.setState({
                    onValidationDone: "processing",
                    validationImage: false,
                    validationStateMessage: "Sedang Mendeteksi Wajah ..."
                });
                setTimeout(() => {
                    _this.pai.extractFaceFromWebCam(_this.onValidationWebCamRef).then((img) => {
                        this.extractFaceFromWebCamHandler(img)
                    })
                }, 1000)
            }}
        >Capture</button>
    }

    this.renderIndentityCapture = () => {
        return <button
            className={[classes.btnOk, classes.button2div].join(" ")}
            onClick={() => {
                _this.setState({
                    onValidationDone: "processing",
                    validationImage: false,
                    validationStateMessage: "Sedang Mendeteksi Wajah ..."
                });
                setTimeout(() => {
                    _this.pai.extractFaceFromWebCam(_this.onValidationWebCamRef).then((img) => {
                        this.extractFaceFromWebCamHandler(img)
                    })
                }, 1000)
            }}
        >Capture</button>
    }

    this.renderCapFace = (webcamEl, capture) => {
        return <>
            <div className={classes.info}>
                <span>
                    1. Tegakkan badan.
                </span>
                <span>
                    2. Pastikan wajah terlihat jelas di kamera.
                </span>
                <span>
                    3. Setelah siap, tekan "Capture", dan tunggu sampai AI berhasil mendapatkan tangkapan wajah anda.
                </span>
            </div>
            <div className={classes.image}>{webcamEl}</div>
            {
                _this.state.onValidationDone === "processing" ?
                    <div style={{ margin: "5px" }}>
                        <Loading />
                    </div> : null
            }
            <div className={classes.canvas} style={{ margin: "10px" }}>
                <img
                    alt="validation"
                    id="onValidationCanvas"
                    height="100px"
                    style={{ display: "none" }}
                ></img>
                <img
                    alt="capture"
                    id="onCaptureCanvas"
                    height="100px"
                    style={{ display: _this.state.validationImage === true ? "flex" : "none" }}
                />
            </div>
            {capture}
        </>
    }

    this.renderCompareFace = (webcamEl, capture) => {
        return <>
            <div className={classes.info}>
                <span>
                    1. Tegakkan badan.
                </span>
                <span>
                    2. Pastikan wajah terlihat jelas di kamera.
                </span>
                <span>
                    3. Setelah siap, tekan "Capture", dan tunggu sampai AI berhasil mendapatkan tangkapan wajah anda.
                </span>
                <span>
                    4. Setelah AI berhasil mendapatkan wajah anda, tombol "Proses Validasi" akan muncul. Klik tombol "Proses Validasi" untuk melakukan validasi wajah.
                </span>
                <span>
                    5. Tunggu beberapa saat sampai AI selesai melakukan proses validasi.
                </span>
                <span>
                    6. Apabila proses validasi gagal, anda bisa menyesuaikan posisi anda, dan mencoba proses validasi kembali.
                </span>
                <span>
                    7. Apabila proses validasi tetap gagal, karena kualitas dari gambar, anda dapat menekan tombol bantuan.
                </span>
            </div>
            <div className={classes.image}>
                {
                    _this.props.mode !== "mobile"
                        ?
                        webcamEl
                        :
                        <div className={classes.image_mobile}>
                            <div className={classes.image_mobile_left}>
                                <video ref={_this.onValidationWebCamRef} height="200px" autoPlay muted playsInline></video>
                                <div
                                    style={{
                                        fontSize: ".75rem",
                                        marginTop: "5px"
                                    }}
                                >
                                    WebCam
                                </div>
                            </div>

                            <div className={classes.image_mobile_right}>
                                <img id="ktpimage" alt="ktpimage" src={_this.context.profile.photo} height="200px" />
                                <div style={{
                                    fontSize: ".75rem",
                                    marginTop: "5px"
                                }}>
                                    Identitas
                                </div>
                            </div>
                        </div>
                }

                <div>
                    <div className={classes.canvas} style={{
                    }}>
                        <img
                            alt="capture"
                            id="onCaptureCanvas"
                            height="100px"
                            style={{ display: _this.state.validationImage === true ? "flex" : "none" }}
                        />
                        {/* {_this.state.validationImage === true &&
                        <ProgressStatus type="equal" state={_this.state.onValidationDone} />
                    } */}
                        {
                            _this.state.onValidationDone === "processing" ?
                                <div style={{ margin: "5px" }}>
                                    <Loading />
                                </div> :
                                _this.state.validationImage === true && <span style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    margin: "5px"
                                }}>{"<>"}</span>
                        }
                        <img
                            alt="capture"
                            id="onKTPCanvas"
                            height="100px"
                            style={{ display: _this.state.validationImage === true ? "block" : "none" }}
                        />
                        <img
                            alt="validation"
                            id="onValidationCanvas"
                            height="100px"
                            style={{ display: "none" }}
                        ></img>
                    </div>
                    <span
                        style={{
                            fontSize: ".75rem",
                            fontWeight: "bold",
                            marginTop: "5px"
                        }}
                    >
                        {_this.state.validationStateMessage}
                    </span>
                </div>
                <div className={classes.image_mobile_bottom}>
                    <img id="ktpimage" alt="ktpimage" src={_this.context.profile.photo} height="200px" />
                    <div style={{
                        fontSize: ".75rem",
                        marginTop: "5px"
                    }}>
                        Identitas
                    </div>
                </div>
            </div>
            <div style={{ margin: "5px" }}>
                {
                    _this.firstTimeValidation === false && <div
                        className={classes.btnCancel}
                        style={{ marginRight: "20px" }}
                        onClick={() => {
                            _this.setState({
                                validationInProgress: false,
                                onValidationDone: ""
                            })
                            _this._preparation.recoverVideoForPreparation();
                        }}
                    >
                        Back
                    </div>
                }
                {capture}
                {_this.state.onValidationDone !== "processing" && _this.state.validationImage === true ?
                    <>
                        <span
                            style={{
                                margin: "5px",
                                fontWeight: "bold"
                            }}
                        >
                            {" > "}
                        </span>
                        <button
                            className={[classes.btnOk, classes.button2div].join(" ")}
                            onClick={() => {
                                _this.setState({
                                    onValidationDone: "processing",
                                    validationStateMessage: "Sedang Melakukan Validasi Wajah ..."
                                })
                                this.onProcessingValidation();
                            }}
                        >Proses Validasi</button>
                    </> : null}
            </div>
        </>
    }

    this.onRenderValidation = () => {
        const webcamEl = <div>
            <video ref={_this.onValidationWebCamRef} height="200px" autoPlay muted playsInline></video>
            <div
                style={{
                    fontSize: ".75rem",
                    marginTop: "5px"
                }}
            >
                WebCam
            </div>
        </div>

        const capture = this.renderCapture()

        const capface = this.renderCapFace(webcamEl, capture)

        const compareface = this.renderCompareFace(webcamEl, capface)

        return <div className={[classes.container, classes.ascroll, classes.FadeInPage1s
        ].join(" ")}>
            <div className={classes.container_validation}>
                {
                    _this.state.examProfile.valid_by_default === true ?
                        capface :
                        compareface
                }
            </div>
            {_this._manual.renderManual()}
        </div >
    }

    this.onValidationVideo = () => {
        _this.commonWs.sendVideoValidationTime(null, _this.context.profile.id, _this._dynamicvalidation.identity_validation.video_start_validation, _this._dynamicvalidation.identity_validation.video_end_validation);

        // also update directly using HTTP POST
        fetchUpdateVideoValidationTime({
            id: _this.context.profile.id,
            startTs: _this._dynamicvalidation.identity_validation.video_start_validation, endTs: _this._dynamicvalidation.identity_validation.video_end_validation
        }, (error) => {
            if (error) {
                Err("error update video validation", error);
            }
        }).then((response) => {
            if (response.status !== "success") {
                Err(response)
            }
        })
    }

    this.onHandleStartTs = () => {
        fetchCurrentTs((error) => { }).then((data) => {
            _this._dynamicvalidation.identity_validation.video_start_validation = data.result;
            _this._dynamicvalidation.startVideoRecording = true;
            // restart the websocket video face
            delete _this.faceWs;
            _this.faceWs = null;

            // to make it take every second image
            _this.imageToServerDelay = 500;
        })
    }

    this.onHandleStopTs = () => {
        fetchCurrentTs((error) => { }).then((data) => {
            _this._dynamicvalidation.identity_validation.video_end_validation = data.result;
            _this._dynamicvalidation.startVideoRecording = false;
            // restart the websocket video face

            delete _this.faceWs;
            _this.faceWs = null;

            _this.imageToServerDelay = _this.DEFAULT_IMAGE_TO_SERVER_DELAY;
        })
    }

}