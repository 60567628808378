import * as constants from "../WebRTC/constants.js";
import { Debug, Err, Info } from "../logger.js";
import { fetchParticipantsByProctorId, fetchParticipantsBySearch } from "../proctor_utils.js";
import WebRTCLiveVideo from "../WebRTC/WebRTCLiveVideo.js";
import { callingRoutine, getDoNotRun, hangupGeneralParticipant, switchDoNotRun, webRTCAction, webRTCGeneralAction } from "./Calling.js";

class ParticipantManager {
  constructor(proctorerId, proctorerName, stateCallback) {
    this.participants = {};
    this.stateCallback = stateCallback;
    this.commonWs = null;
    this.seq = 0;

    this.searchText = ""

    this.proctorerId = proctorerId;
    this.proctorerName = proctorerName;

    this.callingInterval = null;
    this.updateStatusInterval = null;
    this.waitingForStartedWebRTC = false;

    this.videoElInterval = null;
    this.videoRouter = [];

    if (this.updateStatusInterval !== null) {
      clearInterval(this.updateStatusInterval);
    }

    this.updateStatusInterval = setInterval(() => {
      if (this.commonWs === null) {
        return;
      }

      if (this.proctorerId.includes("proctoradmin")) {
        this.getParticipantStatus(this.searchText, true)
      } else {
        this.getParticipantStatus(this.proctorerId);
      }
    },
      // 30000
      // use 10s update for proctor, 
      10000
    );

    if (this.callingInterval !== null) {
      clearInterval(this.callingInterval);
    }

    this.callingInterval = setInterval(() => {
      if (this.proctorerId.includes("#") || this.proctorerId.includes("admin")) {
        // Debug("do not call, you are sub proctor or admin");
        return;
      }
      callingRoutine(this, "liveVideo");
      callingRoutine(this, "screenCapture");
      callingRoutine(this, "mobilePairing");
    }, 5000);
  }

  hangup(calltype = "liveVideo", participantId) {
    hangupGeneralParticipant(this, calltype, participantId)
  }

  switchDoNotRun(calltype = "liveVideo", participantId) {
    switchDoNotRun(this, calltype, participantId)
  }

  getDoNotRun(calltype, participantId) {

    return getDoNotRun(this, calltype, participantId)
  }

  setSearchParam(searchText) {
    this.searchText = searchText
  }

  getSearchParam() {
    return this.search;
  }

  updateVideoRouter(videoRouter) {
    this.videoRouter = videoRouter;
  }

  getVideoRouterCandidate = () => {
    const id = Math.round(Math.random() * (this.videoRouter.length - 1))
    return this.videoRouter[id];
  }

  handleEstablishedVideo(participantId) {
    // this.handleEstablishedLiveVideo(participantId);
    const par = this.participants[participantId];
    this.handleEstablished(par, "liveVideo");
    this.handleEstablished(par, "screenCapture");
    this.handleEstablished(par, "mobilePairing");
  }

  handleEstablished(par, lv /* liveVideo/liveScreen/mobilePairing*/) {
    let elIdVideo = "liveVideo";
    let elIdImg = "img";
    let elStatus = "status";
    if (lv === "screenCapture") {
      elIdVideo = "screenCapture";
      elIdImg = "imgScreenCapture";
      elStatus = "status";
    } else if (lv === "mobilePairing") {
      elIdVideo = "mobilePairing";
      elIdImg = "imgMobilePairing";
      elStatus = "status";
    }
    if (par[lv].establishedStartTs !== 0) {
      if (Date.now() - par[lv].establishedStartTs > 30000) {
        // the element on ParticipantVideo.js
        // the element of the online/offline status
        // online/offline status is also handled by the other process
        let el = null;
        if (lv === "liveVideo") {
          el = document.getElementById(`${elStatus}_` + par.proctorId);
          el && (el.style.display = "none");
        }
        // the element of the video
        el = document.getElementById(`${elIdVideo}_${par.proctorId}`);
        el && (el.style.display = "flex");

        // the element of the image
        el = document.getElementById(`${elIdImg}_` + par.proctorId);
        if (par[lv].show_image === false) {
          el && (el.style.display = "none");
        } else {
          el && (el.style.display = "flex");
        }
      } else if (par[lv].show_image === true) {
        // the element of the video
        let el = document.getElementById(`${elIdVideo}_${par.proctorId}`);
        el && (el.style.display = "flex");

        // the element of the image
        el = document.getElementById(`${elIdImg}_` + par.proctorId);
        el && (el.style.display = "flex");
      } else if (par[lv].show_image === false) {
        // the element of the video
        let el = document.getElementById(`${elIdVideo}_${par.proctorId}`);
        el && (el.style.display = "none");

        // the element of the image
        el = document.getElementById(`${elIdImg}_` + par.proctorId);
        el && (el.style.display = "flex");
      }
    } else {
      let el = document.getElementById(`${elIdVideo}_${par.proctorId}`);
      if (par[lv].show_image === false) {
        el && (el.style.display = "none");
      } else {
        el && (el.style.display = "flex");
      }

      el = document.getElementById(`${elIdImg}_` + par.proctorId);
      el && (el.style.display = "flex");
    }
  }

  handleEstablishedLiveVideo(participantId) {
    const par = this.participants[participantId];
    if (par.liveVideo.establishedStartTs !== 0) {
      if (Date.now() - par.liveVideo.establishedStartTs > 30000) {
        // the element on ParticipantVideo.js
        // the element of the online/offline status
        // online/offline status is also handled by the other process
        let el = document.getElementById("status_" + par.proctorId);
        el && (el.style.display = "none");

        // the element of the video
        el = document.getElementById(`liveVideo_${par.proctorId}`);
        el && (el.style.display = "flex");

        // the element of the image
        el = document.getElementById("img_" + par.proctorId);
        if (par.liveVideo.show_image === false) {
          el && (el.style.display = "none");
        } else {
          el && (el.style.display = "flex");
        }
      } else if (par.liveVideo.show_image === true) {
        // the element of the video
        let el = document.getElementById(`liveVideo_${par.proctorId}`);
        el && (el.style.display = "flex");

        // the element of the image
        el = document.getElementById("img_" + par.proctorId);
        el && (el.style.display = "flex");
      } else if (par.liveVideo.show_image === false) {
        // the element of the video
        let el = document.getElementById(`liveVideo_${par.proctorId}`);
        el && (el.style.display = "none");

        // the element of the image
        el = document.getElementById("img_" + par.proctorId);
        el && (el.style.display = "flex");
      }
    } else {
      let el = document.getElementById(`liveVideo_${par.proctorId}`);
      if (par.liveVideo.show_image === false) {
        el && (el.style.display = "none");
      } else {
        el && (el.style.display = "flex");
      }

      el = document.getElementById("img_" + par.proctorId);
      el && (el.style.display = "flex");
    }
  }

  setCommonWs(commonWs) {
    this.commonWs = commonWs;
  }
  
  setSoundDetectedTs(participantId, ts) {
    this.participants[participantId].soundDetectedTs = ts;
  }

  checkDynValidationValue(dx) {
    if (this.participants[dx.id].dynamic_validation.confirmed !== dx.confirmed ||
      this.participants[dx.id].dynamic_validation.value !== dx.value) {
      this.participants[dx.id].dynamic_validation.confirmed = dx.confirmed;
      this.participants[dx.id].dynamic_validation.value = dx.value;
      this.participants[dx.id].dynamic_validation = {
        green: dx.green,
        yellow: dx.yellow,
        orange: dx.orange,
        pink: dx.pink,
        red: dx.red,
        value: dx.value,
        valid: dx.valid,
        always_valid: dx.always_valid,
        confirmed: dx.confirmed
      }
      return true;
    }
    return false;
  }

  getParticipantStatus(searchOrProctorerId, search = false) {
    const handleDataResult = (data) => {
      if (data !== null && data.status === "success") {
        let dataUpdated = false;
        for (let d in data.result) {
          let dx = data.result[d];
          if (this.participants[dx.id]) {
            dataUpdated = this.checkDynValidationValue(dx);

            this.participants[dx.id].validation_status = dx.validation_status;

            try {
              const mi = JSON.parse(dx.misc_information)
              const tmi = this.participants[dx.id].misc_information;
              if (tmi.ts < mi.ts) {
                this.participants[dx.id].misc_information = mi;
                dataUpdated = true;
              }
            } catch (e) {
              this.participants[dx.id].misc_information = {
                webcam: false,
                screencap: false,
                ts: 0,
                response_time: 9999
              }
            }
            this.participants[dx.id].ice_id = dx.ice_id;

            const diff = (Date.now() / 1000 - parseInt(dx.last_connected))
            if (diff < 40 * 2) {
              if (this.participants[dx.id].liveVideo.available === false) {
                this.participants[dx.id].liveVideo.available = true;
                dataUpdated = true;
              }
            } else {
              if (this.participants[dx.id].liveVideo.available === true) {
                this.participants[dx.id].liveVideo.available = false;
                dataUpdated = true;
              }
            }

            if (this.participants[dx.id].status !== dx.status) {
              if (dx.status === 'offline' && this.participants[dx.id].offlineCount > 2) {
                this.participants[dx.id].status = dx.status;
                this.participants[dx.id].offlineCount = 0;
                dataUpdated = true;
              } else if (dx.status === 'offline') {
                this.participants[dx.id].offlineCount++;
              } else if (dx.status === 'online') {
                this.participants[dx.id].status = "online";
                this.participants[dx.id].offlineCount = 0;
              }
            }

            if (this.participants[dx.id].currentTime === undefined) {
              this.participants[dx.id].currentTime = 0;
            }
            let elv = document.getElementById(this.participants[dx.id].proctorId);

            let el = document.getElementById("status_" + this.participants[dx.id].proctorId);

            if (el !== undefined && el !== null) {
              if (this.participants[dx.id].status === 'online') {
                el.style.display = "none";
              } else {
                el.style.display = "flex";
              }
            }

            if (this.participants[dx.id].status === 'online' && elv !== null && this.participants[dx.id].currentTime < elv.currentTime) {
              this.participants[dx.id].currentTime = elv.currentTime;
              this.participants[dx.id].try = 0;
            } else if (this.participants[dx.id]?.currentTime > elv?.currentTime) {
              this.participants[dx.id].currentTime = elv.currentTime;
            }
          }
        }
        if (dataUpdated && this.stateCallback !== null) {
          this.stateCallback();
        }
      }
    }
    const handleError = (error) => {
      Err(error);
    }
    if (search === false) {
      const proctorerId = searchOrProctorerId
      fetchParticipantsByProctorId(proctorerId, handleError)
        .then(handleDataResult);
    } else {
      const search = searchOrProctorerId
      fetchParticipantsBySearch(search, handleError)
        .then(handleDataResult);
    }
  }

  deleteAllParticipants() {
    this.participants = {};
  }

  addParticipant(userId, userUUId, userName, ice_id, photo, folder_rand, userStatus, validation_status, dynamic_validation) {
    this.participants[userId] = {
      seq: this.seq++,
      id: userId,
      uuid: userUUId,
      username: userName,
      ice_id: ice_id,
      status: userStatus,
      photo: photo,
      folder_rand: folder_rand,
      proctorId: this.proctorerId + userId,
      offlineCount: 0,
      validation_status: validation_status,
      dynamic_validation: dynamic_validation,
      soundDetectedTs: 0,
      liveVideo: {
        do_not_run: false,
        available: false,
        try: 0,
        call_status: "idle",
        videoRouter: null,
        establishedStartTs: 0,
        show_image: false,
        webrtc: null,
        currentTime: 0,
        currentTimeTry: 0,

        waitingWebRTC: {
          // the time we send start webrtc signal
          send_start_webrtc_ts: 0,

          // webrtc status is received, either
          // participant webrtc is failed or running
          received_webrtc_status_ts: 0,

          // sendPreOffer
          sendPreOffer: ""
        },
      },
      mobilePairing: {
        do_not_run: true,
        try: 0,
        call_status: "idle",
        videoRouter: null,
        establishedStartTs: 0,
        show_image: false,
        webrtc: null,
        currentTime: 0,
        currentTimeTry: 0,

        waitingWebRTC: {
          // the time we send start webrtc signal
          send_start_webrtc_ts: 0,

          // webrtc status is received, either
          // participant webrtc is failed or running
          received_webrtc_status_ts: 0,

          // sendPreOffer
          sendPreOffer: ""
        },
      },
      screenCapture: {
        do_not_run: true,
        try: 0,
        call_status: "idle",
        videoRouter: null,
        establishedStartTs: 0,
        show_image: false,
        webrtc: null,
        currentTime: 0,
        currentTimeTry: 0,

        waitingWebRTC: {
          // the time we send start webrtc signal
          send_start_webrtc_ts: 0,

          // webrtc status is received, either
          // participant webrtc is failed or running
          received_webrtc_status_ts: 0,

          // sendPreOffer
          sendPreOffer: ""
        },
      },
    };
  }

  getParticipants() {
    return this.participants;
  }

  printParticipants() {
    Debug(this.participants);
  }

  webRTCAction(participantId, isWebRTCRunning, type, mediaStatus) {
    webRTCAction(this, participantId, isWebRTCRunning, type, mediaStatus);
  }

  webRTCGeneralAction(calltype, participantId, isWebRTCRunning, mediaStatus) {
    webRTCGeneralAction(this, calltype, participantId, isWebRTCRunning, mediaStatus)
  }
}

export default ParticipantManager;
