import React from "react";
import classes from "./UserManual.module.scss"

class UserManual extends React.Component {
    render() {
        return <div className={classes.container}>
            <embed
                type="application/pdf"
                src={this.props.src}
                width="100%"
                height="100%"
            ></embed>
            <div
                className={classes.btnOk}
                onClick={this.props.onClose}
            >X</div>
        </div>
    }
}

export default UserManual;