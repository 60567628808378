import React from "react";
import classes from "./__index__.module.scss";

import __index__ from "../ProktorEventLoginPage/__index__";
import { Err } from "../../libs/logger";
import { fetchCredRequestToExam } from "../../libs/proctor_utils";
class QuizPage extends React.Component {
    constructor(props) {
        super(props);
        const path = window.location.pathname;
        const pathSegments = path.split("/");
        console.log(pathSegments);
        const info = pathSegments[2];
        const decodedInfo = JSON.parse(atob(info));
        this.iframeRef = React.createRef();
        // sample eyJ1c2VybmFtZSI6ICJkZWRkaWhwQGdtYWlsLmNvbSIsICJwYXNzd29yZCI6ICJBbWFuaW4xMjMjIn0=
        // Isi directUser dengan nilai decodedInfo
        this.directUser = { ...decodedInfo, coursename: 'SNBT_010203' };
        // console.log(this.directUser)
        // this.sendDataToIframe();

        this.state = {
            quizCred: null
        }
    }

    sendCredRequestToExam() {
        fetchCredRequestToExam(
            this.directUser.username,
            this.directUser.password,
            this.directUser.coursename,
            (e) => {
                console.log("ERROR", e);
            }).then((response) => {
                console.log("response ", response);
                if (response.status !== "success") {
                    return;
                }
                this.setState({ quizCred: response.result.cred })
                this.iframeRef.current.src = this.iframeRef.current.src
            })
    }

    setupIframe() {
        if (this.iframeRef?.current) {
            console.log("debug 1")
            // Kirim pesan saat iframe telah dimuat
            this.iframeRef.current?.addEventListener('load', this.sendMessageToIframe);
            return () => {
                // Bersihkan event listener saat komponen di-unmount
                console.log("REMOVE EVENT LISTENER")
                this.iframeRef.current?.removeEventListener('load', this.sendMessageToIframe);
                // Hapus data di sessionStorage
                sessionStorage.removeItem('directUser');
            };
        } else {
            console.log("debug 59");
        }
    }

    sendMessageToIframe = () => {
        console.log("send message to iframe");
        if (this.iframeRef.current && this.state.quizCred !== null) {
            // Mengirim pesan berbentuk object
            const message = {
                username: this.state.quizCred.username,
                password: this.state.quizCred.password,
            }
            console.log("MESSAGE = ", message)
            try {
                this.iframeRef.current?.contentWindow.postMessage(message, "*");
            } catch (error) {
                console.log(error);
                Err(error)
            }
        }
    }

    componentDidMount() {
        this.setupIframe();
        this.sendCredRequestToExam();

        // this.sendDataToIframe();
    }


    // sendDataToIframe() {
    //     console.log("SEND DATA IFRAME");
    //     if (this.iframeRef?.current) {
    //         console.log("debug 1")
    //         // Kirim pesan saat iframe telah dimuat
    //         this.iframeRef.current?.addEventListener('load', this.sendMessageToIframe);
    //         return () => {
    //             // Bersihkan event listener saat komponen di-unmount
    //             this.iframeRef.current?.removeEventListener('load', this.sendMessageToIframe);
    //             // Hapus data di sessionStorage
    //             sessionStorage.removeItem('directUser');
    //         };
    //     } else {
    //         console.log("debug 59");
    //     }
    // }


    render() {
        return <div className={classes.container}>
            <iframe
                src="https://console.amanin.id:9443/login/index.php"
                title="exam-view"
                width="100%"
                height="100%"
                frameBorder="0"
                // src={getQuestionUrl(_this.state.examProfile.url, _this.context.profile.question_id)}
                // src={'http://localhost:5173/exam'}
                allowFullScreen
                id="exam-iframe"
                rel="noreferrer"
                ref={this.iframeRef}
            />

        </div>
    }
}

export default QuizPage;