import WebRTCGeneral from "../WebRTC/WebRTCGeneral.js";
import WebRTCLiveVideo from "../WebRTC/WebRTCLiveVideo";
import * as constants from "../WebRTC/constants.js";
import { Debug, Info } from "../logger";

const getParVideo = (participant, type = "liveVideo") => {
    if (type === "liveVideo") {
        return participant.liveVideo;
    } else if (type === "mobilePairing") {
        return participant.mobilePairing
    } else if (type === "screenCapture") {
        return participant.screenCapture
    }
    return participant.liveVideo;
}

const sendStartWebRTCGeneral = (_this, type, proctorerId, participantId, videoRouter) => {
    Debug("[SEND START WEBRTC GENERAL] type=", type);
    if (type === "liveVideo") {
        _this.commonWs.sendStartWebRTCGeneral(type, proctorerId, participantId, proctorerId + participantId, videoRouter);

        return
        _this.commonWs.sendStartWebRTC(proctorerId, participantId, proctorerId + participantId, videoRouter);
        // _this.commonWs.sendStartWebRTC(
        //         _this.proctorerId,
        //         u,
        //         _this.proctorerId + u,
        //         parv.videoRouter)
    } else if (type === "mobilePairing" || type === "screenCapture") {
        _this.commonWs.sendStartWebRTCGeneral(type, proctorerId, participantId, proctorerId + participantId, videoRouter);
    }
}

export const callingRoutine = (_this, type = "liveVideo") => {
    if (_this.commonWs === null) {
        return;
    }

    let servedNumber = 0;

    let sortByTry = [];

    let onlineNumber = 0;
    for (let u in _this.participants) {
        const par = _this.participants[u];


        // _this.handleEstablishedLiveVideo(u);
        _this.handleEstablishedVideo(u);

        if (par[type].do_not_run === true) {
            // close all webrtc connection
            Debug(`[${type}][${u}] do not run = ${par[type].do_not_run}`);
            continue;
        }

        if (par.status !== "online") {
            continue;
        }
        let parv = getParVideo(par, type);
        Debug(`[${type}][${u}] Call Status = ${parv.call_status}`);

        if (parv.call_status === "established") {
            continue;
        }
        onlineNumber++;

        let x = {
            try: parv.try,
            id: u
        }
        let i = 0;
        for (; i < sortByTry.length; i++) {
            let s = sortByTry[i];
            if (s.try >= parv.try) {
                sortByTry.splice(i, 0, x)
                break;
            }
        }
        if (i === sortByTry.length) {
            sortByTry.push(x);
        }
    }

    // check if there is participant is calling or not
    let nCalling = 10;
    if (sortByTry.length < nCalling) {
        nCalling = sortByTry.length;
    }
    for (let i = 0; i < nCalling; i++) {
        let sbt = sortByTry[i];
        if (sbt.try !== -1) {
            let u = sbt.id;
            const par = _this.participants[u];
            let parv = getParVideo(par, type);

            //there is only one participant left, keep try number to 0
            if (onlineNumber === 1) {
                parv.try = 0;
            }

            // First In First Serve
            // let p = _this.participants[u];
            if (parv.waitingWebRTC.send_start_webrtc_ts === 0) {
                servedNumber++;
                parv.try++;

                // if there is online participant, using commonWs call 
                // the participant to activate webrtc
                parv.waitingWebRTC.send_start_webrtc_ts = Date.now();
                parv.videoRouter = _this.getVideoRouterCandidate();
                sendStartWebRTCGeneral(_this,
                    type,
                    _this.proctorerId,
                    u,
                    parv.videoRouter)
            } else if (Date.now() - parv.waitingWebRTC.send_start_webrtc_ts > 10000) {
                if (parv.waitingWebRTC.sendPreOffer !== "failed") {
                    parv.waitingWebRTC.send_start_webrtc_ts = 0;
                }
            }
        }
    }

    if (servedNumber === 0) {
        //  reset all of the sendPreOffer failed
        for (let u in _this.participants) {
            const par = _this.participants[u]
            let parv = getParVideo(par, type);

            parv.waitingWebRTC.sendPreOffer = "";
        }
    }
}

// webrtc
export const webRTCAction = (_this, participantId, isWebRTCRunning, type = "liveVideo", mediaStatus = null) => {
    const par = _this.participants[participantId]

    let parv = getParVideo(par, type);

    if (isWebRTCRunning === true) {
        parv.waitingWebRTC.sendPreOffer = "start";
        callParticipant(_this, type, participantId);
        return;
    }
    parv.waitingWebRTC.sendPreOffer = "failed";
    parv.waitingWebRTC.send_start_webrtc_ts = 0;
}

// webrtc general
export const webRTCGeneralAction = (_this, calltype /*liveVideo, mobilePairing*/, participantId, isWebRTCRunning, mediaStatus = null) => {
    const par = _this.participants[participantId]

    let parv = getParVideo(par, calltype);

    if (isWebRTCRunning === true) {
        parv.waitingWebRTC.sendPreOffer = "start";
        callGeneralParticipant(_this, calltype, participantId);
        return;
    }
    parv.waitingWebRTC.sendPreOffer = "failed";
    parv.waitingWebRTC.send_start_webrtc_ts = 0;
}

export const switchDoNotRun = (_this, calltype, userId) => {
    let par = _this.participants[userId];

    let parv = getParVideo(par, calltype);
    if (parv.do_not_run === false) {
        hangupGeneralParticipant(_this, calltype, userId);
    }
    parv.do_not_run = !parv.do_not_run;
}

export const getDoNotRun = (_this, calltype, userId) => {
    let par = _this.participants[userId];
    let parv = getParVideo(par, calltype);
    return parv.do_not_run;
}

export const hangupGeneralParticipant = (_this, calltype = "liveVideo", userId = "") => {
    let par = _this.participants[userId];

    let parv = getParVideo(par, calltype);

    parv.webrtc.close();
    setTimeout(() => {
        parv.call_status = "disconnected";
        parv.establishedStartTs = 0;
        delete parv.webrtc;
        parv.webrtc = null;
    }, 7000)
}

export const callGeneralParticipant = (_this, calltype = "liveVideo", userId = "") => {
    let par = _this.participants[userId];

    let parv = getParVideo(par, calltype);

    parv.call_status = "calling";
    delete parv.webrtc;
    parv.webrtc = null;
    parv.webrtc = new WebRTCGeneral(
        calltype, //calltype
        par.proctorId, //registerId
        par.proctorName, //registerName
        parv.videoRouter, //videoRouter
        () => {
            if (parv.webrtc === null) {
                return;
            }
            parv.webrtc.sendPreOffer(
                constants.callType.VIDEO_PERSONAL_CODE,
                `${calltype}_${userId}`,
                `${calltype}_${par.proctorId}`,
                par.proctorName
            );
        },
        false, //isParticipant
        null, // streamLocal
        // null,
        (status) => {
            Debug(`[${calltype}][${userId}] WEBRTC ON DISCONNECT`, status);
            if (par !== undefined) {
                parv.call_status = status;
            }
            if (status !== "established") {
                parv.call_status = "idle";
                parv.establishedStartTs = 0;
            } else if (status === "established") {
                parv.call_status = "established"
                parv.currentTime = 0;
                parv.establishedStartTs = Date.now();
            } else {
                parv.webrtc = null;
            }
        }
    );
}

export const callParticipant = (_this, calltype = "liveVideo", userId = "") => {
    let par = _this.participants[userId];

    let parv = getParVideo(par, calltype);

    parv.call_status = "calling";
    delete parv.webrtc;
    parv.webrtc = null;
    parv.webrtc = new WebRTCLiveVideo(
        par.ice_id,
        par.proctorId,
        par.proctorName,
        parv.videoRouter,
        () => {
            if (parv.webrtc === null) {
                return;
            }
            parv.webrtc.sendPreOffer(
                constants.callType.VIDEO_PERSONAL_CODE,
                userId,
                par.proctorId,
                par.proctorName
            );
        },
        false,
        null,
        null,
        (status) => {
            Debug(`[${calltype}][${userId}] WEBRTC ON DISCONNECT`, status);
            if (par !== undefined) {
                parv.call_status = status;
            }
            if (status !== "established") {
                parv.call_status = "idle";
                parv.establishedStartTs = 0;
            } else if (status === "established") {
                parv.call_status = "established"
                parv.currentTime = 0;
                parv.establishedStartTs = Date.now();
            } else {
                parv.webrtc = null;
            }
        }
    );
}