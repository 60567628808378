import React from "react";
import classes from "./Logout.module.scss";
import logout from "../assets/imgs/logout.png";
import PropTypes from "prop-types";
import { DecisionAlert } from "./Alert";

class Logout extends React.Component {
    render() {
        return <div className={classes.container}>
            <button className={[classes.container_logout, classes.button2div].join(" ")} onClick={async () => {
                const confirmed = await DecisionAlert("Are you sure to quit now?");
                if (!confirmed) {
                    return;
                }
                this.props.onLogout();
            }}
            >
                <img alt="logout" src={logout} width="26px"></img>
                <span>Logout</span>
            </button>
        </div>
    }
}

Logout.propTypes = {
    onLogout: PropTypes.func.isRequired
}

export default Logout;