import React from "react";
import classes from "./ProfileShow.module.scss";
import PropTypes from "prop-types";
import { Lang } from "../libs/Language";
import { LANG } from "../libs/utils";
import { DISABLE_SCREEN_NUMBER_DETECTION, TELKOM_CASE } from "../libs/proctor_utils";
import { DecisionAlert } from "./Alert";

class ProfileShow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAudioOn: true,
            status: 'On'
        }
    }

    toggleAudio = (e) => {
        this.setState({
            isAudioOn: e.target.checked
        }, () => {
            this.props.handleAudio(!this.state.isAudioOn);
        });
    };

    // status = () => {
    //     return this.state.isAudioOn ? "On" : "Off"
    // }

    render() {
        const pr = this.props.profile;

        let statusEl = <></>
        if (pr.validation_status === 2) {
            statusEl = <>
                <span className={classes.invalid}>Blocked</span>
                {
                    this.props.showButton === true ?
                        <button
                            className={[classes.btnOk].join(" ")}
                            style={{ display: "inline-flex" }}
                            onClick={() =>
                                this.props.onBlocked("Open", pr)
                            }>
                            {Lang(`Buka Block`, LANG(), { en: `Open Block` })}
                        </button> : null
                }
            </>
        } else if (pr.validation_status === 1 || pr.validation_status === 3) {
            statusEl = <>
                <span className={classes.valid}>Valid</span>
                {
                    this.props.showButton === true ?
                        <button
                            className={[classes.btnOk].join(" ")}
                            style={{ display: "inline-flex" }}
                            onClick={() =>
                                this.props.onValidInvalid("Invalid")
                            }>
                            {Lang(`Set Tidak Valid`, LANG(), { en: `Set Invalid` })}
                        </button> : null
                }
            </>
        } else {
            statusEl = <>
                <span className={classes.invalid}>Invalid</span>
                {
                    this.props.showButton === true ?
                        <button
                            className={[classes.btnOk].join(" ")}
                            style={{ display: "inline-flex" }}
                            onClick={() => this.props.onValidInvalid("Valid")}
                        >
                            Set Valid
                        </button> : null
                }
            </>
        }

        return <div className={classes.container}>
            <table>
                <tbody>
                    <tr>
                        <td>
                            Username
                        </td>
                        <td style={{ maxWidth: "180px", wordWrap: "break-word" }}>
                            {pr.id}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {Lang(`Nama`, LANG(), { en: 'Name' })}
                        </td>
                        <td style={{
                            maxWidth: "180px",
                            wordWrap: "break-word"
                        }}>
                            {pr.name}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Email
                        </td>
                        <td>
                            {pr.email}
                        </td>
                    </tr>
                    {this.props.showButton && <tr>
                        <td>
                            {Lang(`Id Pengawas`, LANG(), { en: 'Proctorer Id' })}
                        </td>
                        <td>
                            {pr.proctor_id}
                        </td>
                    </tr>
                    }
                    <tr>
                        <td>
                            Status
                        </td>
                        <td>
                            {pr.ts > 0 ?
                                <span style={{
                                    color: "green",
                                    fontWeight: "bold",
                                    marginRight: "5px"
                                }}>Online</span> :
                                <span style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    marginRight: "5px"
                                }}>Offline</span>
                            }
                        </td>
                    </tr>
                    {/* <tr>
                        <td>
                            Link Photo
                        </td>
                        <td>
                            <a href={pr.photo}>Link</a>
                        </td>
                    </tr> */}
                    <tr>
                        <td style={{ maxWidth: "100px" }}>
                            {Lang(`Status Validasi Wajah`, LANG(), { en: `Face Validation Status` })}
                        </td>

                        <td>
                            {
                                pr.validation_status === 2 ? <>
                                    <span className={classes.invalid}>Blocked</span>
                                    {
                                        this.props.showButton === true ?
                                            <button
                                                className={[classes.btnOk].join(" ")}
                                                style={{ display: "inline-flex" }}
                                                onClick={() =>
                                                    this.props.onBlocked("Open", pr)
                                                }>
                                                {Lang(`Buka Block`, LANG(), { en: `Open Block` })}
                                            </button> : null
                                    }
                                </> :
                                    pr.validation_status === 1 ?
                                        <>
                                            <span className={classes.valid}>Valid</span>
                                            {
                                                this.props.showButton === true ?
                                                    <button
                                                        className={[classes.btnOk].join(" ")}
                                                        style={{ display: "inline-flex" }}
                                                        onClick={async () => {
                                                            const confirmed = await DecisionAlert("Apakah Anda yakin ingin mengubah status peserta menjadi tidak valid? ");
                                                            if (confirmed) {
                                                                this.props.onValidInvalid("Invalid")
                                                            }
                                                        }}>
                                                        {Lang(`Set Tidak Valid`, LANG(), { en: `Set Invalid` })}
                                                    </button> : null
                                            }
                                        </> :
                                        <>
                                            <span className={classes.invalid}>Invalid</span>
                                            {
                                                this.props.showButton === true ?
                                                    <button
                                                        className={[classes.btnOk].join(" ")}
                                                        style={{ display: "inline-flex" }}
                                                        onClick={async () => {
                                                            const confirmed = await DecisionAlert("Apakah Anda yakin ingin mengubah status peserta menjadi valid ? ");
                                                            if (confirmed) {
                                                                this.props.onValidInvalid("Valid")
                                                            }
                                                        }}
                                                    >
                                                        Set Valid
                                                    </button> : null
                                            }
                                        </>
                            }
                        </td>

                    </tr>
                    {this.props.numberOfScreen !== undefined && DISABLE_SCREEN_NUMBER_DETECTION === false ?
                        <tr>
                            <td>
                                {Lang(`Jumlah Layar Terhubung`, LANG(), { en: `Number Of Screen` })}
                            </td>
                            <td>
                                {this.props.numberOfScreen}&nbsp;
                                {this.props.numberOfScreen === 1 ?
                                    <span className={classes.valid}>{"( Valid )"}</span>
                                    :
                                    <>
                                        <span className={classes.invalid}>{"( Invalid )"}</span>
                                        {this.props.numberOfScreen > 1 && <div
                                            className={classes.invalid}
                                            style={{
                                                width: "150px",
                                                fontSize: ".60rem",
                                                wordWrap: "break-word",
                                                border: "0px solid red"
                                            }}

                                        >{Lang(`*putuskan layar tambahan anda.`, LANG(), { en: `*disconnect your additional screen.` })}
                                        </div>
                                        }
                                        {this.props.numberOfScreen === -1 && <div
                                            className={classes.invalid}
                                            style={{
                                                width: "150px",
                                                fontSize: ".60rem",
                                                wordWrap: "break-word",
                                                border: "0px solid red"
                                            }}
                                        >{Lang(`*aktifkan tangkapan layar/Screen Capture.`, LANG(), { en: `*activate Screen Capture` })}
                                        </div>}
                                    </>

                                }
                            </td>
                        </tr> : null
                    }
                    {
                        this.props.showButton === true ?
                            <tr>
                                <td>
                                    Audio
                                </td>
                                <td>
                                    <div className={classes.audioToggle}>
                                        <label className={classes.switch}>
                                            <input
                                                type="checkbox"
                                                checked={this.state.isAudioOn}
                                                onChange={(e) => { this.toggleAudio(e) }}
                                            />
                                            <span className={`${classes.slider} ${classes.round}`} />
                                        </label>
                                    </div>
                                    {
                                        // this.status()
                                    }
                                </td>
                            </tr>
                            :
                            null
                    }
                    <tr>
                        <td>

                        </td>
                        <td>
                            {
                                this.props.showButton === true && pr.validation_status !== 2 ?
                                    <button
                                        className={[classes.btnHelp].join(" ")}
                                        style={{ display: "inline-flex", marginTop: "5px", paddingInline: "10px" }}
                                        onClick={() =>
                                            this.props.onBlocked("Block", pr)
                                        }>
                                        Block
                                    </button> : null
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
}

ProfileShow.propTypes = {
    handleAudio: PropTypes.func,
    profile: PropTypes.object.isRequired,
    showButton: PropTypes.bool,
    onValidInvalid: PropTypes.func,
    onBlocked: PropTypes.func,
    numberOfScreen: PropTypes.number
}

export default ProfileShow;