import { getAccessToken, getExamId } from "../utils";

export function sendUpdateValidationStatus(_this, proctorerId, participantId, status, info = { camera: "on", screen: "on" }) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify(
                {
                    type: "update-validation",
                    participantId: participantId,
                    proctorerId: proctorerId,
                    status: status,
                    info: info,
                    token: getAccessToken(),
                    examid: getExamId()
                }));
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}

export function sendVideoValidationTime(_this, proctorerId, participantId, startTs, endTs) {
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify(
                {
                    type: "update-video-validation",
                    participantId: participantId,
                    proctorerId: proctorerId,
                    startTs: startTs,
                    endTs: endTs,
                    token: getAccessToken(),
                    examid: getExamId()
                }));
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}
