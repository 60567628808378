import { Info } from "../../libs/logger";

export const __ActionMp__ = function (_this, classes) {
    _this.lastSendImageTs = 0;
    this.sendImageToServerRoutine = (delay = 30000) => {
        Info("send image here")
        if ( Date.now() - _this.lastSendImageTs > delay) {
            Info("send image here 2")
            const use_ts = Date.now();
            _this._action.sendWebCamImageToServer(
                "mobilepairing",
                use_ts,
                // called from store,
                false,
                () => {
                    _this.lastSendImageTs = Date.now();
                }
            )
        }
    }    

    this.startVideoStream = () => {
        const { useFrontCamera } = _this.state;
        const facingMode = useFrontCamera ? 'user' : { exact: 'environment' };

        const faceDefaultConstraints = {
            audio: true,
            video: {
                frameRate: { ideal: 10, max: 10, min: 10 },
                width: { ideal: 480, max: 480 },
                height: { ideal: 360, max: 360 },
                facingMode: facingMode
            }
        }
        navigator.mediaDevices.getUserMedia(
            faceDefaultConstraints
        )
            .then(stream => {
                if (_this.videoRef.current) {
                    _this.videoRef.current.srcObject = stream;
                    _this.stream = stream;
                    _this.setState({
                        mobilePairingStream: stream
                    })
                }
            })
            .catch(error => {
                console.error("Error accessing user media: ", error);
            });
    }

    this.stopVideoStream = () => {
        if (_this.stream) {
            _this.stream.getTracks().forEach(track => track.stop());
        }
    }

    this.switchCamera = () => {
        this.stopVideoStream();
        _this.setState(prevState => ({
            useFrontCamera: !prevState.useFrontCamera,
            isSpinning: true,
            reverseSpin: !prevState.reverseSpin // Toggle spin direction
        }), () => {
            this.startVideoStream();
            setTimeout(() => {
                _this.setState({ isSpinning: false });
            }, 500);
        });
    }

}