import FileWebsocket from "../../libs/FileWebsocket";
import { Debug } from "../../libs/logger";

export const __FileWs__ = function (_this) {
    _this.faceWs = null;
    _this.screenWs = null;
    _this.warningWs = null;
    _this.faceWsMediaRecorder = null;
    _this.screenWsMediaRecorder = null;

    this.fileWsOnMessageReceived = (_this, msg) => {
        msg = JSON.parse(msg)
        Debug(`[filews]`, msg)
    }

    // 5 minutes
    const IMAGEFILEWS_RECONNECT_TIMEOUT = 60500 * 5;

    this.fileWsRoutine = () => {
        // warningws
        if (_this.state.warningWsRegisteredLastTs) {
            if (_this.state.warningWsRegisteredLastTs !== -1 && Date.now() - _this.state.warningWsRegisteredLastTs > IMAGEFILEWS_RECONNECT_TIMEOUT) {
                _this.warningWs.sendRefreshConnection(_this.context.profile.id);
                _this.setState({ warningWsRegisteredLastTs: Date.now() - 10000 })
            }
        }

        // Jika belum masuk waktu ujian, return 
        if (_this.props.mode === "mobile") {
            return;
        }

        Debug("[filews] filews routine")
        // warningws: file handler on warnings
        if (_this.warningWs === null) {
            _this.warningWs = new FileWebsocket(false, _this.context.profile.id, "warning", _this.context.profile.folder_rand, this.fileWsOnMessageReceived, (err) => {
                delete _this.warningWs;
                _this.warningWs = null;
                _this.setState({
                    warningWsRegistered: false,
                    warningWsRegisteredLastTs: -1
                });
            });
        } else {
            _this.setState({
                warningWsRegistered: true,
            });
            if (_this.state.warningWsRegistered === false) {
                _this.setState({
                    warningWsRegisteredLastTs: Date.now()
                })
            }
        }

        // videofilews: file handler on video/webm for face
        if (
            // _this.faceWs === null && 
            _this.state.userMediaStream
            &&
            _this.faceWsMediaRecorder === null
        ) {
            // _this.faceWs = new FileWebsocket(true, _this.context.profile.id, "face", _this.context.profile.folder_rand, null, (err) => {
            //     delete _this.faceWs;
            //     _this.faceWs = null;
            //     _this.setState({ faceWsRegistered: false });
            // });
            if (_this.faceWsMediaRecorder !== null) {
                _this.faceWsMediaRecorder.stop();
            }
            _this.faceWsMediaRecorder = _this._action.recordToWebsocket(_this.faceWs, _this.state.userMediaStream,
                true,
                "face"
            );
        } else {
            _this.setState({ faceWsRegistered: true })
        }

        // videofilews: file handler on video/webm for screen
        if (
            // _this.screenWs === null && 
            _this.props.mode !== "mobile" && _this.state.displayMediaStream
            &&
            _this.screenWsMediaRecorder === null
        ) {
            // _this.screenWs = new FileWebsocket(true, _this.context.profile.id, "screen", _this.context.profile.folder_rand, null, (err) => {
            //     delete _this.screenWs;
            //     _this.screenWs = null;
            //     _this.setState({ screenWsRegistered: false });
            // });
            if (_this.screenWsMediaRecorder !== null) {
                _this.screenWsMediaRecorder.stop();
            }
            _this.screenWsMediaRecorder = _this._action.recordToWebsocket(_this.screenWs, _this.state.displayMediaStream,
                false,
                "screen"
            );
        } else {
            _this.setState({ screenWsRegistered: true });
        }
    }
}