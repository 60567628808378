import * as constants from "./constants.js";
import io from "socket.io-client";
import ProktorWebsocket from "./ProktorWebsocket";
import { Debug, Err, Info } from "../logger.js";

class WebRTCLiveVideo {
  destructor() {
    // This function is called when the object is destroyed
    Debug(`${this.name} is destroyed`);
  }

  constructor(
    iceId,
    registerId,
    registerName,
    videoRouter,
    event,
    isParticipant = true,
    streamLocal = null,
    remoteVideoRef = null,
    onDisconnect = null
  ) {
    this.iceId = iceId;
    this.connectedUserDetails = null;
    this.peerConnection = null;
    this.dataChannel = null;
    this.onDisconnectEvent = onDisconnect;
    this.videoRouter = videoRouter;
    this.savedStats = null;

    if (isParticipant) {
      this.userId = registerId;
    } else {
      this.proctorId = registerId;
    }

    // this.store.setRegisterId(registerId);

    this.localStream = streamLocal;

    if (remoteVideoRef !== null) {
      // this.store.setRemoteVideoRef(remoteVideoRef);
      // remoteVideoRef = document.getElementById(this.registerId + this.userId);
    }

    this.defaultConstraints = {
      audio: false,
      video: true,
    };

    this.ice_configuration = {
      iceServers: [
      ]
    };
    this.ice_configuration = {
      iceServers: [
        {
          urls: `stun:${videoRouter.ip_address}:3478`,
          username: `${videoRouter.username}`,
          credential: `${videoRouter.password}`
        },
        {
          urls: `turn:${videoRouter.ip_address}:3478`,
          username: `${videoRouter.username}`,
          credential: `${videoRouter.password}`
        }
      ]
    }
    Debug("ice configuration = ", this.ice_configuration);

    try {
      // nginx will parse the value of the id, and route it to ${id}.ice.amanin.id,
      // with path value is /socket.io which is default value of socket.io
      this.socket = io(`/?id=${this.iceId}&ipaddress=${videoRouter.ip_address}`, {
        path: "/socket.io"
        // path: "/socket.io/1.1.15"
      });

      // this.socket = io("/", {
      //   path: `/ice-${this.iceId}`
      // });
    } catch (err) {
      Debug("ERROR IS CAPTURED HERE");
    }
    this.wss = new ProktorWebsocket(
      this.socket,
      registerId,
      registerName,
      isParticipant,
      event
    );

    Debug("ice configuration=", this.ice_configuration);

    this.socket.on("pong", (data) => {
      Debug("PONG", data);
    })
    this.socket.on("pre-offer", (data) => {
      Debug("[" + this.userId + "] recv pre-offer proctorer->participant", data);
      this.handlePreOffer(data);
    });

    this.socket.on("pre-offer-failed", (data) => {
      Debug("XXXXXXXX LAST PREOFFER FAILED");
      setTimeout(() => {
        this.wss.close();
        this.socket.disconnect();
        this.onDisconnectEvent("sendPreOffer Failed");
      }, 5000);
    });

    this.socket.on("pre-offer-answer", (data) => {
      Debug("[" + this.proctorId + "] recv pre-offer answer participant->proctorer", data);
      this.handlePreOfferAnswer(data);
    });

    this.socket.on("user-hanged-up", () => {
      Debug("[" + this.userId + "] user hang up");
      this.handleConnectedUserHangedUp();
    });
    this.socket.on("callee-socket-id", (data) => {
      Debug("[] callee-socket-id, data=", data);
      this.fillCalleeId(data["calleeSocketId"]);
    });

    this.socket.on("webRTC-signaling", (data) => {
      // Debug("[webrtc-signaling]", data);
      const { toId, fromId } = data;
      switch (data.type) {
        case constants.webRTCSignaling.OFFER:
          this.handleWebRTCOffer(toId, fromId, data);
          break;
        case constants.webRTCSignaling.ANSWER:
          this.handleWebRTCAnswer(toId, fromId, data);
          break;
        case constants.webRTCSignaling.ICE_CANDIDATE:
          this.handleWebRTCCandidate(toId, fromId, data);
          break;
        default:
          return;
      }
    });

    this.socket.on("disconnect", () => {
      // Debug("DISCONNECTED IS RECEIVED");
      this.socket.disconnect();
      if (this.onDisconnectEvent !== null) {
        this.onDisconnectEvent("diconnect event captured");
      } else {
        Debug("onDisconnectEvent is not set");
      }
    });
    this.socket.on("connect_error", () => {
      // Debug("ERROR IS RECEIVED");
      this.socket.disconnect();
      this.onDisconnectEvent("connect_error event captured");
    });
    // this.socket.on("connecting", () => {
    //   Debug("ERROR IS RECEIVED");
    // });
    // this.socket.on("error", () => {
    //   Debug("ERROR IS RECEIVED");
    // });
  }

  // getLocalPreview() {
  //   navigator.mediaDevices
  //     .getUserMedia(this.defaultConstraints)
  //     // .getDisplayMedia(defaultConstraints)
  //     .then((stream) => {
  //       //ui.updateLocalVideo(stream);
  //       // //ui.showVideoCallButtons();
  //       this.store.setCallState(constants.callState.CALL_AVAILABLE);
  //       this.store.setLocalStream(stream);
  //     })
  //     .catch((err) => {
  //       Debug("error occured when trying to get an access to camera");
  //       Debug(err);
  //     });
  // }

  getVideoRouter() {
    return this.videoRouter;
  }

  getSavedStats() {
    if (!this.savedStats) {
      return null
    }

    return this.savedStats.length > 0 ? this.savedStats : null;
  }

  getStats() {
    if (this.peerConection === undefined) {
      this.savedStats = [];
      return;
    }
    let r = [];
    this.peerConection?.getStats().then(stats => {
      stats.forEach(report => {
        r.push(report);
      });
      this.savedStats = r;
    });
  }

  close() {
    Debug("close webrtc, readyState=", this.socket);
    this.socket.disconnect();
    this.socket.close();
    if (this.peerConection !== null) {
      Debug("CLOSE PEER CONNECTION");
      if (this.peerConection !== undefined) {
        this.peerConection.close();
      }
      this.peerConection = null;
      delete this.peerConection;
    }
  }

  createPeerConnection(isReceivingMedia = true) {
    Debug("CREATE PEER CONNECTION");
    Debug(this.ice_configuration);
    this.peerConection = new RTCPeerConnection(this.ice_configuration);
    Debug(this.peerConection);
    this.dataChannel = this.peerConection.createDataChannel("chat");

    this.peerConection.ondatachannel = (event) => {
      const dataChannel = event.channel;

      dataChannel.onopen = () => {
        Debug(
          "[" +
          this.userId +
          "] peer connection is ready to receive data channel messages"
        );
        Debug("THIS IS ON OPEN");
        this.socket.disconnect();
        if (this.onDisconnectEvent !== null) {
          this.onDisconnectEvent("established");
        }
      };

      dataChannel.onmessage = (event) => {
        // const message = JSON.parse(event.data);
      };
    };

    this.peerConection.onicecandidate = (event) => {
      Debug(
        "[" + this.userId + "] connectedUserDetails=",
        this.connectedUserDetails
      );
      Debug(
        "[" + this.userId + "][onicecandidate] send from " +
        this.connectedUserDetails.fromId
        + "to ",
        this.connectedUserDetails.toId
      );
      if (event.candidate) {
        // send our ice candidates to other peer
        this.wss.sendDataUsingWebRTCSignaling({
          // connectedUserSocketId: this.connectedUserDetails.socketId,
          type: constants.webRTCSignaling.ICE_CANDIDATE,
          candidate: event.candidate,
          fromId: this.connectedUserDetails.fromId,
          toId: this.connectedUserDetails.toId
        });
      }
    };

    this.peerConection.onconnectionstatechange = (event) => {
      if (this.peerConection === undefined) {
        return;
      }
      Debug("onconnection state change state=", this.peerConection.connectionState);
      if (this.peerConection.connectionState === "connected") {
        // TODO document why this block is empty
        Debug("PEER CONNECTION IS CONNECTED");
      } else if (
        this.peerConection.connectionState === "disconnected" ||
        this.peerConection.connectionState === "failed") {
        this.peerConection.close();
        this.peerConection = null;
        delete this.peerConection;

        // do not change "webrtc disconnected", it's param for the callback
        this.onDisconnectEvent("webrtc disconnected");
      }
    };

    if (isReceivingMedia === true) {
      Debug(
        "[" +
        this.proctorId +
        "][onPeerConnection] THIS SIDE IS RECEIVING REMOTE STREAM"
      );

      // receiving tracks
      const remoteStream = new MediaStream();
      // this.store.setRemoteStream(remoteStream);
      this.remoteStream = remoteStream;
      let remoteVideo = null;
      // if (this.store.getState().remoteVideoRef !== undefined) {
      //   remoteVideo = this.store.getState().remoteVideoRef.current;
      // }
      // Debug("register id = ", this.store.getState().registerId);
      remoteVideo = document.getElementById(this.proctorId);
      Debug(remoteVideo);
      if (remoteVideo !== null) {
        Debug(
          "[" +
          this.proctorId +
          "] remote video is not null, assign RemoteStream",
          remoteVideo
        );
        remoteVideo.srcObject = remoteStream;
      }
      this.peerConection.ontrack = (event) => {
        Debug(`[liveVideo][${this.userId}][ontrack] add Track `, event);
        remoteStream.addTrack(event.track);
      };
    } else {
      Debug(
        "[" +
        this.userId +
        "][onPeerConnection] THIS SIDE DID NOT RECEIVE REMOTE MEDIA"
      );
    }

    // add our stream to peer connection
    Debug(
      "[" + this.userId + "] connected user = ",
      this.connectedUserDetails
    );

    const localStream = this.localStream;

    if (localStream !== null) {
      for (const track of localStream.getTracks()) {
        Debug(`[liveVideo]*** `, track, localStream)
        this.peerConection.addTrack(track, localStream);
        //   peerConection.addTrack(track);
      }
    } else {
      Debug("[" + this.proctorId + "] localStream is empty");
    }
  }

  fillCalleeId(calleePersonalCode) {
    Debug("[" + this.proctorId + "] fill callee id", calleePersonalCode);
    Debug(this.connectedUserDetails);
    this.connectedUserDetails["socketId"] = calleePersonalCode;
  }

  sendPing(toId) {
    Debug("Ping to ", toId);
    this.wss.sendPing(toId);
  }

  sendPreOffer(callType, userId, proctorId) {
    Debug("sendPreOffer");
    this.connectedUserDetails = {
      callType,
      userId: userId,
      proctorId: proctorId,
      fromId: proctorId,
      toId: userId,
    };

    this.userId = userId;
    const data = {
      callType,
      userId,
      proctorId,
    };
    this.wss.sendPreOffer(data);
  }

  handlePreOffer(data) {
    Debug("participant receive preoffer do createPeerConnection, sendPreOfferAnswer")
    const { callType, proctorId, userId } = data;

    this.connectedUserDetails = {
      // socketId: callerSocketId,
      callType,
      userId: userId,
      proctorId: proctorId,

      fromId: userId,
      toId: proctorId,
    };

    this.createPeerConnection(true);

    this.sendPreOfferAnswer(
      constants.preOfferAnswer.CALL_ACCEPTED,
      proctorId
    );

    setTimeout(() => {
      this.sendWebRTCOffer(this.userId, proctorId);
    }, 1000);
  }

  sendPreOfferAnswer(preOfferAnswer, proctorId) {
    Debug("sendPreOfferanswer, answer=",
      preOfferAnswer, ", proctorId=", proctorId);
    // const socketId = callerSocketId
    //   ? callerSocketId
    //   : this.connectedUserDetails.socketId;
    // Debug("use socketId=", socketId);
    // Debug("wss = ", this.wss);
    const data = {
      proctorId: proctorId,
      preOfferAnswer: preOfferAnswer,
    };
    this.wss.sendPreOfferAnswer(data);
  }

  handlePreOfferAnswer(data) {
    const { preOfferAnswer } = data;
    Debug("[" + this.proctorId + "] PREOFFER ANSWER = ", preOfferAnswer);
    if (preOfferAnswer === "CALLEE_NOT_FOUND") {
      Debug("[" + this.proctorId + "] Callee is not found, stop here");
    } else {
      // Debug("[" + this.proctorId + "] socketId=", this.socket.id);
      Debug(`[${this.proctorId}], userId=${this.userId} create Peer Connection`);
      this.createPeerConnection(true);
    }
  }

  async sendWebRTCOffer(fromId, toId) {
    // Debug("[create-offer] ", this.peerConection);
    const offer = await this.peerConection.createOffer();
    await this.peerConection.setLocalDescription(offer);
    // Debug("[send] sendWebRTCOffer, offer=", JSON.stringify(offer));
    this.wss.sendDataUsingWebRTCSignaling({
      // connectedUserSocketId: this.connectedUserDetails.socketId,
      type: constants.webRTCSignaling.OFFER,
      offer: offer,
      fromId: fromId,
      toId: toId
    });
  }

  async handleWebRTCOffer(fromId, toId, data) {
    // Debug(
    //   "[handleWebRTCOffer] this.peerConnection = ",
    //   this.peerConnection
    // );
    if (this.peerConection === undefined) {
      Debug("[" + this.userId + "] peerConnection is not created");
      return;
    }
    await this.peerConection.setRemoteDescription(data.offer);
    const answer = await this.peerConection.createAnswer();
    await this.peerConection.setLocalDescription(answer);
    // Debug("[sendWebRTCAnswer]");
    this.wss.sendDataUsingWebRTCSignaling({
      // connectedUserSocketId: this.connectedUserDetails.socketId,
      type: constants.webRTCSignaling.ANSWER,
      answer: answer,
      fromId: fromId,
      toId: toId
    });
  }

  async handleWebRTCAnswer(fromId, toId, data) {
    if (this.peerConection === undefined) {
      Debug("[" + this.userId + "] peerConnection is not created");
      return;
    }

    // Debug("[handleWebRTCAnswer] ");
    await this.peerConection.setRemoteDescription(data.answer);
  }

  async handleWebRTCCandidate(fromId, toId, data) {
    if (this.peerConection === undefined) {
      Debug("[" + this.userId + "] peerConnection is not created");
      return;
    }

    // Debug("[handleWebRTCCandidate] ", data.candidate);
    try {
      await this.peerConection.addIceCandidate(data.candidate);
    } catch (err) {
      Err("error occured when trying to add received ice candidate", err);
    }
  }
}

export default WebRTCLiveVideo;
