import client from './itb2.png';
import itb from './logo_itb.png';

import { LANG } from '../../libs/utils';

import guide_extension from './troubleshootings/Extension_ troubleshooting_ guide.png';
import guide_exam_org_1 from './troubleshootings/Guide_for_exam _organizer_1.png';
import guide_exam_org_2 from './troubleshootings/Guide_for_exam _organizer_2.png';
import guide_exam_participant from './troubleshootings/Important_Guidelines_for_Exam_Participants.png';
import guide_macbook_camera from './troubleshootings/Macbook_camera_troubleshooting_guide.png';
import guide_macbook_microphone from './troubleshootings/Macbook_microphone_troubleshooting_guide.png';
import guide_macbook_screen_audio from './troubleshootings/Macbook_screen&audio_recording_troubleshooting_guide.png';
import guide_exam_issues from './troubleshootings/Possible_Issues_during_Online_Exam.png';


let extension;
let exam_org_1;
let exam_org_2;
let exam_participant;
let macbook_camera;
let macbook_microphone;
let macbook_screen_audio;
let exam_issues;

switch (LANG()) {
    case 'id':
        exam_org_1 = "";
        exam_org_2 = "";

        extension = "https://amanin.id/troubleshootings_guidelines/ekstensi%20troubleshooting%20guide.png";
        exam_participant = "https://amanin.id/troubleshootings_guidelines/guideline_participants.png";
        macbook_camera = "https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_camera_problems.png";
        macbook_microphone = "https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_microphone_problems.png";
        macbook_screen_audio = "https://amanin.id/troubleshootings_guidelines/troubleshooting_macbook_screen_audio_problems.png";
        exam_issues = "https://amanin.id/troubleshootings_guidelines/guideline_problems_in_exams.png";

        break;
    default:
        exam_org_1 = guide_exam_org_1;
        exam_org_2 = guide_exam_org_2;

        extension = guide_extension;
        exam_participant = guide_exam_participant;
        macbook_camera = guide_macbook_camera;
        macbook_microphone = guide_macbook_microphone;
        macbook_screen_audio = guide_macbook_screen_audio;
        exam_issues = guide_exam_issues;
}

export {
    client,
    itb,
    extension,
    exam_org_1,
    exam_org_2,
    exam_participant,
    macbook_camera,
    macbook_microphone,
    macbook_screen_audio,
    exam_issues,
}
