import CreateExamEMSModal from "../../components/CreateExamEMSModal";
import LoginEMSModal from "../../components/LoginEMSModal";
import UserManual from "../../components/UserManual";
import { Lang } from "../../libs/Language";
import help from "../../assets/imgs/helpcolor.png"
import Loading from "../../components/Loading";

export const __Component__ = function (_this, classes) {
    this.generateBackground = () => {
        return <div className={classes.circle}>
            <div className={classes.one}>
            </div>
            <div className={classes.two}>
            </div>
        </div>
    }

    this.renderBg = () => {
        let fractal = [];
        for (let i = 0; i < 50; i++) {
            if (i % 3 !== 0) {
                continue;
            }
            fractal.push(<div
                key={i}
                style={{
                    top: `${5 + Math.ceil(Math.random() * 80)}%`,
                    left: `${5 + Math.ceil(Math.random() * 80)}%`,
                    rotate: `${Math.ceil(Math.random() * 900)}deg`,
                    width: `${50 + Math.ceil(Math.random() * 200)}px`,
                    height: `${50 + Math.ceil(Math.random() * 200)}px`,
                    backgroundColor: `rgba(
                        ${Math.ceil(Math.random() * 255)},
                        ${Math.ceil(Math.random() * 255)},
                    ${Math.ceil(Math.random() * 255)},0.05)`,
                    boxShadow: `1px 1px 10px rgba(128,128,255,0.1)`

                }}
            ></div>)
        }
        return <div className={[
            classes.container_bg,
            // classes.FadeInPage1s
        ].join("")}>
            {fractal}
        </div>
    }

    this.generateCreateExamModal = () => {
        // return _this.state.showCreateExamDialog && 
        return <div className={[
            classes.cemodal
        ].join(" ")}
            style={{
                transform: _this.state.showCreateExamDialog === false ? "scale(0,0)" : "scale(1,1)",
                transformOrigin: "left top"
            }}
        >
            {/* <img src={close} /> */}
            {/* <div>Model Ujian yang ingin anda buat ?</div> */}
            <span
                onClick={() => {
                    _this.setState({
                        showCreateExamModal: true,
                        showCreateExamModalMode: "instant",
                        showCreateExamDialog: false
                    })
                }}
            >Buat Ujian Instan</span>
            <span
                onClick={() => {
                    _this.setState({
                        showCreateExamModal: true,
                        showCreateExamModalMode: "scheduled",
                        showCreateExamDialog: false
                    })
                }}

            >Buat Ujian Terjadwal</span>
            <div>
                <div onClick={() => {
                    _this.setState({ showCreateExamDialog: false })
                }} className={[classes.btnOk, classes.cl].join(" ")}>
                    Tutup
                </div>
            </div>
        </div>
    }

    this.renderUserManual = () => {
        return <div className={classes.container_modal}>
            <UserManual
                onClose={() => {
                    _this.setState({ showUserManual: "" })
                }}
                src={_this.state.showUserManual === "participant" ?
                    "https://amanin.id/pdf/ProktorFirst-Peserta.pdf" :
                    _this.state.showUserManual === "proctorer" ?
                        "https://amanin.id/pdf/ProktorFirst-Pengawas.pdf" :
                        "https://amanin.id/pdf/ai_proctoring_management_1.1.pdf"}
            />
        </div>
    }

    this.renderLoginModal = () => {
        return <div className={classes.container_modal}>
            <div className={classes.container_modal_content}>
                <LoginEMSModal
                    onLogin={(id, password) => {
                        _this._action.logingIn(id, password);
                    }}

                    onLoginSSO={() => {
                        window.location.href = _this.state.authURL;
                    }}
                    onFormGoogleAuth={_this._action.onFormGoogleAuth}
                    onFormFacebookAuth={_this._action.onFormFacebookAuth}
                    // onFormGithubAuth={_this.onFormGithubAuth}
                    onClose={() => {
                        _this.setState({ showLoginModal: false })
                    }}
                />
            </div>
        </div>
    }

    this.renderCreateExamModal = () => {
        return <div className={classes.container_modal}>
            <div className={classes.container_modal_content}>
                <CreateExamEMSModal
                    userId={_this.state.userProfile?.id}
                    id={_this.state.userProfile?.id}
                    mode={_this.state.showCreateExamModalMode}
                    onCreate={(data) => {
                        _this._action.handleCreateExam(data);
                    }}
                    data={_this.state.userProfile}
                    onClose={() => {
                        _this.setState({
                            showCreateExamModal: false,
                            showCreateExamDialog: false

                        })
                    }}
                />
            </div>
        </div>
    }

    this.renderHelpUserManual = () => {
        return <div className={classes.help}>
            <div
                className={classes.help_button}
                onClick={() => {
                    _this.setState({
                        showUserManual: "participant"
                    })
                }}
            >
                <img alt="help" src={help} height="25px" />
                {`${Lang('User Manual', _this.state.lang)} - ${Lang('Peserta', _this.state.lang)}`}
            </div>

            <div
                className={classes.help_button}
                onClick={() => {
                    if (_this.state.userProfile && _this.state.userProfile.usertype === "proctorer") {
                        _this.setState({
                            showUserManual: "proctorer"
                        })
                    } else {
                        alert("Anda harus Login sebagai pengawas/dosen terlebih dahulu.")
                    }
                }}
            >
                <img alt="help" src={help} height="25px" />
                {`${Lang('User Manual', _this.state.lang)} - ${Lang('Pengawas', _this.state.lang)}`}
            </div>
            {/* <div
                className={classes.help_button}
                onClick={() => {
                    if (_this.state.userProfile && _this.state.userProfile.usertype === "proctorer") {
                        _this.setState({
                            showUserManual: "management"
                        })
                    } else {
                        alert("Anda harus Login sebagai pengawas/dosen terlebih dahulu.")
                    }
                }}
            >
                <img alt="help" src={help} height="25px" />
                {`${Lang('User Manual', _this.state.lang)} - ${Lang('Management', _this.state.lang)}`}
            </div> */}
        </div>
    }

    this.renderLoading = () => {
        return <div className={classes.container_loading}>
            <Loading />
            <span>
                {_this.state.showLoading}
            </span>
        </div>
    }
}