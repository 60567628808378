import classes from "./LeftMenu.module.scss";
import close from "../../../assets/imgs/close.png";

export const renderMenu = (_this) => {
    let el = [];
    for (let m in _this.menus) {
        el.push(<div
            title={_this.menus[m].title}
            key={m}
            className={[
                classes.xmenu,
                _this.state.chosenMenu === m ? classes.chosen : null
            ].join(" ")}
            onClick={() => {
                _this.setState({ chosenMenu: m });
            }}
        >
            <img alt="menu" width={"30px"} src={_this.menus[m].img} />
        </div>)
    }
    return <div className={classes.menu}>
        <div
            className={classes.xclose}
            onClick={() => _this.props.onClose()}
        >
            <img alt="close" src={close} height={"30px"} />
            <span>close</span>
        </div>
        {el}
    </div>;
}