import React from "react";
import classes from "./CoolButton.module.scss";

class CoolButton extends React.Component {
    render() {
        const { marginTop, marginLeft, marginRight, gradient, fontColor, fontWeight, fontSize, onClick, children } = this.props;

        const customStyle = {
            background: gradient || "linear-gradient(135deg, #4BC0C8, #7c97ff)",
            fontWeight: fontWeight || "bold",
            fontSize: fontSize || "inherit",
            color: fontColor || "rgb(ff, 73, 82)",
            marginTop: marginTop || "0px",
            marginLeft: marginLeft || "0px",
            marginRight: marginRight || "0px"
        };

        return <div className={classes.container}>
            <div
                className={classes.container_btn}
                style={customStyle}
                onClick={onClick}
            >
                {children}
            </div>
        </div>
    }
}

export default CoolButton;