import React from "react";
import classes from "./WebCam.module.scss";
import amanin from "../assets/imgs/logo512.png";
import PropTypes from "prop-types";

class WebCam extends React.Component {
    constructor(props) {
        super(props);
        // Debug(this.props); 
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        // Debug("WebCam stream = ", this.props.stream);
        if (this.videoRef.current !== null) {
            this.videoRef.current.srcObject = this.props.stream;
        }
    }

    renderExam(width, height) {
        return <div className={classes.container_v}>
            <div className={classes.container_v_video}                        >
                <video
                    ref={this.videoRef}
                    autoPlay
                    muted
                    height="50px"
                    id={this.props.videoId !== null ? this.props.videoId : null}
                />
            </div>
        </div>
    }

    renderExamMobile(width, height) {
        return <div className={[classes.container_v].join(" ")}>
            <div className={[classes.container_v_video, classes.mobile].join(" ")}                        >
                <video
                    ref={this.videoRef}
                    autoPlay
                    muted
                    height="40px"
                    id={this.props.videoId !== null ? this.props.videoId : null}
                />
            </div>
        </div>
    }

    renderAI(width, height) {
        return <div
            className={classes.container_v}
            style={{
                width: `${width}px`,
                height: `${height}px`
            }}
        >
            <div className={classes.container_v_ai}>
                <img
                    width={this.props.smallMode === false ? "40px" : "20px"}
                    src={amanin} alt="amanin" />
                <span
                    style={{
                        fontSize: this.props.smallMode === false ? ".6rem" : ".5rem"
                    }}>
                    Artificial Intelligence
                </span>
            </div>
        </div>
    }

    renderOthers(width, height) {
        return <div className={classes.container_v}>
            <div className={classes.container_v_video}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                    // border: "2px solid blue"
                }}
            >
                <video
                    ref={this.videoRef}
                    autoPlay
                    muted
                    width={`${width}px`}
                    height={`${height}px`}
                    id={this.props.videoId !== null ? this.props.videoId : null}
                />
            </div>
        </div>
    }

    render() {
        let width = this.props.isFullscreen === true ? "272" : "132";
        let height = this.props.isFullscreen === true ? "220" : "107";
        if (this.props.smallMode === true) {
            width = "70";
            height = "70";
        }
        let component = this.renderOthers(width, height)
        if (this.props.type === "Exam") {
            component = this.renderExam(width, height)
        } else if (this.props.type === "ExamMobile") {
            component = this.renderExamMobile(width, height)
        } else if (this.props.type === "AI") {
            component = this.renderAI(width, height)
        }

        return <div className={[
            classes.container,
            this.props.isFullscreen === true ?
                classes.fullscreen : null
        ].join(" ")
        }>
            {component}
        </div>
    }
}

WebCam.propTypes = {
    stream: PropTypes.object,
    isFullscreen: PropTypes.bool,
    smallMode: PropTypes.bool,
    type: PropTypes.string,
    videoId: PropTypes.string,

}

export default WebCam;