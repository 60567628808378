import { fetchJSONMessageConf } from "./proctor_utils"

export const fetchLoginOAUTH = async (body, error_handler) => {
    return fetchJSONMessageConf('login-oauth', { ...body }, error_handler)
}

export const fetchLogin = async (examId, id, password, as, error_handler) => {
    console.log("FETCH LOGIN", examId, id, password, as);
    return fetchJSONMessageConf('login', {
        'exam-id': examId,
        'id': id,
        'password': password,
        'as': as
    }, error_handler)
}
