import React from "react";
import classes from "./CreateExamEMSModal.module.scss";
import { DEFAULT_DURATION_INSTANT_EXAM, formatDate, formatReverseDate, formattedDate, formattedTime, generateAlphanumeric, getPreparationTime } from "../libs/proctor_utils_ext";
import CheckBox from "./CheckBox";

// var console = {};
// console.log = function () { };
// console.error = function () { };

class CreateExamEMSModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newTabExam: false,
            validByDefault: true,
            instantExam: this.props.mode === "instant" ? true : false,
            formData: {
                id: generateAlphanumeric(),
                // editor_id: this.props?.userId,
                editor_id: this.props?.data.id,
                title: "",
                title_2: "",
                exam_date: formatDate(new Date()),
                exam_time: formattedTime(),

                // duration will not be used, we will use duration from the backend
                // duration: 60 * DEFAULT_DURATION_INSTANT_EXAM,
                duration: this.props?.data.package_name === 'Freedom' ? (60 * DEFAULT_DURATION_INSTANT_EXAM) : this.props?.data.max_duration,

                // duration: this.props?.data?.max_duration,
                // preparation: Math.floor(new Date().getTime() / 1000),
                preparation: 0,
                url: "https://forms.gle/sedD9k7rQco7Yk7u9",
                // "https://lms.telkomuniversity.ac.id/login/index.php",
                url_in_frame: 1,

                // pperp: 100, // Banyaknya peserta
                pperp: this.props?.data.package_name === 'Freedom' ? 100 : this.props?.data.max_participant, // Banyaknya peserta

                video_resolution: "640x480@15fps",
                video_bitrate: "128kbps",
                interprocess_ai_delay: 5,
                redirect_on_preparation: 1,
                valid_by_default: 1,
                time_warning: 5,
                face_detection_on_validation_thresh: 0.6,
                face_detection_on_exam_thresh: 0.6,
                primary_logo_url: "https://amanin.id/logos/amanin.png",
                secondary_logo_url: "http://amanin.id/logos/amanin.png",
                show_warning: 0,
                ice_number: 1,
                proctorer_pass: "amanin@2023#",
                use_extension: 0,
                is_running: 1,
            },
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "exam_date") {
            this.setState({ formData: { ...this.state.formData, [name]: formatDate(value) } });
        } else {
            this.setState({ formData: { ...this.state.formData, [name]: value } });
        }
    };

    handleUpdateTabExam(newTabExam) {
        let formData = this.state.formData;
        if (newTabExam === true) {
            formData.use_extension = 1
            formData.url_in_frame = 0
            this.setState({
                formData: formData,
                newTabExam: newTabExam
            })
        } else {
            formData.use_extension = 0
            formData.url_in_frame = 1
            this.setState({
                formData: formData,
                newTabExam: newTabExam
            })
        }
    }

    handleUpdateValidByDefault(validByDefault) {
        let formData = this.state.formData;
        formData.valid_by_default = validByDefault;
        this.setState({
            formData: formData,
            validByDefault: validByDefault
        })
    }

    handleCreateExam() {
        let data = this.state.formData;
        if (!this.state.instantExam) {
            data.preparation = getPreparationTime(this.state.formData.exam_date, this.state.formData.exam_time);
        }
        // console.log("DATA : ", data)
        this.props.onCreate(data)
    }

    componentDidMount() {
        this.handleUpdateTabExam(this.state.newTabExam);
        console.log("Data : ", this.props?.data)
    }

    disabled = () => {
        return this.props?.data?.package_name !== 'Freedom';
    }

    render() {
        return <div className={classes.container}>
            <div className={classes.container_title}>
                Buat Ujian
            </div>
            {/* <div className={classes.container_login}> */}
            <div className={classes.container_exam_create}>
                <div className={classes.container_exam_create_c}>
                    <div className={classes.title}>
                        <span>Judul Ujian</span>
                    </div>
                    <input
                        name={"title"}
                        type={"text"}
                        value={this.state.formData.title}
                        onChange={this.handleChange}
                        required
                        placeholder="ex. Ujian Kalkulus"
                    />
                </div>
                <div className={classes.container_exam_create_c}>
                    <div className={classes.title}>
                        <span>URL Ujian</span>
                    </div>
                    <input
                        name={"url"}
                        type={"text"}
                        value={this.state.formData.url}
                        onChange={this.handleChange}
                        required
                        placeholder="ex. Link Soal Anda/LMS Anda"
                    />
                </div>

                {!this.state.instantExam && <>
                    <div className={classes.container_exam_create_c}>
                        <div className={classes.title}>
                            <span>Tanggal Ujian</span>
                        </div>
                        <input
                            name={"exam_date"}
                            type={"date"}
                            value={formatReverseDate(this.state.formData.exam_date)}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={classes.container_exam_create_c}>
                        <div className={classes.title}>
                            <span>Waktu Ujian</span>
                        </div>
                        <input
                            name={"exam_time"}
                            type={"time"}
                            value={this.state.formData.exam_time}
                            onChange={this.handleChange}
                        />
                    </div>

                    <div className={classes.container_exam_create_c}>
                        <div className={classes.title}>
                            <span>Durasi (dalam menit)</span>
                        </div>
                        <input
                            name={"duration"}
                            type={"number"}
                            min="0"
                            max="1440"
                            step="10"
                            value={this.state.formData.duration}
                            onChange={this.handleChange}
                            disabled={this.disabled()}
                        />
                    </div>
                </>
                }
                {/* <div className={classes.container_exam_create_c}>
                    <div className={classes.checkbox}>
                        <CheckBox
                            id="instantExam"
                            name="instantExam"
                            onChange={() => {
                                this.setState({ instantExam: !this.state.instantExam })
                            }}
                            checked={this.state.instantExam}
                        />
                        <span className={classes.statement}>
                            Instant Exam
                        </span>
                    </div>
                </div> */}
                <div className={classes.container_exam_create_c}>
                    <div className={classes.checkbox}>
                        <CheckBox
                            id="useIFrame"
                            name="use_iframe"
                            onChange={() => {
                                // this.setState({ examDirect: !this.state.examDirect })
                                const newTabExam = !this.state.newTabExam;
                                this.handleUpdateTabExam(newTabExam)
                            }}
                            checked={this.state.newTabExam}
                        />
                        <span className={classes.statement}>
                            Membuka Laman Ujian Ke Tab Baru
                        </span>
                    </div>
                </div>
                <div className={classes.container_exam_create_c}>
                    <div className={classes.checkbox}>
                        <CheckBox
                            id="validByDefault"
                            name="valid_by_default"

                            onChange={() => {
                                const validByDefault = !this.state.validByDefault;
                                this.handleUpdateValidByDefault(validByDefault)
                            }}
                            checked={this.state.validByDefault}
                        />
                        <span className={classes.statement}>
                            Saya kenal para peserta, tidak perlu lakukan validasi wajah.
                        </span>
                    </div>
                </div>

                <div className={classes.container_exam_create_c}>
                    <div className={[classes.btnOk, classes.btnCreate].join(" ")}

                        onClick={() => {
                            // this.props.onCreate(this.state.formData)
                            this.handleCreateExam()
                        }}
                    >
                        Buat Ujian
                    </div>
                </div>
            </div>

            {/* <div className={classes.container_sep}>
                <div>
                </div>
                <div></div>
            </div> */}
            <div className={classes.container_close}>
                <div className={classes.btnReload}
                    onClick={() => {
                        this.props.onClose()
                    }}
                >
                    Tutup
                </div>
            </div>
        </div>
    }
}

export default CreateExamEMSModal;