import React from "react";
import classes from "./XButton.module.scss";

class XButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className={classes.container}
            style={{
                width: "15px",
                height: "15px",
                marginLeft: "5px"

            }}
            onClick={() => {this.props.onClick()}}
        >
            <div className={classes.container_1} />
            <div className={classes.container_2} />
        </div>
    }
}

export default XButton;