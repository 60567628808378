import React, { createRef } from 'react'
import classes from "./FormCommandChat.module.scss";
import PropTypes from "prop-types";

class FormCommandChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {
                command: props.data?.command.replace(/^\/+/, '') || "",
                description: props.data?.description || "",
                response: props.data?.response || "",
                topic: props.data?.topic || "",
                role: props.data?.role || 0,
                id: props.data?.id || "",
            },
        }
        // this.roleOptions = ["admin", "all"];
        this.roleOptions = [
            { name: 'all', value: 0 },
            { name: 'admin', value: 1 },
        ];

        this.onClose = this.props.onClose

        this.data = this.props.data;
        // this.packages = this.props.packages;
        this.formRef = createRef(); // Create a ref for the form

    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({ formData: { ...prevState.formData, [name]: value } }));
    };

    handleSubmit = () => {
        const form = this.formRef.current;

        if (form.checkValidity()) {
            this.props.onCreate(this.state.formData);
        } else {
            form.reportValidity(); // Show native validation messages
        }
    };

    readonly = () => {
        if (this.data !== null) {
            return {
                disabled: true
            };
        } else {
            return {};
        }
    };


    render() {
        console.log("Data : ", this.state.formData)
        return <div className={[classes.form_container, classes.ascroll].join(" ")}>
            {/* <div className={classes.form_container_title}>
                {this.state.title}
            </div> */}
            <div className={[classes.form_container_c].join(" ")}>
                <div className={classes.form_container_c_form}>
                    <form ref={this.formRef}>
                        <div className={classes.inputfield}>
                            <span>Topic</span>
                            <input type="text"
                                className={classes.input}
                                name={"topic"}
                                value={this.state.formData.topic}
                                onChange={this.handleChange}
                                placeholder={"ex. Nilai dan Evaluasi Ujian"}
                                required
                            />
                        </div>
                        <div className={classes.inputfield}>
                            <span>Command</span>
                            <input type="text"
                                className={classes.input}
                                name={"command"}
                                value={this.state.formData.command}
                                onChange={this.handleChange}
                                placeholder={"ex. do_not_chat"}
                                required
                            />
                        </div>
                        <div className={classes.inputfield}>
                            <span>Description</span>
                            <input type="text"
                                className={classes.input}
                                name={"description"}
                                value={this.state.formData.description}
                                onChange={this.handleChange}
                                placeholder={"ex. Deskripsi penggunaan command chat"}
                                required
                            />
                        </div>
                        <div className={classes.inputfield}>
                            <span>Response</span>
                            <textarea
                                className={classes.textarea}
                                name="response"
                                value={this.state.formData.response}
                                onChange={this.handleChange}
                                rows="7"
                                placeholder={"ex. Template Jawaban yang sudah disediakan"}
                                required
                            />
                        </div>

                        <div className={classes.inputfield}>
                            <span>Role</span>
                            <div className={classes.custom_select}>
                                <select
                                    name={"role"}
                                    value={this.state.formData.role}
                                    onChange={this.handleChange} >
                                    {this.roleOptions.map((role) => (
                                        <option
                                            key={role.name}
                                            value={parseInt(role.value)}
                                        >
                                            {role.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </form>

                    <div
                        className={classes.btnOk}
                        onClick={() => {
                            this.handleSubmit()
                        }}
                    >
                        {this.props.titleButton}
                    </div>
                </div>

            </div>

            <div className={classes.container_close}>
                <div className={classes.btnReload}
                    onClick={() => {
                        this.onClose();
                    }}
                >
                    Close
                </div>
            </div>
        </div>
    }
}

FormCommandChat.propTypes = {
    onClose: PropTypes.func,
    data: PropTypes.object,
    packages: PropTypes.object,
    onCreate: PropTypes.func
}

export default FormCommandChat;
