import React from "react";
import classes from "./__index__.module.scss";
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import proctoramanin from '../../../assets/imgs/proctoramanin.png';
import warning from "../../../assets/imgs/warning.png";
import info from "../../../assets/imgs/info.png";
import newmessage from "../../../assets/imgs/newmessage.png";
import notification from "../../../assets/imgs/notification.png";
import AuthContext from "../../../store/auth-context";
import { Err } from "../../../libs/logger";
import bell from "../../../assets/sounds/bell.mp3";

export const __PictureInPicture__ = function (_this) {
    _this.pipWindow = null;
    _this.rootElement = null;
    _this.pipInterval = null;

    this.lastRenderTs = 0;
    this.newMessageReceived = false;
    this.lastNotificationTs = 0;

    this.routine = () => {
        if (this.proctorBroadcastMessage !== _this.state.broadcastFromProctorerMessage) {
            this.lastNotificationTs = Date.now();
        } else if (Date.now() - this.lastNotificationTs > 60000) {
            this.lastNotificationTs = 0;
        }

        if (Date.now() - this.lastRenderTs > 1000) {
            this.lastRenderTs = Date.now();

            if (_this.rootElement !== null && _this.pipWindow !== null) {
                // if (this.newMessageReceived !== _this.state.newMessageReceived
                //     ||
                //     this.proctorBroadcastMessage !== _this.state.broadcastFromProctorerMessage
                // ) 
                // render every 5s
                this.newMessageReceived = _this.state.newMessageReceived;
                this.proctorBroadcastMessage = _this.state.broadcastFromProctorerMessage;
                _this.rootElement.render(
                    this.renderPictureInPicture()
                );

                // check if validation is failed
            }
        }
    }

    this.renderPictureInPicture = () => {
        return <PictureInPicture
            newMessageReceived={_this.state.newMessageReceived}
            primary_logo_url={_this.state.examProfile.primary_logo_url}
            proctorBroadcastMessage={_this.state.broadcastFromProctorerMessage}
            newNotificationReceived={this.lastNotificationTs !== 0}
            onNewNotificationClick={() => {
                this.lastNotificationTs = 0;
            }}
        />
    }
    this.pagehidehandler = (event) => {
        event.preventDefault();
        window.focus();
        _this.pipWindow = null;
        _this.rootElement = null;
    }

    this.initPipWindow = async () => {
        if (_this.pipWindow !== null) {
            return;
        }
        try {
            _this.pipWindow = await window.documentPictureInPicture.requestWindow({
                preferInitialWindowPlacement: true,
                width: 280,
                height: 300
            });
            // Detect when window is closed by user
            _this.pipWindow.removeEventListener("pagehide", this.pagehidehandler);
            _this.pipWindow.addEventListener("pagehide", this.pagehidehandler);
            [...document.styleSheets].forEach((styleSheet) => {
                try {
                    const cssRules = [...styleSheet.cssRules].map((rule) => rule.cssText).join('');
                    const style = document.createElement('style');

                    style.textContent = cssRules;
                    _this.pipWindow.document.head.appendChild(style);
                } catch (e) {
                    const link = document.createElement('link');

                    link.rel = 'stylesheet';
                    link.type = styleSheet.type;
                    link.media = styleSheet.media;
                    link.href = styleSheet.href;
                    _this.pipWindow.document.head.appendChild(link);
                }
            });
            _this.pipWindow.document.body.innerHTML = '<div id="root"></div>';
            const innerHTML = _this.pipWindow.document.body.innerHTML

            this.proctorBroadcastMessage = _this.state.broadcastFromProctorerMessage;
            this.lastNotificationTs = 0;
            _this.rootElement = createRoot(_this.pipWindow.document.getElementById('root'));
            _this.rootElement.render(
                this.renderPictureInPicture()
            );
        } catch (e) {
            Err(`[err-79] pip activating`);
            console.log(e);
        }
    }

    this.storedTitle = "";
    this.render = () => {
        const isSupported = "documentPictureInPicture" in window;
        if (_this.pipWindow === null) {
            if (this.storedTitle === "") {
                this.storedTitle = document.title;
            } else {
                document.title = "warning alarm is running"
            }
        } else {
            if (this.storedTitle !== "") {
                document.title = this.storedTitle;
                this.storedTitle = "";
            }
        }
        return (isSupported && _this.pipWindow === null) && <div id="btnPopWindow" className={classes.container}>
            <audio src={bell}
                autoPlay
                loop
            ></audio>
            <div
                onClick={async () => {
                    await this.initPipWindow();
                }}>
                Klik untuk melanjutkan ujian
            </div>
        </div>
    }
}

class PictureInPicture extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
    }

    render() {
        return <div className={[classes.pipcontainer, classes.ascroll].join(" ")}>
            <div className={classes.pipcontainer_title}>
                {this.props.newMessageReceived &&
                    <div className={classes.newmessage}
                        onClick={() => {
                            window.focus();
                        }}
                    >
                        <img alt="newmessage" src={newmessage} />
                        <div>
                            <span className={classes.title}>
                                Pesan baru diterima
                            </span>
                            <span className={classes.subtitle}>
                                Klik disini untuk membuka
                            </span>
                        </div>
                    </div>
                }
                {this.props.newNotificationReceived &&
                    <div className={classes.newmessage}
                        onClick={() => {
                            window.focus();
                            this.props.onNewNotificationClick();
                        }}
                    >
                        <img alt="notification" src={notification} />
                        <div>
                            <span className={classes.title}>
                                Notifikasi Baru Diterima
                            </span>
                            <span className={classes.subtitle}>
                                Klik disini untuk melihat
                            </span>
                        </div>
                    </div>
                }
                <div
                    className={classes.gofocus}
                    onClick={() => {
                        window.focus();
                    }}
                >
                    <img alt="info" src={info} />
                    <span>
                        Window ini adalah indikator bahwa anda terhubung dengan ujian yang sedang berlangsung. Semua informasi terkait ujian akan ditampilkan di sini.
                    </span>
                </div>
                <div
                    className={classes.gofocus}
                    onClick={() => {
                        window.focus();
                    }}
                >
                    <img alt="warning" src={warning} />
                    <span style={{
                        fontWeight: "bold",
                        fontSize: ".6rem"
                    }}>
                        Mohon untuk TIDAK menutup window ini selama ujian berlangsung. Apabila tertutup, akan muncul peringatan.
                    </span>
                </div>
                {/* <div
                    className={classes.gofocus}
                    onClick={() => {
                        window.focus();
                    }}
                >
                    <img alt="info" src={info} />
                    <span style={{ fontSize: ".75rem" }}>
                        Klik disini untuk menuju sistem pengawasan
                    </span>
                </div> */}
                <div className={classes.notification}>
                    <div className={classes.title}>
                        PERHATIAN
                    </div>
                    <div className={classes.content}>
                        {this.props.proctorBroadcastMessage}
                    </div>
                </div>
            </div>
        </div>
    }

}

export default PictureInPicture;