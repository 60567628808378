import React from "react";
import classes from "./__index__.module.scss";
import AuthContext from "../../store/auth-context";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import showpass from "../../assets/imgs/showpassword.png";
import hidepass from "../../assets/imgs/hidepassword.png";
import Loading from "../../components/Loading";
import {
  LANG,
  MOBILE_PAIRING_MODE,
  convertTimestampToDate,
  convertTimestampToTime,
  convertTimestampToTimeHourMinute,
  getExamId,
  initializeMedias,
  mobileMediaQueryHandleScreenChange,
  mobileMediaQueryHandler,
  mobileMediaQueryRemoveHandler,
  renderMobileWarning,
  showMediaError,
  normalizeExamId,
  setupInitialExamId,
  normalizeExamId2,
} from "../../libs/utils";
import {
  VERSION,
  fetchCurrentTs,
  fetchGetEventsSetting,
} from "../../libs/proctor_utils";
import { __Action__ } from "./Action";
import { Lang } from "../../libs/Language";
import CountDown from "../../components/CountDown";
import brand from "../../assets/imgs/proktoramaninwhitewithlink.png";
import { __TermsAndConditions__ } from "../../components/TermsAndConditions";
import PropTypes from "prop-types";
import ProgressBar from "../../components/ProgressBar";
import Language from "../../components/Language";
import Tips from "../../components/Tips";
import { ErrorAlert } from "../../components/Alert";
import device from "../../assets/imgs/device.png";
import CoolButton from "../../components/CoolButton";

class AuthPage extends React.Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);

    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();

    this.initWebCamRef = React.createRef();
    this.initScreenCaptureRef = React.createRef();

    this._action = new __Action__(this);

    this.state = {
      lang: LANG(),

      validExamId: true,

      mediaError: null,
      desktopView: true,
      extensionInstalled: true,

      mobileView: true,

      // loginAs: "", //"", "participant", "proctorer"
      loginAs: this.props.as, //"", "participant", "proctorer"
      isPageShown: true,
      ruleAsParticipant: true,

      loadingMessage: "",
      showPass: false,

      examProfile: {
        date: null,
        title: null,
        title_2: null,
        startTime: null,
        start_time_ts: null,
        preparationTime: null,
        preparation_time_ts: null,
        duration: null,
        durationTime: null,
        useExtension: false,
      },

      examTimes: {
        preparationTime: "00:00",
        startTime: "00:00",
        endTime: "00:00",
      },

      currentTimes: {
        date: "",
        hours: "",
        minutes: "",
        seconds: "",
      },
      currentTs: 0,

      preparationCountdown: {
        days: "",
        dates: "",
        hours: "",
        minutes: "",
        seconds: "",
      },

      examCountdown: {
        days: "",
        hours: "",
        minutes: "",
        seconds: "",
      },

      displayTimer: "",

      bgColor: "#E3B891",

      onlineDetectedRetry: null,
      // onlineDetectedRetry: {
      //   username: "proctor00001",
      //   password: "amanin@2023#",
      //   pperp: 50,
      // }
    };
    this._termsandconditions = new __TermsAndConditions__(
      this,
      window.location.hostname === "localhost"
        ? "console.amanin.id"
        : window.location.host
    );
  }

  generateBackground() {
    return (
      <div className={classes.circle}>
        <div className={classes.one}></div>
        <div className={classes.two}></div>
      </div>
    );
  }

  generateCurrentTime() {
    return (
      <div className={classes.datetime}>
        <span className={classes.title}>
          {Lang(`- Saat Ini -`, this.state.lang, { en: `- Now -` })}
        </span>
        <span className={classes.date}>
          {convertTimestampToDate(this.state.currentTs)}
        </span>
        <span className={classes.time}>
          {convertTimestampToTime(this.state.currentTs)}
        </span>
      </div>
    );
  }

  generateForm() {
    let exam_status;
    let countdown = <></>;
    if (this.state.displayTimer === "ujian") {
      exam_status = Lang("Selamat Melaksanakan Ujian", this.state.lang, {
        en: `Good Luck With The Exam`,
      });
    } else if (this.state.displayTimer === "selesai") {
      exam_status = Lang("Ujian Telah Selesai", this.state.lang, {
        en: `The Exam Has Finished`,
      });
    } else {
      exam_status = Lang(`Anda dapat login untuk bersiap`, this.state.lang, {
        en: `You Can Log In To Get Ready`,
      });
      countdown = (
        <div className={classes.infocd}>
          <span>
            {Lang(`Akan Berlangsung Dalam`, this.state.lang, {
              en: `Will occur in`,
            })}
          </span>
          <CountDown value={this.state.examCountdown} />
        </div>
      );
    }

    return (
      <div className={[classes.cform, classes.FadeInPage1s].join(" ")}>
        <div className={classes.cform_lang}>
          <Language
            currentLang={this.state.lang}
            left={true}
            onChangeLang={(v) => {
              this.setState({ lang: v });
            }}
          />
        </div>
        <div className={classes.form}>
          {this.state.loadingMessage === null && this.generateCurrentTime()}
          {this.state.loadingMessage === null && this.generateTestDevice()}
          {this.state.loadingMessage === null ? (
            <div className={[classes.form_top, classes.FadeInPage1s].join(" ")}>
              <div className={classes.title}>
                {this.state.examProfile.title}
              </div>
              <img alt="logo" src={this.state.examProfile.primary_logo_url} />
              <div className={classes.title2}>
                {this.state.examProfile.title_2}
              </div>
              <div className={classes.title3}>
                {Lang(
                  `Diselenggarakan Pada ${convertTimestampToDate(
                    this.state.examProfile.start_time_ts * 1000
                  )} Pukul ${convertTimestampToTimeHourMinute(
                    this.state.examProfile.start_time_ts * 1000
                  )}`,
                  this.state.lang,
                  {
                    en: `Held On ${convertTimestampToDate(
                      this.state.examProfile.start_time_ts * 1000
                    )} at ${convertTimestampToTimeHourMinute(
                      this.state.examProfile.start_time_ts * 1000
                    )}`,
                  }
                )}
              </div>
            </div>
          ) : null}
          <div className={classes.form_bottom}>
            {this.state.loadingMessage === null && (
              <div
                className={[
                  classes.form_bottom_lleft,
                  classes.FadeInPage1s,
                ].join(" ")}
              >
                {countdown}
                <div className={classes.info}>
                  {/* Ujian Sedang Berlangsung */}
                  {exam_status}
                </div>
              </div>
            )}
            {this.state.loadingMessage !== null && this.renderLoadingMessage()}
            {this.state.loadingMessage === null && (
              <div
                className={[
                  classes.form_bottom_right,
                  classes.FadeInPage1s,
                ].join(" ")}
              >
                <div className={classes.input}>
                  <form onSubmit={this._action.submitHandler}>
                    <div>
                      <div>
                        <span>Username</span>
                      </div>
                      <input
                        type="text"
                        id="username"
                        ref={this.usernameRef}
                        required
                      />
                    </div>
                    <div>
                      <div>
                        <span>Password</span>
                        <img
                          alt="showpass"
                          onClick={() => {
                            // this.setState({ showPass: !this.state.showPass })
                            this.setState((prevState) => ({
                              showPass: !prevState.showPass,
                            }));
                          }}
                          src={this.state.showPass ? showpass : hidepass}
                        />
                      </div>
                      <input
                        type={this.state.showPass ? "text" : "password"}
                        id="password"
                        ref={this.passwordRef}
                        required
                      />
                    </div>
                    <div className={classes.action}>
                      {/* <button
                        className={[classes.btnOk, classes.btn].join(" ")}
                      >
                        LOGIN
                      </button> */}
                      <button style={{ all: "unset" }}>
                        <CoolButton
                          gradient="linear-gradient(135deg, #0390fc, #3fb6ba)"
                          fontWeight="700"
                          fontSize="16px"
                          fontColor="white"
                        >
                          <div
                            style={{ margin: "5px", color: "white" }}
                          >
                            LOGIN
                          </div>
                        </CoolButton>
                      </button>
                    </div>
                  </form>
                  {/* <div className={classes.or}>atau</div>
                <div>
                  <LoginWith />
                </div> */}
                </div>
              </div>
            )}
          </div>
          {this.state.loadingMessage === null && (
            <>
              <div
                className={[classes.form_footer, classes.FadeInPage1s].join(
                  " "
                )}
              >
                {Lang(
                  `Silahkan Login Menggunakan Akun dan Password Pendaftaran Anda`,
                  this.state.lang,
                  {
                    en: `Please log in using your account and registration password`,
                  }
                )}
              </div>
              <span className={classes.version}>v{VERSION}</span>
            </>
          )}
        </div>
      </div>
    );
  }

  generateTestDevice() {
    return (
      <div
        onClick={() => {
          window.location.href = "/testdevice";
        }}
        className={classes.testdevice}
      >
        <CoolButton
          gradient="linear-gradient(135deg, #59068a, #8a0611)"
          fontWeight="700"
          fontSize="16px"
        >
          <div className={classes.testdevice_content}>
            <img alt="device" src={device} width={"40px"} />
            Klik Disini Untuk Menguji Perangkat Anda
            {/* Click Here to Test Your Device Compatibility */}
          </div>
        </CoolButton>
      </div>
    );
  }

  renderLoadingMessage() {
    return (
      <div
        className={[
          classes.loggingin,
          // classes.ScaleYShow1s
          classes.FadeInPage1s,
        ].join(" ")}
      >
        <Loading />
        <span>{this.state.loadingMessage}</span>
      </div>
    );
  }

  handleScreenChange = (event) => {
    mobileMediaQueryHandleScreenChange(this, event);
  };

  componentWillUnmount() {
    clearInterval(this.clockInterval);
    clearInterval(this.fetchCurrentTsInterval);

    this._termsandconditions.unmount();

    mobileMediaQueryRemoveHandler(this.mm, this.handleScreenChange);
  }

  componentDidMount() {
    setupInitialExamId();

    // check events setting mode
    fetchGetEventsSetting().then((data) => {
      if (data.status !== "success") {
        return;
      }
      const { mode, validexamid, examid } = data.result;
      if (mode === "single" &&
        this.props.as !== "testdevice" &&
        (getExamId() !== null && getExamId() !== "null") &&
        getExamId() !== examid) {
        window.location.href = `/id/${examid}`
      } else {
        if (validexamid === false) {
          this.setState({ validExamId: false })
          ErrorAlert("Invalid Exam ID").then(() => {
          })
        }
        if (
          this.props.as !== "testdevice" &&
          mode === "multiple" &&
          (getExamId() === null || getExamId() === "null")
        ) {
          window.location.href = "/first";
        }
      }
    });

    this.setState((prevState) => ({
      loadingMessage: Lang(`Mendapatkan Profil Ujian`, prevState.lang, {
        en: "Fetching Exam Profile",
      }),
    }));
    this.mm = mobileMediaQueryHandler(this.handleScreenChange);

    // Direct Login menggunakan token
    this._action.directUserLogin();

    fetchCurrentTs((error) => { }).then((data) => {
      this.setState({ currentTs: data.result });
    });

    clearInterval(this.fetchCurrentTsInterval);
    this.fetchCurrentTsInterval = setInterval(() => {
      fetchCurrentTs((error) => { }).then((data) => {
        if (data !== null) {
          this.setState({ currentTs: data.result });
        }
      });
    }, 60000);

    this.clockInterval = setInterval(() => {
      this._action.clockRoutine();
    }, 1000);

    this._action.fetchExamInitial((success, ep, et) => {
      if (success) {
        if (success === false) {
          ErrorAlert(
            Lang(`Gagal mendapatkan profil ujian`, this.state.lang, {
              en: `Failed To Get Exam Profile`,
            })
          );
          return;
        }

        if (ep.hide_terms_conditions === 0) {
          this._termsandconditions.mount();
        }

        setTimeout(() => {
          this.setState({
            loadingMessage: null,
          });
        }, 1000);
      }
    });
  }

  generateBrand() {
    return (
      <div className={classes.brand}>
        <a href="https://amanin.id" target="_blank" rel="noreferrer">
          <img alt="logo" src={brand} />
        </a>
      </div>
    );
  }

  renderOnlineDetectedWait() {
    return (
      <div className={[classes.onlinedetected, classes.FadeInPage1s].join(" ")}>
        <div>
          <img alt="logo" src={this.state.examProfile.primary_logo_url} />
          <span>
            {Lang(
              `Mohon Tunggu, Sistem Sedang Melakukan Pengecekan`,
              this.state.lang,
              { en: `Please Wait, System Checking is in Progress ...` }
            )}
          </span>
        </div>
        <ProgressBar
          completed={
            Math.round(
              (this.state.onlineDetectedRetry.progress /
                this.state.onlineDetectedRetry.wait) *
              100
            ) > 100
              ? 100
              : Math.round(
                (this.state.onlineDetectedRetry.progress /
                  this.state.onlineDetectedRetry.wait) *
                100
              )
          }
        />
      </div>
    );
  }

  renderMediaParticipants() {
    let me = [];
    for (let m in this.state.mediaError) {
      me.push(<span key={m}>{m}</span>);
    }
    return (
      <div className={classes.media}>
        <CoolButton
          // className={classes.back}
          fontColor={"black"}
          onClick={() => {
            window.location.href = `/id/${getExamId()}`;
          }}
        >
          Back To Login Page
        </CoolButton>
        <div className={classes.petunjuk}>
          <span>IKUTI PETUNJUK DI BAWAH INI</span>
          <ul>
            <li>Klik "Aktifkan WebCamera dan Screen Capture"</li>
            <li>Pada pilihan Screen Share, pilih "ENTIRE SCREEN"</li>
          </ul>
        </div>
        <div>
          <div className={classes.media_b}>
            <video ref={this.initWebCamRef} autoPlay muted playsInline></video>
            <div>WebCam</div>
          </div>

          {this.mode !== "mobile" && (
            <div className={classes.media_b}>
              <video ref={this.initScreenCaptureRef} autoPlay muted></video>
              <div>Screen Capture</div>
            </div>
          )}
        </div>
        <CoolButton
          // className={classes.activate}
          marginTop={"5px"}
          onClick={() => {
            this.setState({ mediaError: null });
            initializeMedias(
              "320x240@10fps",
              true,
              true,
              false,
              // error
              {
                face: true,
                screen: true,
                webrtc: false,
              },
              (stream) => {
                // capture face
                this.initWebCamRef.current.srcObject = stream;
              },
              (stream) => {
                // capture screen
                this.initScreenCaptureRef.current.srcObject = stream;
              },
              (stream) => {
                //capture webrtc
              },
              (mediatype, err) => {
                this.setState(
                  (prevState) => ({
                    mediaError: {
                      ...prevState.mediaError,
                      [mediatype]: err,
                    },
                  }),
                  () => {
                    console.log(this.state.mediaError);
                  }
                );
              }
            );
          }}
        >
          Aktifkan WebCamera dan Screen Capture
        </CoolButton>
        {/* <div className={classes.error}>
        {me}
      </div> */}
        {showMediaError(this.state.mediaError)}
        <div className={classes.info}>
          <span>
            Test ini dilakukan untuk menguji apakah perangkat yang anda gunakan
            sesuai dengan kebutuhan Sistem Pengawasan Ujian Online yang
            digunakan.
          </span>
        </div>
        <div className={classes.info}>
          <span>
            Apabila anda dapat melihat video webcamera dan screen capture anda,
            maka perangkat anda telah sesuai dan siap digunakan.
          </span>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.as === "testdevice") {
      return this.renderMediaParticipants();
    }
    if (this.state.validExamId === false) {
      return <div className={classes.container}>
        {this.generateBackground()}
        <span style={{ zIndex: "1000", fontWeight: "bold" }}>
          Invalid Exam ID, please check your exam id, your exam id= {normalizeExamId2(getExamId())}
        </span>
      </div>
        ;
    }
    if (
      this.state.desktopView === false &&
      MOBILE_PAIRING_MODE === false
      //  && this.mobileView === false
    ) {
      return <div className={classes.container}>{renderMobileWarning()}</div>;
    }

    if (this.state.onlineDetectedRetry?.retry === 1) {
      return (
        <div className={classes.container}>
          {this.generateBackground()}
          {this.renderOnlineDetectedWait()}
          {this.generateBrand()}
        </div>
      );
    }
    return (
      <div className={classes.container}>
        {this.state.examProfile.hide_terms_conditions === 0 &&
          this._termsandconditions.render()}
        <Tips />
        {this.generateBackground()}
        {this.generateForm()}
        {this.generateBrand()}
      </div>
    );
  }
}

AuthPage.propTypes = {
  as: PropTypes.string,
};
export default withRouter(AuthPage);
