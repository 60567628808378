import { Debug, Err } from "../logger";
import { fetchJSONMessageCommon } from "../proctor_utils";
import { getAccessToken, getExamId } from "../utils";

export function getCurrentTS(_this, id) {
    // Debug("[getCurrentTS] type= getCurrentTs" + ", id=" + id)
    if (_this.socket.readyState === 1) {
        _this.socket.send(
            JSON.stringify({
                type: "getcurrentts",
                id: id,
                token: getAccessToken(),
                examid: getExamId()
            })
        )
    } else {
        // Debug("[getCurrentTS] socket is not ready");
        return false;
    }
    return true;
}

export function sendUpdateTsPost(_this, id, ipAddress, type = "participant", cb = null) {
    fetchJSONMessageCommon("update-ts", {
        type: "update-ts",
        userType: type,
        id: id,
        token: getAccessToken(),
        examid: getExamId(),
        ipAddress: ipAddress ? ipAddress : "<unknown>",
    }, (err) => {
        Err(err);
        cb(false)
    }).then((v) => {
        Debug(v)
        cb(true)
    })
}
export function sendUpdateTs(_this, id, ipAddress, type = "participant") {
    if (_this.socket.readyState === 1) {
        _this.socket.send(JSON.stringify({
            type: "update-ts",
            userType: type,
            id: id,
            token: getAccessToken(),
            examid: getExamId(),
            ipAddress: ipAddress ? ipAddress : "<unknown>",
        }));
    } else {
        _this.errorSocketIsNotReady();
        return false;
    }
    return true;
}