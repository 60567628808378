import ChromeExtension from "../../libs/ChromeExtension";
import { Debug, Info } from "../../libs/logger";

export const __ChromeExtension__ = function (_this, classes) {
    this.counter = 0;
    _this.CE = new ChromeExtension();
    _this.quizActivity = {
        host: null,
        tab: false,
        windowFocused: false
    }
    this.tabsHandler = (tabs) => {
        let oneFailed = false;
        tabs.forEach((t) => {
            if (t.active) {
                if (t.url.includes("about:blank")) {
                    return;
                }
                // check the white list 
                const whiteList = _this.CE.getAllowedUrls();
                let foundWhiteList = false;
                for (let w in whiteList) {
                    if (t.url.includes(whiteList[w]) === true) {
                        foundWhiteList = true;
                        // oneActive = true;
                        // _this.quizActivity.tab = true;
                        break;
                    }
                }
                if ( foundWhiteList ) {
                    return;
                }
                if (t.url.includes(window.location.host) === false) {
                    if (_this.quizActivity.host !== null) {
                        if (t.url.includes(_this.quizActivity.host) === true) {
                            Debug("quiz page detected");
                            // oneActive = true;
                            // _this.quizActivity.tab = true;
                        } else {
                            Debug(this.counter++, "set tab=false, check = ", t.url, _this.quizActivity.host)
                            oneFailed = true;
                            // _this.quizActivity.tab = false;
                        }
                    }
                } else if (t.url.includes("proctor")) {
                    Debug("ignore for proctorer")
                } else {
                    Debug("current system detected");
                    // _this.quizActivity.tab = true;
                    // oneActive = true;
                }
            }
        })
        if (oneFailed === true) {
            _this.quizActivity.tab = false;
        } else {
            _this.quizActivity.tab = true;
        }
    }

    this.chromeExtensionRoutine = () => {
        if (_this.state.examProfile.useExtension) {
            _this.CE.checkExtension((result) => {
                _this.setState({ extensionInstalled: result })
            });
            _this.CE.register();
            _this.CE.getTabs((tabs) => {
                this.tabsHandler(tabs)
            })
            _this.CE.getWindowsFocused((v) => {
                _this.quizActivity.windowFocused = v;
            })
        }
    }
}



