import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";
import classes from "./Notes.module.scss";
import { saveNotes } from "./Action";
import { renderAttentionButton } from "./Component";

export const renderNotes = (_this) => {

    const showNotes = () => {

        return <div className={classes.container_second_wrap}  >

            <textarea
                cols="70"
                rows="25"
                ref={_this.notesRef}
                placeholder="Tulis catatan Anda di sini . . . . ."
                onChange={(ev) => {
                    _this.setState({ notes: _this.notesRef.current?.value })
                }}
                defaultValue={_this.state.chosen?.profile?.notes}
            >
            </textarea>
            <button
                style={{ margin: "10px" }}
                className={classes.btnOk}
                onClick={() => {
                    let fa = _this.state.chosen?.profile?.flagAttention === 1 ? true : false;
                    saveNotes(_this, fa, () => {
                        if (_this.notesRef.current !== null) {
                            _this.setState((prevState) => ({
                                chosen: {
                                    ...prevState.chosen,
                                    profile: {
                                        ...prevState.chosen.profile,
                                        notes: _this.notesRef.current?.value,
                                    },
                                },
                            }));
                        }
                    });
                }}
            >
                {Lang(`Simpan Catatan`, LANG(), { en: `Save` })}
            </button>
            <div className={classes.flagAttention}>
                {renderAttentionButton(_this)}
            </div>
        </div>
    }

    return <div className={classes.container}>
        <div className={[classes.container_first, classes.ascroll].join(" ")} >
            <img
                width="320px"
                alt={"face"}
                src={_this.state.faceImgUrl}
                onClick={(event) => {
                    if (_this.props.openImage) {
                        _this.props.onImageClose()
                    } else {
                        _this.props.onImageOpen([event.target.src])
                    }
                }}
                style={{ cursor: "zoom-in" }}
            />
            <img
                width="320px"
                alt="screen"
                src={_this.state.screenImgUrl}
                onClick={(event) => {
                    if (_this.props.openImage) {
                        _this.props.onImageClose()
                    } else {
                        _this.props.onImageOpen([event.target.src])
                    }
                }}
                style={{ cursor: "zoom-in" }}
            />

            <img
                width="320px"
                alt="mobilepairing"
                src={_this.state.mobilePairingImgUrl}
                onClick={(event) => {
                    if (_this.props.openImage) {
                        _this.props.onImageClose()
                    } else {
                        _this.props.onImageOpen([event.target.src])
                    }
                }}
                style={{ cursor: "zoom-in" }}
            />
        </div>
        <div className={classes.container_second}>
            {showNotes()}
        </div>
    </div>
}