import log from 'loglevel';

// log.setLevel(localStorage.getItem('_xprmc')?localStorage.getItem('_xprmc'):'info')
// log.setLevel('trace');
log.setLevel('info');

export const Trace = log.trace
export const Debug = log.debug
export const Info = log.info
export const Warning = log.warn
export const Err = log.error

// export const Trace = (...msg) => { log.trace(`[trace]`, ...msg) }
// export const Debug = (...msg) => { log.debug(`[debug]`, ...msg) }
// export const Info = (...msg) => { log.info(`[info]`, ...msg) }
// export const Warning = (...msg) => { log.warn(`[info]`, ...msg) }
// export const Err = (...msg) => { log.error(`[error]`, ...msg) }