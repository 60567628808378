import Chat from "../../../components/Chat/Chat";
import CoolButton from "../../../components/CoolButton";
import classes from "./Chat.module.scss";

export const renderChat = (_this) => {
    const isChatBoxShown = (_this.props.chatBoxIsOpenState && _this.props.chatBoxIsOpenState.indexOf(_this.state.chosen.profile.id) > -1)
    return <div className={classes.container}>
        <div className={[classes.container_first, classes.ascroll].join(" ")} >
            <img
                width="320px"
                alt={"face"}
                src={_this.state.faceImgUrl}
                onClick={(event) => {
                    if (_this.props.openImage) {
                        _this.props.onImageClose()
                    } else {
                        _this.props.onImageOpen([event.target.src])
                    }
                }}
                style={{ cursor: "zoom-in" }}
            />
            <img
                width="320px"
                alt="screen"
                src={_this.state.screenImgUrl}
                onClick={(event) => {
                    if (_this.props.openImage) {
                        _this.props.onImageClose()
                    } else {
                        _this.props.onImageOpen([event.target.src])
                    }
                }}
                style={{ cursor: "zoom-in" }}
            />

            <img
                width="320px"
                alt="mobilepairing"
                src={_this.state.mobilePairingImgUrl}
                onClick={(event) => {
                    if (_this.props.openImage) {
                        _this.props.onImageClose()
                    } else {
                        _this.props.onImageOpen([event.target.src])
                    }
                }}
                style={{ cursor: "zoom-in" }}
            />
        </div>
        <div className={classes.container_second}>
            <div
                style={{ height: "95%" }}
            >
                {_this.props.children}
            </div>
            {(isChatBoxShown) && <div
                style={{
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <CoolButton
                    onClick={() => {
                        _this.props.onCloseParticipantChatBox(_this.state.chosen.profile.id)
                    }}
                >Close Participant's Chat Box</CoolButton>
            </div>}
        </div>
    </div>
}