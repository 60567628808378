import "./App.css";

import { Switch, Route, Redirect } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { useContext, useEffect } from "react";
import AuthContext from "./store/auth-context";
import LoginPageMobileRouter from "./pages/ProktorEventLoginPageMobile/LoginPageMobile";
import ExamPage from "./pages/ExamPage/__index__";
import AuthPage from "./pages/ProktorEventLoginPage/__index__";
import ProctorPage from "./pages/ProctorPage/__index__";
import EmbeddedFrame from "./pages/EmbeddedFrame/__index__";
import EmbeddedParent from "./pages/EmbeddedParent/__index__";
import LoginPageMobilePairing from "./pages/ProktorEventLoginPageMobile/LoginPageMobilePairing";
import MobilePairingPage from "./pages/MobilePairingPage/__index__";
// import MobilePairingPage from "./pages/MobilePairingPage/MobilePairingPage";
import ProktorFirstLogin from "./pages/ProktorFirstLoginPage/__index__";
import { getExamId, normalizeExamId, normalizeExamId2, setExamId } from "./libs/utils";
import QuizPage from "./pages/QuizPage/__index__";

function App() {
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    // Ambil path dari URL
    const path = window.location.pathname;
    const pathSegments = path.split("/");

    // Cek apakah URL memiliki segmen "id" dan ambil segmen setelahnya
    if (pathSegments[1] === "proctor" || pathSegments[1] === "exam") {
      window.location.href = `/id/${normalizeExamId2(getExamId())}`
      return;
    }
    if (pathSegments[1] === "id" && pathSegments[2]) {
      const id = pathSegments[2];
      setExamId(id);
    } else if (pathSegments[1] !== "testdevice") {
      localStorage.removeItem("examid");
    }
  }, []);

  return (
    <Switch>
      <Route path="/embedded" exact>
        <EmbeddedFrame />
      </Route>
      <Route path="/embeddedparent" exact>
        <EmbeddedParent />
      </Route>

      {authCtx.isLoggedIn && (
        <Route path="/exam/id/:id" exact>
          <BrowserView>
            <ExamPage
              mode="web"
            />
          </BrowserView>
          <MobileView>
            <ExamPage mode="mobile" />
          </MobileView>
        </Route>
      )}
      {authCtx.isLoggedIn && (
        <Route path="/proctor/id/:id" exact>
          <BrowserView>
            <ProctorPage />
          </BrowserView>
          <MobileView>
            <ProctorPage />
          </MobileView>
        </Route>
      )}
      <Route path="/quizexam/:descriptions">
        <BrowserView>
          <QuizPage />
        </BrowserView>
      </Route>
      <Route path="/id/:id" exact>
        <BrowserView>
          {/* <LoginPageMobilePairing /> */}
          <AuthPage />
        </BrowserView>
        <MobileView>
          <LoginPageMobilePairing />
        </MobileView>
      </Route>
      <Route path="/participant" exact>
        <BrowserView>
          {/* <LoginPageMobilePairing /> */}
          <AuthPage />
        </BrowserView>
        <MobileView>
          <LoginPageMobilePairing />
        </MobileView>
      </Route>
      <Route path="/proctorer" exact>
        <BrowserView>
          <AuthPage />
        </BrowserView>
        <MobileView>
          <LoginPageMobileRouter as={"proctorer"} />
          {/* <LoginPageMobilePairing /> */}
        </MobileView>
      </Route>

      <Route path="/testdevice" exact>
        <BrowserView>
          <AuthPage as={"testdevice"} />
        </BrowserView>
      </Route>

      <Route path="/first" exact>
        <BrowserView>
          <ProktorFirstLogin as={"testdevice"} />
        </BrowserView>
      </Route>

      {authCtx.isLoggedIn && (
        <Route path="/mobilePairing" exact>
          <MobileView>
            <MobilePairingPage />
          </MobileView>
        </Route>
      )}
      <Route path="/management" exact component={() => {
        window.location.href = "https://" + window.location.hostname + ":4443";
      }} >
      </Route>
      <Route path="*">
        <Redirect to={`/first`} />
      </Route>
    </Switch>
  );
}

export default App;
