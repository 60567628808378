import React from "react";
import classes from "./UserProfile.module.scss";
import XButton from "./XButton";
import { fetchUserPackageById } from "../libs/proctor_utils_ext";
import Loading from "./Loading";
import copypaste from "../assets/imgs/copypaste.png";
import info from "../assets/imgs/info.png";

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.data = this.props.data;
        this.onLogout = this.props.onLogout ? this.props.onLogout : null;
        this.onJoinExam = this.props.onJoinExam ? this.props.onJoinExam : null;
        if (this.data.running_exam === undefined) {
            this.data.running_exam = []
        }

        this.state = {
            showDetail: false,
            data: this.data,
            ready: false
        }
        this.fetchInterval = null;
    }

    getData() {
        fetchUserPackageById(this.data.id, (e) => {
            console.log(e)
        }).then((data) => {
            if (data.status === "success") {
                this.setState({
                    data: { ...this.state.data, ...data.result },
                    ready: true
                })
            }
        })
    }

    componentWillUnmount() {
        clearInterval(this.fetchInterval)
    }

    componentDidMount() {
        this.getData();
        clearInterval(this.fetchInterval);
        this.fetchInterval = setInterval(() => {
            this.getData();
        }, 10000);
    }

    render() {
        return <div className={classes.container}>
            {this.state.showDetail === false ?
                <img
                    alt="user"
                    className={[classes.img, classes.FadeInPage].join(" ")}
                    src={this.state.data.photo}
                    onClick={() => {
                        this.setState({ showDetail: true });
                    }}
                /> :
                <div className={[classes.container_modal, classes.FadeInPage].join(" ")}>
                    {/* <Loading /> */}
                    <div className={classes.top}>
                        <div className={classes.hi}>
                            Halo, {this.state.data.name.split(" ")[0]}
                        </div>
                        <img
                            alt="user"
                            className={[classes.img, classes.FadeInPage].join(" ")}
                            src={this.state.data.photo}
                        />
                        <XButton onClick={() => {
                            this.setState({ showDetail: false })
                        }} />
                    </div>
                    <div className={classes.bottom}>
                        <div>
                            Email: {this.state.data.email}
                        </div>
                        <div>
                            Nama: {this.state.data.name}
                        </div>
                        <div>
                            Paket: {this.state.data.package_name}
                        </div>
                        {this.state.ready && <div className={classes.exam}>
                            <div className={classes.exam_title}>
                                Ujian Yang Anda Buat dan Sedang Berjalan:
                            </div>
                            <div className={classes.exam_value}>
                                {this.state.data.running_exam.length === 0 ? <ol>Tidak Ada Ujian Yang Berlangsung</ol> :
                                    <ol>
                                        {this.state.data.running_exam.map((v) => {
                                            return <li key={v.id}>
                                                <div>
                                                    <a
                                                        title="masuk ke ruang pengawasan"
                                                        className={classes.link} href="#"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (this.onJoinExam !== null) {
                                                                if (window.confirm(`Apakah anda ingin masuk ke ruang pengawasan Ujian ${v.title} (${v.id}) ?`)) {
                                                                    this.onJoinExam(v.id)
                                                                }
                                                            }
                                                        }}
                                                    >{v.title} ({v.id}) ({v.current_duration} menit)</a>
                                                    <img
                                                        alt="copypaste"
                                                        title="copy id ujian"
                                                        src={copypaste}
                                                        width={"20px"}
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(v.id).then((data) => {
                                                                alert(`Kode Ujian ${v.id} telah di copy.`)
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            </li>
                                        })}
                                    </ol>
                                }
                            </div>
                        </div>
                        }
                        {/* <div>
                            Total Durasi Ujian Keseluruhan: 90 menit
                        </div> */}
                        <div>
                            Total Durasi Ujian Yang Anda Buat Hari Ini: {this.state.data?.total_duration_today || 0} menit
                        </div>
                        <div>
                            Sisa Kuota Hari Ini: {this.state.data?.max_duration - this.state.data?.total_duration_today < 0 ? 0 : this.state.data.max_duration - this.state.data.total_duration_today} menit
                        </div>
                        <div>
                            <img
                                alt="info"
                                src={info} width={"20px"}
                                style={{ marginRight: "5px" }}
                            /> Peserta Ujian Tidak Dibatasi Kuota
                        </div>
                        <div>
                            <a className={classes.link}
                                href={`https://${window.location.hostname}:4443`}
                            >
                                Untuk Konfigurasi Lebih Lanjut Silakan Menuju Manajemen Ujian
                            </a>
                        </div>
                        <div className={classes.logout}>
                            <div className={classes.btnHelp}
                                onClick={this.onLogout}
                            >
                                Logout
                            </div>
                        </div>
                        <div className={classes.legal}>
                            <a href="https://amanin.id/termsandconditions">Syarat & Ketentuan Pengguna</a>
                            •
                            <a href="https://amanin.id/privacypolicy">Kebijakan Privasi</a>
                        </div>
                    </div>
                </div>
            }
        </div>
    }
}

export default UserProfile;