import { createRef } from "react";
import { Lang } from "../../../libs/Language";
import { LANG } from "../../../libs/utils";
import classes from "./ScreenShot.module.scss";
import { onImageModalClose, onImageModalOpen, saveScreenshot } from "./Action";
import { renderNavigation, viewScreenshot } from "./Component";

export const renderScreenShot = (_this) => {
    _this.screenShotRef = createRef();
    _this.participantImageRef = createRef();
    _this.screenImageRef = createRef();

    _this.mobileImageRef = createRef();

    return <div className={classes.container}>
        <div className={classes.container_navigation}>
            {renderNavigation(_this)}
        </div>
        {
            _this.state.mode === "view-screenshot"
                ? <div>
                    {viewScreenshot(_this)}
                </div>
                :
                <>
                    <div className={[classes.container_first, classes.ascroll].join(" ")} >

                        <div className={classes.modal_c_profile_right_photos}>
                            <div>
                                <img
                                    alt={"face"}
                                    src={_this.state.faceImgUrl}
                                    height="240px"
                                    ref={_this.participantImageRef}
                                    onError={() => {
                                        setTimeout(() => {
                                            _this.participantImageRef.current.src = _this.state.faceImgUrl;
                                        }, 1000);
                                    }}
                                    onClick={(event) => {
                                        if (_this.props.openImage) {
                                            _this.props.onImageClose()
                                        } else {
                                            _this.props.onImageOpen([event.target.src])
                                        }
                                    }}
                                    style={{ cursor: "zoom-in" }}
                                />
                                <span>Image</span>
                            </div>

                            <div>
                                <img
                                    alt="screen"
                                    src={_this.state.screenImgUrl}
                                    height="240px"
                                    ref={_this.screenImageRef}
                                    onError={() => {
                                        setTimeout(() => {
                                            _this.screenImageRef.current.src = _this.state.screenImgUrl;
                                        }, 1000);
                                    }}
                                    onClick={(event) => {
                                        if (_this.props.openImage) {
                                            _this.props.onImageClose()
                                        } else {
                                            _this.props.onImageOpen([event.target.src])
                                        }
                                    }}
                                    style={{ cursor: "zoom-in" }}
                                />
                                <span>Screen Capture</span>
                            </div>

                            <div>
                                <img
                                    alt="mobilepairing"
                                    src={_this.state.mobilePairingImgUrl}
                                    height="240px"
                                    ref={_this.mobileImageRef}
                                    onError={() => {
                                        setTimeout(() => {
                                            _this.mobilePairingImageRef.current.src = _this.state.mobilePairingImgUrl;
                                        }, 1000);
                                    }}
                                    onClick={(event) => {
                                        if (_this.props.openImage) {
                                            _this.props.onImageClose()
                                        } else {
                                            _this.props.onImageOpen([event.target.src])
                                        }
                                    }}
                                    style={{ cursor: "zoom-in" }}
                                />
                                <span>Mobile Pairing</span>
                            </div>
                        </div>
                    </div>
                    <div className={classes.container_second}>
                        <textarea
                            style={{ marginTop: "10px" }}
                            cols="70"
                            rows="8"
                            ref={_this.screenShotRef}
                            // defaultValue={""}
                            placeholder={Lang('Ketik Keterangan Screenshot', LANG(), { en: `Type Screenshot Description` })}
                        >
                        </textarea>

                        <button
                            style={{ margin: "10px" }}
                            className={classes.btnOk}
                            onClick={() => { saveScreenshot(_this) }}
                        >
                            {Lang(`Simpan Screenshot`, LANG(), { en: `Save Screenshot` })}
                        </button>

                    </div>
                </>
        }

    </div>
}