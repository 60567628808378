const LangList = [
    {
        id: 'Selamat Datang di Ujian',
        en: 'Welcome to the'
    },
    {
        id: 'Ujian Telah Selesai',
        en: 'The Examination has done',
        ch: '考试已完成'
    },
    {
        id: 'Login sebagai Pengawas',
        en: 'Login as Proctorer',
        ch: '以主管身份登录'
    },
    {
        id: 'Login sebagai Peserta',
        en: 'Login as Participant',
    },
    {
        id: 'Peserta',
        en: 'Participant',
    },
    {
        id: 'Pengawas',
        en: 'Proctorer'
    }
]

export const Lang = (text, lang = 'id', customList = null) => {
    if (customList !== null && lang !== 'id') {
        return customList[lang] ? customList[lang] : text
    }

    if (lang !== 'id') {
        for (let l in LangList) {
            if (LangList[l].id === text) {
                return LangList[l][lang] ? LangList[l][lang] : text
            }
        }
    }
    return text;
}